import React from 'react';

import { Heading } from 'components/common/Heading';

import { IntlMessage } from 'util/IntlMessages';
import { formatDecimal, formatCurrency } from 'util/utils';

import { PAGES } from 'constants/Common';

import { BidDrawerCalc } from './BidDrawerCalc';
import ConnectedInput from './ConnectedInput';
import ConnectedValue from './ConnectedValue';
import { BID_STONE_TYPE } from './_constants';
import { handleChange, handleDecrease, handleIncrease } from './_functions';

import { STATUS_COLOR } from '../Status';

export default function BidStoneMobile({ list, onSubmit, onClose }) {
  return (
    <div className="offerWrapper">
      <div className="align-items-center">
        <Heading className="popupInnerTitle">
          <IntlMessage id="app.bidStone" />
        </Heading>
        <BidDrawerCalc />
      </div>
      <div className="searchInnerResult tabInnerTable">
        <div className="diamondListMobile mobileOfferScroll">
          {list.map((stone) => {
            return (
              <div key={stone.vStnId} className="diamondListMobile as_app active">
                <div className="diamodListItemWrapper green">
                  <div className="caratStatus">
                    <p>
                      <span className="cVal">{formatDecimal(stone.crt) ?? '-'}</span>
                      <small className="cName">Carat</small>
                    </p>
                    <p className={`sStatus ${STATUS_COLOR[stone.wSts] ?? 'green'}`}>
                      <span className="lDot"></span>
                      <span className="lName">{stone.wSts}</span>
                    </p>
                  </div>
                  <div className="diamodListItemContent">
                    <div className="priceCaratContainer">
                      <p className="sId">
                        <a href={`/${PAGES.DETAIL}/${stone.id}`}>{stone.vStnId}</a>
                      </p>
                      <p className="sShape">{stone.shpNm}</p>
                      <p className="sDis">
                        {' '}
                        {Boolean(!stone.isFcCol && stone.back) && <span>{formatDecimal(stone.back) ?? '-'}%</span>}
                      </p>
                      <p className="priceCarat">
                        {stone.ctPr ? <span>{formatDecimal(stone.ctPr) ?? '-'} $/Cts</span> : ''}
                      </p>
                    </div>
                    <div className="stoneInfo">
                      <p>
                        <span className="sColor">
                          {stone.isFcCol
                            ? stone.fcColNm
                                ?.split?.(' ')
                                ?.map?.((x) => x.charAt(0))
                                ?.join?.()
                                ?.replaceAll?.(',', '')
                            : stone.colNm}
                        </span>
                        <span className="sFlu">{stone.clrNm}</span>
                        <span className="sc">{stone.cutNm ?? '-'}</span>
                        <span className="sc">{stone.polNm ?? '-'}</span>
                        <span className="sc">{stone.symNm ?? '-'}</span>
                        <span className="in">{stone.fluNm ?? '-'}</span>
                        <span className="lab">{stone.lbNm ?? '-'}</span>
                      </p>
                      <p className="sAmt">{stone.amt ? <span>{formatDecimal(stone.amt) ?? '-'} $/Amt</span> : ''}</p>
                    </div>
                    <div className="smeasure">
                      <p>
                        <span className="title">M: </span>
                        <span className="val">{stone.msrmnt ?? '-'}</span>
                      </p>
                      <p>
                        <span className="title">R: </span>
                        <span className="val">{stone.ratio ?? '-'}</span>
                      </p>
                      <p>
                        <span className="title">TD%: </span>
                        <span className="val">{stone.depPer ?? '-'}</span>
                      </p>
                      <p>
                        <span className="title">TB%: </span>
                        <span className="val">{stone.tblPer ?? '-'}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex j-space-between quoteExtraDetail">
                  <div className="quoteExtraItem">
                    <span>Bid Disc(%): </span>
                    <ConnectedInput
                      currentType={BID_STONE_TYPE}
                      column="bidDiscount"
                      onChange={handleChange}
                      onDecrease={handleDecrease}
                      onIncrease={handleIncrease}
                      record={stone}
                    />
                  </div>
                </div>
                <div className="d-flex j-space-between quoteExtraDetail">
                  <div className="quoteExtraItem">
                    <span>Bid Ct/Pr(%): </span>
                    <ConnectedInput
                      currentType={BID_STONE_TYPE}
                      column="bidPricePerCarat"
                      onChange={handleChange}
                      onDecrease={handleDecrease}
                      onIncrease={handleIncrease}
                      record={stone}
                    />
                  </div>
                </div>
                <div className="d-flex j-space-between quoteExtraDetail">
                  <div className="quoteExtraItem">
                    <span>Bid Value: </span>
                    <ConnectedValue
                      currentType={BID_STONE_TYPE}
                      column="bidAmount"
                      record={stone}
                      formatter={formatCurrency}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
      </div>
      <div className="sideBarPopupButton">
        <a href="#!" className="commonButton" onClick={onSubmit}>
          <span>Bid Stone</span>
        </a>
        <a className="commonButton" onClick={onClose}>
          <span>Cancel Bid</span>
        </a>
      </div>
    </div>
  );
}
