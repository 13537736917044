import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import classnames from 'classnames';

import { ImageWithView } from 'components/DiamondDetail/ImageWithView';

import IntlMessages from '../../util/IntlMessages';
import MagnifierImage from '../DiamondDetail/MagnifierImage';
import { downloadFile, downloadVideo, isMobile } from '../DiamondList/DiamondListFunctions';

const ImageSlider = (props) => {
  const { dnaData } = props;

  const setting = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
  ];

  // const actions = (link, ext) => {
  //   return (
  //     <div className="downlaod_data">
  //       {/* <p>Single Stone</p> */}
  //       <div className="dwn_liks">
  //         <a target="_blank" href={link}>
  //           <img src={require('../../assets/svg/DNA/photo.svg')} />
  //         </a>
  //         <a
  //           onClick={() => {
  //             if (ext === '.mp4')
  //               downloadVideo({
  //                 path: link,
  //                 fileName: last(split(link, '/')),
  //                 ext,
  //               });
  //             else
  //               downloadFile({
  //                 path: link,
  //                 fileName: last(split(link, '/')),
  //                 ext,
  //               });
  //           }}
  //         >
  //           <img src={require('../../assets/svg/DNA/download-black.svg')} />
  //         </a>
  //       </div>
  //     </div>
  //   );
  // };
  const [activeTab, setActiveTab] = useState(
    dnaData.img ? '1' : dnaData.videoFile ? '2' : dnaData.certFile ? '3' : '1',
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const link = () => {
    return (
      <Nav tabs className="customerMaintab diamondDetailTopTab">
        {dnaData.img && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              <IntlMessages id="app.Image" />
            </NavLink>
          </NavItem>
        )}
        {dnaData.videoFile && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              <IntlMessages id="app.Video" />
            </NavLink>
          </NavItem>
        )}

        {dnaData.certFile && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
            >
              <IntlMessages id="app.Certificate" />
            </NavLink>
          </NavItem>
        )}
      </Nav>
    );
  };
  return (
    <>
      <div className="dnaLeftBox">
        <div className="dnaLeftBoxImage">
          {/* {!isMobile() && link()} */}
          {link()}
          <TabContent activeTab={activeTab}>
            {activeTab === '1' && dnaData.img && (
              <TabPane className="after-logout" tabId={activeTab}>
                <>
                  <div className="imageSliderFullBlock">
                    {isMobile() ? (
                      <ImageWithView src={dnaData?.files?.img} title="" height="900px" />
                    ) : (
                      <MagnifierImage image={dnaData?.files?.img} />
                    )}
                  </div>
                </>
              </TabPane>
            )}

            {activeTab === '2' && dnaData.videoFile && (
              <TabPane className="mb-17" tabId={activeTab}>
                <>
                  <div className="video-set imageSliderFullBlock">
                    {dnaData?.files?.videoFile !== 'NO' ? (
                      <iframe
                        height="315"
                        allowFullScreen
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        src={dnaData?.files?.videoFile}
                      />
                    ) : (
                      <div className="imageSliderFullBlock no-msg">No video available.</div>
                    )}
                  </div>
                </>
              </TabPane>
            )}

            {activeTab === '3' && dnaData.certFile && (
              <TabPane tabId={activeTab}>
                <>
                  <div className="certificateImage imageSliderFullBlock">
                    {dnaData?.files?.certFile !== 'NO' ? (
                      isMobile() ? (
                        <iframe src={dnaData?.files?.certFile} title="certi" scrolling="no" frameBorder="0" />
                      ) : (
                        <iframe
                          src={dnaData?.files?.certFile}
                          height="650px"
                          width="100%"
                          title="certi"
                          scrolling="no"
                          frameBorder="0"
                        />
                      )
                    ) : (
                      <div className="imageSliderFullBlock no-msg">No certificate available.</div>
                    )}
                  </div>
                </>
              </TabPane>
            )}
          </TabContent>
          {/* {isMobile() && link()} */}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
