import React, { Component } from 'react';
import Magnifier from 'react-magnifier';

class MagnifierImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'Image',
      showDetails: false,
      Path: '',
      content: '',
      zoom: 1,
      maginfierState: false,
    };
  }
  render() {
    return (
      <Magnifier
        className={this.props.className}
        onMouseMove={this.handlechange}
        src={this?.props?.image}
        zoomFactor={this.state.zoom}
        onWheel={this.handleWheelChange}
        onMouseEnter={() => {
          this.setState({ maginfierState: true });
        }}
        onMouseOut={() => {
          this.setState({ maginfierState: false });
        }}
      />
    );
  }
}
export default MagnifierImage;
