import { BidService } from 'services/BidService';
import { TableValuesService } from 'services/TableValueService';

import { notify } from 'util/notify';
import { isEmpty, parseDecimal } from 'util/utils';

import { store } from 'appRedux/store';

import { BID_STONE_TYPE } from './_constants';

const currentType = BID_STONE_TYPE;

const tableValues = new TableValuesService({ table: currentType });

const getRow = (row) => ({ ...row, ...tableValues.state?.[row?.vStnId] });

export function getUpdatedBidList() {
  const selectedRows = store.getState().diamondData?.selectedRows?.[currentType] ?? [];

  const updatedRows = selectedRows.map((stone) =>
    isEmpty(tableValues.state[stone.vStnId]) ? stone : { ...stone, ...tableValues.state[stone.vStnId] },
  );
  return updatedRows;
}

export function handleBidBackChange(input) {
  const { row, value } = input;
  const isPositive = row.ctPr > row.rap;
  const back = isPositive ? Math.abs(row.back) || 0 : -1 * Math.abs(row.back) || 0;

  const max = back + BidService.diffUpward;
  const min = back - BidService.diffDownward;
  const originalValues = tableValues.state[row.vStnId];

  notify.destroy();

  if (value < min) {
    notify.error({ message: `Your bidding discount cannot be lower then ${parseDecimal(min)}%. Please, try again.` });
    tableValues.setValues({ [row.vStnId]: { ...originalValues } });
    return;
  }

  if (value > max) {
    notify.error({ message: `Your bidding discount cannot be higher then ${parseDecimal(max)}%. Please, try again.` });
    tableValues.setValues({ [row.vStnId]: { ...originalValues } });
    return;
  }

  const bidDiscount = parseDecimal(value);
  const bidPricePerCarat = parseDecimal(row.rap + row.rap * (value / 100));
  const bidAmount = parseDecimal(bidPricePerCarat * row.crt);
  const updatedValues = { bidDiscount, bidPricePerCarat, bidAmount };

  tableValues.setValues({ [row.vStnId]: updatedValues });
}

export function handleBidCtPrChange(input) {
  const { row, value } = input;

  if (BidService.calculateOnDiscount) {
    const bidDiscount = parseDecimal((1 - value / row.rap) * -100);
    return handleBidBackChange({ ...input, value: bidDiscount });
  }

  const max = row.ctPr + BidService.diffUpward;
  const min = row.ctPr - BidService.diffDownward;
  const originalValues = tableValues.state[row.vStnId];

  notify.destroy();

  // if (BidService.isAuction && value < row.maxPricePerCarat) {
  //   notify.error({ message: `Your bid must be higher than ${parseDecimal(row.maxPricePerCarat)}` });
  //   tableValues.setValues({ [row.vStnId]: { ...originalValues } });
  //   return;
  // }

  if (value < min) {
    notify.error({ message: `Your bidding amount cannot be lower then ${parseDecimal(min)}. Please, try again.` });
    tableValues.setValues({ [row.vStnId]: { ...originalValues } });
    return;
  }

  if (value > max) {
    notify.error({ message: `Your bidding amount cannot be higher then ${parseDecimal(max)}. Please, try again.` });
    tableValues.setValues({ [row.vStnId]: { ...originalValues } });
    return;
  }

  const bidDiscount = !row?.hasBidDiscount ? 0 : parseDecimal((1 - value / row.rap) * -100);
  const bidPricePerCarat = parseDecimal(value);
  const bidAmount = parseDecimal((value || 0) * row.crt);
  const updatedValues = { bidDiscount, bidPricePerCarat, bidAmount };

  tableValues.setValues({ [row.vStnId]: updatedValues });
}

export function handleChange(input) {
  const { name } = input;

  switch (name) {
    case 'bidDiscount': {
      return handleBidBackChange(input);
    }

    case 'bidPricePerCarat': {
      return handleBidCtPrChange(input);
    }

    default:
      return;
  }
}

export function handleStep(type = '', input) {
  const { name, row } = input;
  const stepDifference = type === 'up' ? BidService.stepUp : type === 'down' ? BidService.stepDown : 0;

  input.value = Number(input.value) + stepDifference;

  switch (name) {
    case 'bidDiscount': {
      return handleBidBackChange(input);
    }

    case 'bidPricePerCarat': {
      return BidService.calculateOnDiscount
        ? handleBidBackChange({ ...input, value: Number(getRow(row).bidDiscount) + stepDifference })
        : handleBidCtPrChange(input);
    }

    default:
      return;
  }
}

export function handleIncrease(input) {
  return handleStep('up', input);
}

export function handleDecrease(input) {
  return handleStep('down', input);
}
