import React from 'react';

import { Duration } from 'luxon';

import { BidService } from 'services/BidService';

import { useBoolean } from 'util/hooks';
import { catchError, classNames } from 'util/utils';

import styles from './BidCountdown.module.less';

function Component() {
  const [showTimer, setShowTimer] = useBoolean();
  const [endingSoon, setEndingSoon] = useBoolean();

  const hoursRef = React.useRef();
  const minutesRef = React.useRef();
  const secondsRef = React.useRef();

  React.useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      const bidEndDuration = BidService.bidEndDuration;
      const duration =
        Duration.isDuration(bidEndDuration) && bidEndDuration?.isValid ? bidEndDuration : Duration.fromMillis(0);

      const totalSeconds = duration.as('seconds');
      if (totalSeconds < 1) {
        setShowTimer.false();
        setEndingSoon.false();
        catchError(() => {
          hoursRef.current.innerText = '';
          minutesRef.current.innerText = '';
          secondsRef.current.innerText = '';
        });
        return;
      }

      const [hours, minutes, seconds] = duration.toFormat('hh:mm:ss').split(':');
      catchError(() => {
        if (hoursRef.current.innerText !== hours) hoursRef.current.innerText = hours;
        if (minutesRef.current.innerText !== minutes) minutesRef.current.innerText = minutes;
        if (secondsRef.current.innerText !== seconds) secondsRef.current.innerText = seconds;
      });
      setShowTimer.true();

      if (duration.as('minutes') < 10) setEndingSoon.true();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [setEndingSoon, setShowTimer]);

  return (
    showTimer && (
      <div className={classNames([styles.Countdown, endingSoon && styles.EndingSoon])}>
        <div className={classNames([styles.Box])}>
          <div ref={hoursRef} className={classNames([styles.Number])} />
          <div className={classNames([styles.Label])}>Hours</div>
        </div>
        <div className={classNames([styles.Box])}>
          <div ref={minutesRef} className={classNames([styles.Number])} />
          <div className={classNames([styles.Label])}>Minutes</div>
        </div>
        <div className={classNames([styles.Box])}>
          <div ref={secondsRef} className={classNames([styles.Number])} />
          <div className={classNames([styles.Label])}>Seconds</div>
        </div>
      </div>
    )
  );
}

export const BidCountdown = React.memo(Component);
