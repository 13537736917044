import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Checkbox } from 'antd';
import _, { join, uniq } from 'lodash';
import map from 'lodash/map';

import SendEmailPopup from './SendEmail';

import { BASE_DOWNLOAD_URL, LOCAL_STORAGE_VAR, SHARE_OPTIONS } from '../../../constants/Common';
import UtilService from '../../../services/util';
import { isMobile, LISTINGPAGES, shareExcel } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';

const URL_ID = 'id';

const ShareDropdown = (props) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [totalChecked, setTotalChecked] = useState([]);
  const [images, setImages] = useState(false);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // const ShareOption = (props) => {
  //   return (
  //     <li id={props.uiid} className={props.activeClass}>
  //       <div className="dropdownBottomItem">
  //         <div className="dropdownIconCheckBox">
  //           <Checkbox
  //             onChange={props.onChange}
  //             checked={props.checked}
  //           ></Checkbox>
  //         </div>
  //         <span className="shareOptionLabel">{props.title}</span>
  //       </div>
  //     </li>
  //   );
  // };

  // const WhatsAppShare = () => {
  //   let totalTrue = 0;
  //   Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
  //   if (totalTrue >= 1) {
  //     let links = ["Unique Diamonds : Diamond Detail"];
  //     props.checked.forEach((i) => {
  //       links.push(`${window.location.origin}/${LISTINGPAGES.DETAIL}/${i.id}`);
  //     });
  //     window.open(
  //       `https://web.whatsapp.com/send?text=${join(links, "%0A%0A")}`
  //     );
  //   } else {
  //     OpenNotification({
  //       type: "error",
  //       title: "Please Select atleast 1 Option to Share.",
  //     });
  //   }
  // };

  // const SkypeShare = () => {
  //   let totalTrue = 0;
  //   Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
  //   if (totalTrue >= 1) {
  //     let links = ["Unique Diamonds : Diamond Detail"];
  //     props.checked.forEach((i) => {
  //       links.push(`${window.location.origin}/${LISTINGPAGES.DETAIL}/${i.id}`);
  //     });
  //     window.open(`https://web.skype.com/share?url=${join(links, "%0A%0A")}`);
  //   } else {
  //     OpenNotification({
  //       type: "error",
  //       title: "Please Select atleast 1 Option to Share.",
  //     });
  //   }
  // };
  const SkypeShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        let msg = [];
        let dna = [];
        shareExcel(map(props.checked, 'vStnId'), (path) => {
          const paths = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${props.checked[0][URL_ID]}`;
          msg.push(`Download Excel: \n ${BASE_DOWNLOAD_URL}${path}`);
          dna.push(` \n ${paths}`);
          msg = uniq(msg);
          dna = uniq(dna);
          // const path1 = `${BASE_DOWNLOAD_URL}${path}`;
          window.open(`skype:?chat&topic=${join([dna, msg], '%0A%0A')}`);
        });

        //window.open(`https://web.skype.com/share?url=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = [];
        let dna = ['Diamond Details :'];
        props.checked.forEach((element) => {
          const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${element[URL_ID]}`;
          dna += path + '%0A%0A';
        });
        shareExcel(map(props.checked, 'vStnId'), (path) => {
          msg.push(`Download Excel : \n ${BASE_DOWNLOAD_URL}${path}`);
          msg = uniq(msg);

          window.open(`skype:?chat&topic=${join([dna, msg], '%0A%0A')}`);
        });

        //window.open(`https://web.skype.com/share?url=${msg}`);
      }
    }
  };
  const WhatsAppShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        let msg = [];
        let dna = ['Diamond Details :'];
        shareExcel(map(props.checked, 'vStnId'), (path) => {
          const paths = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${props.checked[0][URL_ID]}`;
          dna.push(` \n ${paths}`);
          msg.push(`Download Excel: \n ${BASE_DOWNLOAD_URL}${path}`);
          msg = uniq(msg);
          dna = uniq(dna);

          window.open(`https://web.whatsapp.com/send?text=${join([dna, msg], '%0A%0A')}`);
        });
      } else if (props.checked.length >= 2) {
        let msg = [];
        let dna = ['Diamond Details :'];
        props.checked.forEach((element) => {
          const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${element[URL_ID]}`;
          dna += path + '%0A%0A';
        });
        shareExcel(map(props.checked, 'vStnId'), (path) => {
          msg.push(`Download Excel : \n ${BASE_DOWNLOAD_URL}${path}`);
          msg = uniq(msg);

          window.open(`https://web.whatsapp.com/send?text=${join([dna, msg], '%0A%0A')}`);
        });

        // window.open(`https://web.whatsapp.com/send?text=${msg}`);
      }
    }
  };
  // useEffect(() => {
  //   if (SHARE_OPTIONS.Images) {
  //     let mappedImagesKeys = _.map(SHARE_OPTIONS.Images.subLevel, "key");
  //     Object.keys(totalChecked).forEach((v) => {
  //       mappedImagesKeys.includes(v) && (totalChecked[v] = images);
  //     });
  //     forceUpdate();
  //   }
  // }, [images]);

  // useEffect(() => {
  //   if (SHARE_OPTIONS.Videos) {
  //     let mappedVideosKeys = _.map(SHARE_OPTIONS.Videos.subLevel, "key");
  //     Object.keys(totalChecked).forEach((v) => {
  //       mappedVideosKeys.includes(v) && (totalChecked[v] = videos);
  //     });
  //     forceUpdate();
  //   }
  // }, [videos]);

  // useEffect(() => {
  //   if (SHARE_OPTIONS.Roughs) {
  //     let mappedCertificatesKeys = _.map(
  //       SHARE_OPTIONS.Certificates.subLevel,
  //       "key"
  //     );
  //     Object.keys(totalChecked).forEach((v) => {
  //       mappedCertificatesKeys.includes(v) && (totalChecked[v] = certificates);
  //     });
  //     forceUpdate();
  //   }
  // }, [certificates]);

  // useEffect(() => {
  //   if (SHARE_OPTIONS.Roughs) {
  //     let mappedRoughsKeys = _.map(SHARE_OPTIONS.Roughs.subLevel, "key");
  //     Object.keys(totalChecked).forEach((v) => {
  //       mappedRoughsKeys.includes(v) && (totalChecked[v] = roughs);
  //     });
  //     forceUpdate();
  //   }
  // }, [roughs]);

  // useEffect(() => {
  //   if (SHARE_OPTIONS.Excels) {
  //     let mappedExcelssKeys = _.map(SHARE_OPTIONS.Excels.subLevel, "key");
  //     Object.keys(totalChecked).forEach((v) => {
  //       mappedExcelssKeys.includes(v) && (totalChecked[v] = excels);
  //     });
  //     forceUpdate();
  //   }
  // }, [excels]);

  // const getAllKeys = () => {
  //   let keys = _.uniq(
  //     _.flattenDeep(
  //       _.map(SHARE_OPTIONS, (val, key) => {
  //         let tmp = [];
  //         if (val && val.key) {
  //           tmp.push(val.key);
  //         }
  //         if (val && val.subLevel && val.subLevel.length) {
  //           tmp = _.concat(tmp, _.map(val.subLevel, "key"));
  //         }
  //         return tmp;
  //       })
  //     )
  //   );
  //   return keys;
  // };

  // useEffect(() => {
  //   let definedKeys = getAllKeys();
  //   definedKeys.forEach((key) => {
  //     setTotalChecked((totalChecked) => ({
  //       ...totalChecked,
  //       [key]: false,
  //     }));
  //   });
  // }, []);
  return (
    <>
      <div>
        <div className="searchPopupCommon">
          {/* <div className="shareOptionSet d-flex">
            <div className="shareOptionSetItem">
              {SHARE_OPTIONS &&
                SHARE_OPTIONS.Images &&
                SHARE_OPTIONS.Images.subLevel ? (
                <ul>
                  {SHARE_OPTIONS.Images.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={images}
                            onChange={() => {
                              setImages(!images);
                            }}
                          >
                            <span>{SHARE_OPTIONS.Images.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {SHARE_OPTIONS.Images.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            activeClass={
                              totalChecked[record.key] ? "active" : ""
                            }
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : SHARE_OPTIONS.Images ? (
                <div className="singleOnly">
                  <ShareOption
                    title={SHARE_OPTIONS.Images.label}
                    activeClass={
                      totalChecked[SHARE_OPTIONS.Images.key] ? "active" : ""
                    }
                    checked={totalChecked[SHARE_OPTIONS.Images.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [SHARE_OPTIONS.Images.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}

              {SHARE_OPTIONS &&
                SHARE_OPTIONS.Videos &&
                SHARE_OPTIONS.Videos.subLevel ? (
                <ul>
                  {SHARE_OPTIONS.Videos.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={videos}
                            onChange={() => {
                              setVideos(!videos);
                            }}
                          >
                            <span>{SHARE_OPTIONS.Videos.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {SHARE_OPTIONS.Videos.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            activeClass={
                              totalChecked[record.key] ? "active" : ""
                            }
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : SHARE_OPTIONS.Videos ? (
                <div className="singleOnly">
                  <ShareOption
                    title={SHARE_OPTIONS.Videos.label}
                    activeClass={
                      totalChecked[SHARE_OPTIONS.Videos.key] ? "active" : ""
                    }
                    checked={totalChecked[SHARE_OPTIONS.Videos.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [SHARE_OPTIONS.Videos.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}

              {SHARE_OPTIONS &&
                SHARE_OPTIONS.Certificates &&
                SHARE_OPTIONS.Certificates.subLevel ? (
                <ul>
                  {SHARE_OPTIONS.Certificates.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={certificates}
                            onChange={() => {
                              setCertificates(!certificates);
                            }}
                          >
                            <span>{SHARE_OPTIONS.Certificates.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {SHARE_OPTIONS.Certificates.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            activeClass={
                              totalChecked[record.key] ? "active" : ""
                            }
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : SHARE_OPTIONS.Certificates ? (
                <div className="singleOnly">
                  <ShareOption
                    title={SHARE_OPTIONS.Certificates.label}
                    activeClass={
                      totalChecked[SHARE_OPTIONS.Certificates.key]
                        ? "active"
                        : ""
                    }
                    checked={totalChecked[SHARE_OPTIONS.Certificates.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [SHARE_OPTIONS.Certificates.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}

              {SHARE_OPTIONS &&
                SHARE_OPTIONS.Roughs &&
                SHARE_OPTIONS.Roughs.subLevel ? (
                <ul>
                  {SHARE_OPTIONS.Roughs.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={roughs}
                            onChange={() => {
                              setRoughs(!roughs);
                            }}
                          >
                            <span>{SHARE_OPTIONS.Roughs.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {SHARE_OPTIONS.Roughs.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            activeClass={
                              totalChecked[record.key] ? "active" : ""
                            }
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : SHARE_OPTIONS.Roughs ? (
                <div className="singleOnly">
                  <ShareOption
                    title={SHARE_OPTIONS.Roughs.label}
                    activeClass={
                      totalChecked[SHARE_OPTIONS.Roughs.key] ? "active" : ""
                    }
                    checked={totalChecked[SHARE_OPTIONS.Roughs.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [SHARE_OPTIONS.Roughs.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}

              {SHARE_OPTIONS &&
                SHARE_OPTIONS.Excels &&
                SHARE_OPTIONS.Excels.subLevel ? (
                <ul>
                  {SHARE_OPTIONS.Excels.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={excels}
                            onChange={() => {
                              setExcels(!excels);
                            }}
                          >
                            <span>{SHARE_OPTIONS.Excels.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {SHARE_OPTIONS.Excels.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            activeClass={
                              totalChecked[record.key] ? "active" : ""
                            }
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : SHARE_OPTIONS.Excels ? (
                <div className="singleOnly">
                  <ShareOption
                    title={SHARE_OPTIONS.Excels.label}
                    activeClass={
                      totalChecked[SHARE_OPTIONS.Excels.key] ? "active" : ""
                    }
                    checked={totalChecked[SHARE_OPTIONS.Excels.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [SHARE_OPTIONS.Excels.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div> */}
          <div className="commonModalButton mt-50">
            {isMobile() ? (
              <div
                className="smallSliderImage"
                onClick={() => {
                  let totalTrue = 0;
                  Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
                  setSendEmail(true);
                }}
              >
                <img src={require('../../../assets/svg/DiamondList/email.svg')} alt="" />
              </div>
            ) : (
              <button
                className="fillButton"
                onClick={() => {
                  let totalTrue = 0;
                  Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
                  // if (totalTrue >= 1) {
                  setSendEmail(true);
                  // } else {
                  //   OpenNotification({
                  //     type: "error",
                  //     title: "Please Select atleast 1 Option to Share.",
                  //   });
                  // }
                }}
              >
                Email
              </button>
            )}
            {isMobile() ? (
              <div className="smallSliderImage" onClick={WhatsAppShare}>
                <img src={require('../../../assets/svg/DiamondList/whatsapp.svg')} alt="" />
              </div>
            ) : (
              <button onClick={WhatsAppShare} className="fillButton ml-5">
                WhatsApp
              </button>
            )}
            {isMobile() ? (
              <div className="smallSliderImage" onClick={SkypeShare}>
                <img src={require('../../../assets/svg/DiamondList/skype.svg')} alt="" />
              </div>
            ) : (
              <button onClick={SkypeShare} className="fillButton ml-5">
                Skype
              </button>
            )}
          </div>
        </div>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
        ids={map(props.checked, 'id')}
        totalChecked={totalChecked}
      />
    </>
  );
};
export default connect((props) => {
  return props;
}, {})(withRouter(ShareDropdown));
