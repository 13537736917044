import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';
import invert from 'lodash/invert';
import size from 'lodash/size';

import { usePathname } from 'util/hooks';
import { formatDecimal } from 'util/utils';

import MobileConfirmStone from './MobileComfirmStone';
import { calculate, formatNumber } from './SelectStone';
import SendEmailPopup from './SendEmail';

import { LOCAL_STORAGE_VAR, SELECT_STONE_TERMS_NEW } from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import {
  handleConfirmStone,
  handleDownloadExcel,
  isMobile,
  LISTINGPAGES,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import OpenNotification from '../CommonButton/OpenNotification';
import Heading from '../Heading';
import InputBlock from '../InputBlock';
import SelectOption from '../SelectOption';
import TextArea from '../TextArea';

export const savedSearch = {
  saleman: [{ value: 'John Doe' }, { value: 'John Doe' }],
  invoicDate: [
    { id: '1', name: 'Today' },
    { id: '2', name: 'Tommorrow' },
    { id: '3', name: 'Later' },
  ],
  country: [{ value: 'India' }, { value: 'Hong Kong' }, { value: 'US' }],
  city: [{ value: 'Gujarat' }, { value: 'baroda' }, { value: 'Vapi' }],
  billType: [{ value: 'Dollar Bill' }],
  couriername: [{ value: 'Any' }, { value: 'Malca Amit4' }],
  sale: [{ value: 'Idex Ok' }, { value: 'Blue Nile Ok' }],
  terms: [{ value: '7 Days' }, { value: 'Advance' }, { value: 'COD' }],
  assi: [{ value: 'Vishal Virani' }, { value: 'Vishal Virani' }, { value: 'Vishal Virani' }],
};
const currentType = 'ConfirmStonePopup';

export function HeadingCalc(checked, VALUES = null, isBidDis) {
  if (isMobile() && !checked.length && !VALUES) return null;

  const obj = (() => {
    if (VALUES) {
      return VALUES;
    }

    const sum = calculate(checked, null, isBidDis);

    return {
      'Pieces:': sum.total_pieces,
      'Crt:': formatDecimal(sum.total_carat),
      // 'Disc% :': formatDecimal(sum.final_discount),
      'Pr/Ct :': formatDecimal(sum.final_price),
      'Amt. :': formatDecimal(sum.final_value),
    };
  })();

  // if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;

  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>
              {isMobile()
                ? x === 'Bid Term Discount(%)' || x === 'Avg. Discount(%)' || x === 'TERMS'
                  ? obj[x] > 0
                    ? `+${obj[x]}`
                    : formatDecimal(obj[x])
                  : obj[x]
                : formatDecimal(obj[x])}
            </span>
          </div>
        );
      })}
    </div>
  );
}

const ConfirmStonePopup = (props) => {
  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const term = localStorage.getItem('Terms');
  const [comment, setComment] = useState('');
  const [company, setCompany] = useState(user && user.account ? user.account.companyName : '');
  const [date, setDate] = useState('1');
  const [termCal, setTermCal] = useState(term === 'true' ? '' : term);
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [settings, setSettings] = useState([]);
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      const grp = groupBy(settings, 'shp');
      const shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      const carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  const getTerms = () => {
    return (
      <div>
        <select
          name="termCal"
          value={termCal}
          placeholder="Select Terms"
          onChange={(e) => {
            // let s = calculate(checked, 2)
            localStorage.setItem('Terms', false);
            setTermCal(e.target.value);
          }}
        >
          {/* <option value='00' disabled>Select Terms</option> */}
          <option value="" disabled selected hidden>
            Select Terms
          </option>
          {SELECT_STONE_TERMS_NEW.map((x) => (
            <option value={x.id}>{x.name}</option>
          ))}
        </select>
      </div>
    );
  };

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  const newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  // let summation = calculate(newPricedRows);
  const summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);
  const finalValue = parseFloat(summation.final_net_value).toFixed(2);
  const promotionalDis = props.promotionDis?.data;
  const maxAmt = Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100);

  const totalPieces = summation.total_pieces;
  const totalCarat = parseFloat(summation.total_carat).toFixed(2);
  const rapPrice = summation.final_rapaport;
  const avgDisc = parseFloat(summation.final_discount).toFixed(2);
  const avgPr = summation.final_price;

  const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));

  const addDis = parseFloat(summation.addDis).toFixed(2);
  const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
  localStorage.setItem('TermsDis', termDisc);
  const sysDis = summation.sysDis;
  const finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
  //let sysAmtDisc = Number(summation.final_value) * (Number(summation.sysDis) / 100);
  const finalAvgDisc = parseFloat(Number(summation.final_back)).toFixed(2);
  const finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
  //let CmCharge = parseFloat(getCmCharge(newPricedRows)).toFixed(2);
  const terms = getTerms();
  const profinalNetValue =
    parseFloat(finalValue) > props.promotionDis?.maxAmt
      ? parseFloat(finalValue) + maxAmt
      : parseFloat(
          parseFloat(summation.final_net_value) + parseFloat(summation.final_net_value) * (promotionalDis / 100),
        ).toFixed(2);
  const profinalNetCtPr = parseFloat(parseFloat(profinalNetValue) / parseFloat(totalCarat)).toFixed(2);
  const VALUES = isMobile()
    ? {
        'Total Pieces': totalPieces,
        'Total Carats:': totalCarat,
        'Rap Prices': rapPrice,
        'Avg. Discount(%)': avgDisc,
        'Total Pr/Ct': avgPr,
        'Net Value': netValue,
        'Amt Discount(%)': sysDis,
        'Terms Discount(%)': Number(addDis) <= 0 ? addDis : `+${addDis}`,
        'Final Discount(%)': props.promotionDis?.data
          ? parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)
          : finalAvgDisc,
        'Final Net Rate': props.promotionDis?.data ? formatNumber(profinalNetCtPr) : finalNetRate,
        // 'Final Net Value': finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
        TERMS: terms,
      }
    : [
        { title: 'Total Pieces', value: totalPieces },
        { title: 'Total Carats', value: totalCarat },
        { title: 'Rap Prices', value: rapPrice },
        { title: 'Avg. Discount(%)', value: avgDisc },
        { title: 'Total Pr/Ct', value: avgPr },
        { title: 'Net Value', value: netValue },
        { title: 'Amt Discount(%)', value: sysDis },
        { title: 'Terms Discount(%)', value: Number(addDis) <= 0 ? addDis : `+${addDis}` },
        {
          title: 'Final Discount(%)',
          value: props.promotionDis?.data
            ? parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)
            : finalAvgDisc,
        },
        { title: 'Final Net Rate', value: props.promotionDis?.data ? formatNumber(profinalNetCtPr) : finalNetRate },
        {
          title: 'Final Net Value',
          value: props.promotionDis?.data ? formatNumber(profinalNetValue) : finalNetValue,
        },
        {
          title: 'TERMS',
          value: terms,
        },
      ];
  //props.promotionDis?.data !== 0 && VALUES.push({ title: 'promotional Dis(%)', value: props.promotionDis?.data })
  const [email, sendEmail] = useState('');

  const getcompany = () => {
    return (
      <IntlMessages id="app.CompanyName*">
        {(placeholder) => (
          <InputBlock
            label={<IntlMessages id="app.CompanyName*" />}
            placeholder={placeholder}
            value={company}
            disabled
            // onChange={e => setCompany(e.target.value)}
          />
        )}
      </IntlMessages>
    );
  };
  const getinvoicedate = () => {
    return (
      <SelectOption
        selectValue={savedSearch.invoicDate}
        placeholder="Invoice Date"
        label="Select Invoice Date*"
        value={date}
        onChange={(e) => setDate(e)}
      />
    );
  };
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    const selectTerm = localStorage.getItem('Terms');
    if (!date) {
      OpenNotification({ type: 'error', title: 'Please select invoice date.' });
      return;
    } else if (!company) {
      OpenNotification({ type: 'error', title: 'Please enter company name.' });
      return;
    } else if (selectTerm === 'true' || !termCal) {
      OpenNotification({
        type: 'error',
        title: 'You need to select term before confirm',
      });
    } else if (!checkCheck()) {
      return;
    } else {
      handleConfirmStone(
        checked.map((x) => x.id),
        comment,
        date,
        company,
        props.promotionDis?.data,
        (flag) => {
          props.onClose();
          if (props.clearAll) props.clearAll();
          if (flag && props.fetch) props.fetch();
        },
      );
    }
  };
  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };
  const gettextarea = () => {
    return isMobile() ? (
      <IntlMessages id="app.comment">
        {(placeholder) => (
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label={<IntlMessages id="app.comment" />}
            placeholder={placeholder}
          />
        )}
      </IntlMessages>
    ) : (
      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
    );
  };

  if (isMobile())
    return (
      <MobileConfirmStone
        data={props.checked}
        VALUES={VALUES}
        currentType={currentType}
        parent={{
          getcompany,
          getinvoicedate,
          submit,
          cancel,
          gettextarea,
          checkCheck,
        }}
      />
    );
  return (
    <div className="confirmStonePopUp">
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading className="popupInnerTitle mr-40" title={<IntlMessages id="app.confirmStone" />}></Heading>
        {/* {HeadingCalc(checked)} */}
      </div>
      <div className={`searchPopupCommonTable ${props.popupClass ? props.popupClass : null}`} style={props.style}>
        <div className="searchResultTable tabInnerTableScroll" style={props.detail ? { height: 'auto' } : {}}>
          <Table
            data={props.checked}
            nodots
            areAllChecked={true}
            currentType={currentType}
            canSort={false}
            // nocheck={props.detail}
            noGrp
          />
        </div>
        <div className="DiamondDetailPopup mt-10">
          {VALUES.map((x) => {
            return (
              <div className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>
                  {x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)'
                    ? x.value > 0
                      ? `+${x.value}`
                      : x.value
                    : x.title === 'promotional Dis(%)'
                    ? parseFloat(finalValue) > props.promotionDis?.maxAmt
                      ? `${x.value} ($${Math.abs(
                          Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100),
                        )})`
                      : `${x.value} ($${Math.abs(
                          parseFloat(parseFloat(finalValue) * (promotionalDis / 100)).toFixed(2),
                        )})`
                    : x.value}
                </span>
              </div>
            );
          })}
        </div>
        <div className="mt-10 finalCalDetail confirmListOption" style={props.detail ? { padding: '5px' } : {}}>
          {/* <div className="DiamondDetailPopup width-100"> */}

          {getcompany()}
          {/* {getinvoicedate()} */}
          <div className="commonTextArea from-group">
            <label>
              <IntlMessages id="app.comment" />
            </label>
            {gettextarea()}
          </div>
          {!props.detail && (
            <p className="offerNote">
              <b>
                <IntlMessages id="app.Note" />:
              </b>{' '}
              <IntlMessages id="app.confirmStoneNote" />
            </p>
          )}
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            <IntlMessages id="app.confirmStone" />
          </a>
          <a className="commonButton" onClick={cancel}>
            <IntlMessages id="app.Cancel" />
          </a>
          {!props.detail && (
            <>
              <a
                className="commonButton"
                onClick={() =>
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                  )
                }
              >
                <IntlMessages id="app.ExcelExport" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail('stock');
                  else
                    OpenNotification({
                      type: 'error',
                      title: 'Please select any stone(s).',
                    });
                }}
              >
                <IntlMessages id="app.EmailStock" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail('img');
                  else
                    OpenNotification({
                      type: 'error',
                      title: 'Please select any stone(s).',
                    });
                }}
              >
                <IntlMessages id="app.EmailPic" />
              </a>
              <SendEmailPopup
                sendEmail={email}
                onCancel={() => sendEmail('')}
                ids={checked.map((x) => x.id)}
                img={email === 'img'}
                isStock={email === 'stock'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmStonePopup;
