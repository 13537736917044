import React from 'react';

import videoJs from 'video.js';

import { catchError, classNames, logWarn, typeOf } from 'util/utils';

import 'video.js/dist/video-js.css';

/**
 * VideoPlayer
 * Common Wrapper for VideoJs player
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export const VideoPlayer = React.memo((props) => {
  const { className, src, type, ...forwardProps } = props;
  const [videoNode, setVideoNode] = React.useState();

  const player = React.useMemo(() => {
    if (!typeOf(videoNode, 'HTMLVideoElement')) return;

    try {
      const options = {
        muted: forwardProps?.autoplay,
        aspectRatio: '16:9',
        playbackRates: [0.25, 0.5, 1.0, 1.5, 2.0],
        sources: [{ src, type }],
        ...forwardProps,
      };
      return videoJs(videoNode, options);
    } catch (error) {
      logWarn({ VideoPlayerError: error });
    }
  }, [forwardProps, src, type, videoNode]);

  React.useEffect(() => {
    return () => {
      try {
        void player?.dispose?.();
      } catch (error) {
        logWarn({ VideoPlayerError: error });
      }
    };
  }, [player]);

  React.useEffect(() => {
    if (!typeOf(videoNode, 'HTMLVideoElement')) return;

    catchError(
      () => videoNode.addEventListener('contextmenu', (e) => e.preventDefault()),
      (error) => logWarn({ VideoPlayerError: error }),
    );

    return () => {
      catchError(
        () => videoNode.removeEventListener('contextmenu', (e) => e.preventDefault()),
        (error) => logWarn({ VideoPlayerError: error }),
      );
    };
  }, [videoNode]);

  return (
    <div className={classNames([className])}>
      <div data-vjs-player>
        <video ref={setVideoNode} className="video-js"></video>
      </div>
    </div>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
