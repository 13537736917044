import React from 'react';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { VideoPlayer } from 'components/MediaPlayer/VideoPlayer';

import { Storage } from 'services/Storage';

import { IntlMessage } from 'util/IntlMessages';
import { classNames, isEmpty } from 'util/utils';

import { FILE_URLS } from 'constants/Common';

import MagnifierImage from './MagnifierImage';

import 'components/common/Tab/tab.less';

const DiamondDetailLeft = (props) => {
  const { item, shared, style } = props;

  const hasToken = !isEmpty(Storage.get('token'));
  const hasImg = shared?.find?.((c) => c === 'isImg=true');
  const hasVideo = shared?.find?.((c) => c === 'isVideo=true');
  const hasCert = shared?.find?.((c) => c === 'isCert=true');
  const hasAsset = shared?.find?.((c) => c === 'isAsset=true');
  const hasArrow = shared?.find?.((c) => c === 'isArrow=true');
  const hasHeart = shared?.find?.((c) => c === 'isHa=true');

  const [activeTab, setActiveTab] = React.useState(
    hasImg
      ? '1'
      : hasVideo
      ? '2'
      : hasHeart
      ? '3'
      : hasCert
      ? '4'
      : hasArrow
      ? '5'
      : hasAsset
      ? '6'
      : item?.img
      ? '1'
      : item?.videoFile
      ? '2'
      : item?.hAFile
      ? '3'
      : item?.certFile
      ? '4'
      : item?.arrowFile
      ? '5'
      : item?.assetFile
      ? '6'
      : undefined,
  );

  if (!activeTab) return null;

  return (
    <>
      {(hasToken || shared?.length > 1) && (
        <Nav tabs className="customerMaintab diamondDetailTopTab">
          {
            <NavItem>
              <NavLink className={classNames([activeTab === '1' && 'active'])} onClick={() => setActiveTab('1')}>
                <IntlMessage id="app.Image" />
              </NavLink>
            </NavItem>
          }
          {
            <NavItem>
              <NavLink className={classNames([activeTab === '2' && 'active'])} onClick={() => setActiveTab('2')}>
                <IntlMessage id="app.Video" />
              </NavLink>
            </NavItem>
          }
          {item?.hAFile && (hasToken || hasHeart) && (
            <NavItem>
              <NavLink className={classNames([activeTab === '3' && 'active'])} onClick={() => setActiveTab('3')}>
                H&amp;A
              </NavLink>
            </NavItem>
          )}

          {
            <NavItem>
              <NavLink className={classNames([activeTab === '4' && 'active'])} onClick={() => setActiveTab('4')}>
                <IntlMessage id="app.Certificate" />
              </NavLink>
            </NavItem>
          }
          {item?.arrowFile && (hasToken || hasArrow) && (
            <NavItem>
              <NavLink className={classNames([activeTab === '5' && 'active'])} onClick={() => setActiveTab('5')}>
                <IntlMessage id="app.Arrow" />
              </NavLink>
            </NavItem>
          )}
          {item?.assetFile && (hasToken || hasAsset) && (
            <NavItem>
              <NavLink className={classNames([activeTab === '6' && 'active'])} onClick={() => setActiveTab('6')}>
                <IntlMessage id="app.Asset" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      )}
      {(hasToken || shared?.length > 1) && (
        <TabContent activeTab={activeTab} style={style}>
          <TabPane tabId="1">
            <Slider arrows={false}>
              {item?.img ? (
                <div className="diamondDetailImageBox">
                  <MagnifierImage image={item?.files?.img} />
                </div>
              ) : (
                <div className="diamondDetailImageBox no-msg">No Image available.</div>
              )}
            </Slider>
          </TabPane>
          <TabPane tabId="2">
            {item?.videoFile ? (
              <div className="diamondDetailImageBox htmlViewImage">
                <VideoPlayer src={item?.files?.videoFile} aspectRatio="4:3" autoplay loop controls responsive />
              </div>
            ) : (
              <div className="diamondDetailImageBox no-msg">No video available.</div>
            )}
          </TabPane>
          {/* {item?.hAFile && (hasToken || hasHeart) && (
            <TabPane tabId="3">
              <Slider arrows={false}>
                <div className="diamondDetailImageBox">
                  <MagnifierImage image={FILE_URLS.hAFile.replace('***', item?.vStnId)} />
                </div>
              </Slider>
            </TabPane>
          )} */}
          <TabPane tabId="4">
            {item?.certFile && item?.files?.certFile !== 'NO' ? (
              <div className="diamondDetailImageBox">
                {/* <iframe
                  src={item?.files?.certFile}
                  height="100%"
                  width="100%"
                  title="certi"
                  scrolling="no"
                  frameBorder="0"
                /> */}
                <MagnifierImage image={item?.files?.certFile} />
              </div>
            ) : (
              <div className="imageSliderFullBlock no-msg">No certificate available.</div>
            )}
          </TabPane>
          {/* {item?.arrowFile && (hasToken || hasArrow) && (
            <TabPane tabId="5">
              <div className="diamondDetailImageBox">
                <MagnifierImage image={FILE_URLS.arrowFile.replace('***', item?.vStnId)} />
              </div>
            </TabPane>
          )} */}
          {/* {item?.assetFile && (hasToken || hasAsset) && (
            <TabPane tabId="6">
              <div className="diamondDetailImageBox">
                <MagnifierImage image={FILE_URLS.assetFile.replace('***', item?.vStnId)} />
              </div>
            </TabPane>
          )} */}
        </TabContent>
      )}
    </>
  );
};

export default DiamondDetailLeft;
