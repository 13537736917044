import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Radio } from 'antd';
import { findIndex, get } from 'lodash';
import moment from 'moment';

import { BidCountdown } from 'components/Bid/BidCountdown';
import BidCartList from 'components/DiamondList/BidCartList';
import Table from 'components/DiamondList/TableBack';
import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import SelectStone from 'components/common/DiamondListing/SelectStone';
import { Heading } from 'components/common/Heading';
import NoDataShow from 'components/common/NoDataShow';

import { Storage } from 'services/Storage';

import { IntlMessage } from 'util/IntlMessages';
import { useCompositeState, usePathname } from 'util/hooks';
import { notify } from 'util/notify';
import { formatDecimal, isEmpty } from 'util/utils';

import { DIAMOND_BID, LOCAL_STORAGE_VAR, PAGES, PAGE_TITLE } from 'constants/Common';

import { store } from 'appRedux/store';

import { fetchBid, getColumn, TITLE, LISTINGPAGES, fetchBidCart, handleBidDelete } from './DiamondListFunctions';
import { getState } from './diamondListUtils';

import { getBidType } from '../NewArrivalBidIt';

import { isArrayEqual } from '.';

const BID_TAB = { LIVE: '1', MY_BID: '2', HISTORY: '3' };

const BidList = (props) => {
  const dimaondDetailPage = window.location.pathname?.split('/')?.[1];

  const bidConfigState = useSelector((store) => store.bidConfig);
  const bidConfig = bidConfigState.data;
  const hasBidConfig = !bidConfig.loading && !isEmpty(bidConfig);

  const pathname = usePathname();
  const dispatch = useDispatch();
  const tableRef = React.useRef();
  const currentType = pathname;

  const [state, setState] = useCompositeState(getState);
  const sortRef = React.useRef([]);

  const bidType = hasBidConfig ? BID_TAB.LIVE : BID_TAB.MY_BID;

  const [showError, setShowError] = React.useState(false);
  const [dataList, setDataList] = React.useState();
  const USER_PERMIT = React.useMemo(() => Storage.get('userPermissions'), []);

  const [date] = React.useState({
    from: moment().startOf('day').add(-6, 'days'),
    to: moment(),
  });
  const selectedRows = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const check = selectedRows;

  const clearAllSelectedRows = React.useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);

  const fetchcolumn = () => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    const ctr = Columns.filter((el) => el.id === 'ctPr');
    const amnt = Columns.filter((el) => el.id === 'amt');
    Columns = Columns.filter((el) => el.id !== 'ctPr' && el.id !== 'amt');
    const columns = [
      bidType === BID_TAB.HISTORY
        ? {
            Header: 'Type',
            accessor: 'bidType',
            Cell: ({ cell }) => (cell.value === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'),
          }
        : null,
      bidType === BID_TAB.HISTORY
        ? {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) =>
              cell.value === DIAMOND_BID.STATUS.WIN ? (
                <div className="activeStatus">
                  <span>Win</span>
                </div>
              ) : (
                <div className="activeStatus red">
                  <span>Loss</span>
                </div>
              ),
          }
        : null,
      ...Columns.slice(0, index + 1),
      ctr && ctr[0] ? ctr[0] : null,
      amnt && amnt[0] ? amnt[0] : null,
      {
        Header: 'Bid Disc%',
        accessor: 'bidDiscount',
        Cell: ({ cell, row }) => {
          return row.original.isFcCol
            ? '-'
            : cell.value < 0
            ? `-${formatDecimal(Math.abs(cell.value))}`
            : `+${formatDecimal(Math.abs(cell.value))}`;
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        Cell: ({ cell }) => formatDecimal(cell.value),
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmount',
        Cell: ({ cell }) => formatDecimal(cell.value),
      },
      bidConfig?.type == 3
        ? {
            Header: 'Max Bid Disc%',
            // accessor: 'bidDiscount',
            id: 'Max_disc',
            Cell: ({ cell, row }) => {
              return row.original.isFcCol
                ? '-'
                : cell.value < 0
                ? `${formatDecimal(row.original?.maxBidDisc)}`
                : `${formatDecimal(row.original?.maxBidDisc)}`;
            },
          }
        : null,
      bidConfig?.type == 3
        ? {
            Header: 'Max Bid Price/Ct',
            // accessor: 'bidPricePerCarat',
            id: 'Max_ctPr',
            Cell: ({ row }) => formatDecimal(row.original?.maxBidCtpr),
          }
        : null,
      bidConfig?.type == 3
        ? {
            Header: 'Max Bid Amount',
            // accessor: 'bidAmount',
            id: 'max_amt',
            Cell: ({ row }) => formatDecimal(row.original?.maxBidAmt),
          }
        : null,
      ...Columns.slice(index + 1, Columns.length),
    ]
      .filter((el) => el && el)
      .map((col) => ({ ...col, id: col.accessor }));
    return columns;
  };

  const setResponse = (res) => {
    setShowError(res?.count === 0);
    const columns = fetchcolumn();
    if (res) {
      const diamondData = res.data;
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData &&
            diamondData.map((d) => {
              if (defaultChecked.includes(d.id)) {
                d['isDefaultChecked'] = true;
              }
              return d;
            });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...res,
        columns,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ loading: false, columns, defaultChecked: [] });
    }
  };

  const fetch = React.useCallback(() => {
    setState({ loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current, date };
    const bidData = bidConfig;
    const subTypeCode = bidConfig?.sub_types?.subTypeCode;
    if (bidType === BID_TAB.LIVE) {
      fetchBid(
        payload,
        bidData.id ? bidData.id : '',
        subTypeCode ? subTypeCode : '',
        bidType === BID_TAB.LIVE ? [DIAMOND_BID.STATUS.PENDING] : [DIAMOND_BID.STATUS.WIN, DIAMOND_BID.STATUS.LOSS],
        setResponse,
        bidType === BID_TAB.HISTORY,
      );
    }
  }, [state.page, state.limit, sortRef.current, state.sum, date, bidType, bidConfig]);

  const handleSort = React.useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current],
  );

  React.useEffect(() => {
    async function setTimers() {
      bidConfig?.sub_types && fetch();
      const row = await fetchBidCart().then();
      setDataList(row.list);
    }

    setTimers();
  }, [state.page, state.limit, date, bidType]);

  const getTitle = () => {
    if (bidType === BID_TAB.MY_BID) {
      return hasBidConfig && dataList && dataList.length
        ? TITLE.BID + ' (' + dataList.length + ')'
        : TITLE.BID + ' (0)';
    } else {
      return hasBidConfig && !isEmpty(state.data) ? TITLE.BID + ' (' + state.count + ')' : TITLE.BID + ' (0)';
    }
  };

  const getSelection = () => {
    return bidType === BID_TAB.HISTORY ? null : (
      <SelectStone
        profilListing={getBidType() ? 'listingSelected diamondListStone my_bid' : ''}
        sum={state.sum}
        currentType={currentType}
      />
    );
  };

  const handleRemoveBidStones = React.useCallback(() => {
    const id = store.getState().diamondData?.selectedRowIds?.[currentType] ?? [];
    if (isEmpty(id)) return notify.error({ message: 'Please Select Stone(s).' });
    handleBidDelete(id);
  }, [currentType]);

  const getAction = () => {
    return bidType === BID_TAB.LIVE ? (
      <DiamondListingAction
        {...state}
        {...props}
        nocart
        nowatch
        nonote
        noreminder
        // myBidCartStone
        noconfirm={bidConfig?.sub_types?.subTypeCode !== 'BIWI'}
        noquote
        update={get(USER_PERMIT, 'MY_BID.update', false)}
        nonocompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        nohold
        nofinalcalc
        showStatus={true}
        noenquiry
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        currentType={currentType}
        nopaginate
        myBidRemove
        onRemoveBidStones={handleRemoveBidStones}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    ) : null;
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className="bidEndBlock d-flex align-items-center">
        {hasBidConfig ? (
          <span className="bidEndLabel">
            <IntlMessage id="app.BidEndIn" /> :
          </span>
        ) : (
          ''
        )}
        <BidCountdown />
      </div>
    ) : null;
  };

  const getRadio = () => {
    return (
      <Radio.Group value={bidType}>
        <Radio value={BID_TAB.LIVE}>
          <IntlMessage id="app.myBid" />
        </Radio>
      </Radio.Group>
    );
  };
  return (
    <div className="searchResultListWrapper">
      {dimaondDetailPage === 'diamond-detail' || dimaondDetailPage === 'dna' ? (
        ''
      ) : (
        <div className="selectStonHeader">
          <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
            <Heading count={dataList.length}>
              <IntlMessage id={PAGE_TITLE[PAGES.BID]} />
            </Heading>
            {hasBidConfig ? (
              <div className="d-flex">
                <div className="d-flex align-items-center j-content-center mr-10 flex-wrap">
                  <div style={{ display: 'block' }}>{getRadio()}</div>
                  <Link
                    className="commonOutline smallButton"
                    to={
                      bidConfig?.sub_types?.subTypeCode === 'BIWI'
                        ? '/' + LISTINGPAGES.SEARCH_DIAMOND
                        : '/' + LISTINGPAGES.BID
                    }
                  >
                    <IntlMessage id="app.GoToBid" />
                  </Link>
                </div>
                {getBidBlock()}
              </div>
            ) : (
              ' '
            )}
            {hasBidConfig ? getSelection() : ''}
          </div>
        </div>
      )}
      {bidType === BID_TAB.MY_BID ? (
        <BidCartList {...props} />
      ) : (
        <div className="searchInnerResult">
          <div
            className="searchResultTable"
            style={{ height: check?.length ? `calc(100vh - 219px)` : `calc(100vh - 170px)`, overflow: 'auto' }}
          >
            <Table
              noCheckBox={bidType === BID_TAB.HISTORY}
              data={state.data}
              columns={state.columns}
              loading={state.loading}
              ref={tableRef}
              handleSort={handleSort}
              currentType={currentType}
              defaultChecked={state.defaultChecked}
            />
            {showError ? (
              <NoDataShow
                message="No Bid submission Found."
                subTypeCode={bidConfig?.sub_types?.subTypeCode === 'BIWI'}
                noData
              />
            ) : (
              ''
            )}
            {!hasBidConfig && <NoDataShow message={'No Bid is active now. Please purchase diamond!!'} redirectURL />}
            {hasBidConfig && !isEmpty(state.data) ? getAction() : ''}
          </div>
        </div>
      )}
    </div>
  );
};

export default BidList;
