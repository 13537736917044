import React from 'react';

import { TableConfig } from 'components/DiamondList/table-config';

import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import UtilService from '../../services/util';
import Heading from '../common/Heading';

const DNADetail = (props) => {
  const { dnaData } = props;
  const details = [
    { name: 'Stone ID', key: 'vStnId' },
    { name: 'Shape', key: 'shpNm' },
    { name: 'Lab', key: 'lbNm' },
    { name: 'Carat', key: 'crt' },
    { name: 'Color', key: dnaData?.isFcCol ? 'fcColNm' : 'colNm' },
    { name: 'Clarity', key: 'clrNm' },
  ];

  const priceDetails = [
    { name: 'Rapaport', key: 'rap' },
    { name: 'Disc(%)', key: 'back' },
    { name: 'Per / Ct', key: 'ctPr' },
    { name: 'Amount', key: 'amt' },
  ];
  // const grading = [
  //   { name: "Shape", key: "shpNm" },
  //   { name: "Carat", key: "crt" },
  //   { name: "Clarity", key: "clrNm" },
  //   { name: "Color", key: "colNm" },
  //   { name: "Cut", key: "cutNm" },
  //   { name: "Polish", key: "polNm" },
  //   { name: "Symmetry", key: "symNm" },
  //   { name: "Ratio", key: "ratio" },
  //   { name: "Fluorescence", key: "fluNm" },
  //   { name: "Lab", key: "lbNm" },
  // ];

  // const bgmDetails = [
  //   { name: "Brown Tinge", key: "" },
  //   { name: "Mix Tinge", key: "" },
  //   { name: "Other Tinge", key: "" },
  //   { name: "Milky", key: "mlkNm" },
  // ];

  const makeDetails = [
    { name: 'Cut', key: 'cutNm' },
    { name: 'Polish', key: 'polNm' },
    { name: 'Symmetry', key: 'symNm' },
    { name: 'Fluorescence', key: 'fluNm' },
  ];

  // const otherDetails = [
  //   { name: "Laser Ins. ", key: "lsrInc" },
  //   { name: "Extra Facets", key: "eFact" },
  //   { name: "Heart & Arrow", key: "hANm" },
  //   { name: "Eye Clean", key: "eClnNm" },
  // ];

  const measurements = [
    { name: 'Measurements', key: 'msrmnt' },
    { name: 'Table(%)', key: 'tblPer' },
    { name: 'Depth(%)', key: 'depPer' },
    { name: 'Ratio', key: 'ratio' },
    { name: 'Cr. Angl', key: 'cAng' },
    { name: 'Cr. Height', key: 'cHgt' },
    { name: 'Pv. Angle', key: 'pAng' },
    { name: 'Pv. Depth', key: 'pHgt' },
    { name: 'Girdle(%)', key: 'grdlPer' },
    { name: 'Star Length', key: 'strL' },
    { name: 'Lower Half', key: 'lwrHal' },
    // { name: "Width", key: "width" },
    // { name: "Depth", key: "height" },
    // { name: "Culet", key: "cultNm" },
    // { name: "ETK (0 %)", key: "" },
  ];

  const inclusionDetail = [
    { name: 'Table White', key: '' },
    { name: 'Side Inc', key: 'wSideNm' },
    { name: 'Eye Clean', key: 'eClnNm' },
    { name: 'Table Black', key: 'blkIncNm' },
    { name: 'Side Black', key: 'blkSdNm' },
    { name: 'Luster', key: 'lstrNm' },
    { name: 'Table Open', key: 'opTblNm' },
    { name: 'Side Open', key: 'opIncNm' },
    { name: 'H&A', key: 'hANm' },
  ];

  const inclusions = [
    { name: 'Key To Symbols', key: 'kToSStr' },
    { name: 'Report Comm.', key: 'lbCmt' },
    // { name: "Lab Comments", key: "lbCmt" },
    // { name: "Table Black", key: "blkIncNm" },
    // { name: "Table Open", key: "opTblNm" },
    // { name: "Side Black", key: "blkSdNm" },
    // { name: "Crown Open", key: "opCrwnNm" },
    // { name: "Table Inclusion", key: "wInclNm" },
    // { name: "Side Inclusion", key: "wSideNm" },
    // { name: "Pavillion Open", key: "opPavNm" },
    // { name: "Girdle Open", key: "opGrd" },
  ];

  // const details = [
  //   { name: "Packet No", key: "vStnId" },
  //   { name: "Shape", key: "shpNm" },
  //   { name: "Carat", key: "crt" },
  //   { name: "Report No", key: "rptNo" },
  //   { name: "Clarity", key: "clrNm" },
  //   { name: "Cut", key: "cutNm" },
  //   { name: "Color", key: "colNm" },
  //   { name: "Polish", key: "polNm" },
  //   { name: "Rap.($)", key: "rap" },
  //   { name: "Symmetry", key: "symNm" },
  //   { name: "Fluorescence", key: "fluNm" },
  //   { name: "Lab", key: "lbNm" },
  //   { name: "Shade", key: "shdNm" },
  //   { name: "Key To Symbol", key: "kToSStr" },
  //   { name: "Report Comments", key: "lbCmt" },
  // ];

  // const measurements = [
  //   { name: "Table %", key: "tblPer" },
  //   { name: "Depth %", key: "depPer" },
  //   { name: "Length", key: "length" },
  //   { name: "Width", key: "width" },
  //   { name: "Depth", key: "depPer" },
  //   { name: "Ratio", key: "ratio" },
  //   { name: "Crown Angl", key: "cAng" },
  //   { name: " Crown Height", key: "cHgt" },
  //   { name: "Pav Angle", key: "pAng" },
  //   { name: "Pav Height", key: "pHgt" },
  //   { name: "Girdle", key: "girdleStr" },
  //   { name: "Culet", key: "cultNm" },
  //   { name: "Laser Ins. ", key: "lsrInc" },
  // ];

  // const inclusion = [
  //   { name: "Center Natts", key: "blkTblNm" },
  //   { name: "Side Natts", key: "blkSdNm" },
  //   { name: "Center White", key: "wTblNm" },
  //   { name: "Side White", key: "wSdNm" },
  //   { name: "Table Open", key: "opTblNm" },
  //   { name: "Crown Open", key: "opCrwnNm" },
  //   { name: "Pavillion Open", key: "opPavNm" },
  //   { name: "Eye Clean", key: "eClnNm" },
  //   { name: "Heart & Arrow", key: "hANm" },
  //   { name: "Brilliancy", key: "brlncyNm" },
  //   { name: "Type2 Cert", key: "type2Nm" },
  //   { name: "Country of Origin", key: "org" },
  // ];

  // const restrictedFields = [
  //   { name: "Packet No", key: "vStnId" },
  //   { name: "Price / Carat", key: "ctPr" },
  //   { name: "Shape", key: "shpNm" },
  //   { name: "Amount", key: "amt" },
  //   { name: "Carat", key: "crt" },
  //   { name: "Report No", key: "rptNo" },
  //   { name: "Clarity", key: "clrNm" },
  //   { name: "Cut", key: "cutNm" },
  //   { name: "Color", key: "colNm" },
  //   { name: "Polish", key: "polNm" },
  //   { name: "Rap.($)", key: "rap" },
  //   { name: "Symmetry", key: "symNm" },
  //   { name: "Discount", key: "back" },
  //   { name: "Fluorescence", key: "fluNm" },
  //   { name: "Lab", key: "lbNm" },
  //   { name: "Shade", key: "shdNm" },
  //   { name: "Key To Symbol", key: "kToSStr" },
  //   { name: "Report Comments", key: "lbCmt" },
  // ];

  const token = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);
  token && details.push({ name: 'Certificate No.', key: 'rptNo' });
  return (
    <>
      <div className="dnaDetailBlock d-flex flex-wrap">
        <Heading title="Basic Details" />
        <div className="d-flex mobile-w-33">
          {details.map((m) => (
            <div className="detail">
              <span>{m.name}: </span>
              <span>
                {!dnaData[m.key]
                  ? '-'
                  : TableConfig.FLOAT_COLUMNS.includes(m.key)
                  ? parseFloat(dnaData[m['key']]).toFixed(2)
                  : TableConfig.ROUND_COLUMNS.includes(m.key)
                  ? Math.round(dnaData[m['key']])
                  : dnaData[m['key']]}
              </span>
            </div>
          ))}
        </div>

        {/* {token
          ? restrictedFields.map((dt) => (
              <div className="detail">
                <span>{dt.name}: </span>
                <span>
                  {!dnaData[dt.key]
                    ? "-"
                    : TableBackConfig.floatkeys.includes(dt.key)
                    ? parseFloat(dnaData[dt["key"]]).toFixed(2)
                    : TableBackConfig.roundkeys.includes(dt.key)
                    ? Math.round(dnaData[dt["key"]])
                    : dnaData[dt["key"]]}
                </span>
              </div>
            ))
          : details.map((dt) => (
              <div className="detail">
                <span>{dt.name}: </span>
                <span>
                  {!dnaData[dt.key]
                    ? "-"
                    : TableBackConfig.floatkeys.includes(dt.key)
                    ? parseFloat(dnaData[dt["key"]]).toFixed(2)
                    : TableBackConfig.roundkeys.includes(dt.key)
                    ? Math.round(dnaData[dt["key"]])
                    : dnaData[dt["key"]]}
                </span>
              </div>
            ))} */}
      </div>

      {/* <div className="dnaDetailBlock d-flex flex-wrap">
        <Heading title="Diamond Grading" />
        {grading.map((m) => (
          <div className="detail">
            <span>{m.name}: </span>
            <span>
              {!dnaData[m.key]
                ? "-"
                : TableBackConfig.floatkeys.includes(m.key)
                ? parseFloat(dnaData[m["key"]]).toFixed(2)
                : TableBackConfig.roundkeys.includes(m.key)
                ? Math.round(dnaData[m["key"]])
                : dnaData[m["key"]]}
            </span>
          </div>
        ))}
      </div> */}

      {/* <div className="dnaDetailBlock d-flex flex-wrap">
        <Heading title="B G M Details" />
        {bgmDetails.map((i) => (
          <div className="detail">
            <span>{i.name}: </span>
            <span>
              {!dnaData[i.key]
                ? "-"
                : TableBackConfig.floatkeys.includes(i.key)
                ? parseFloat(dnaData[i["key"]]).toFixed(2)
                : TableBackConfig.roundkeys.includes(i.key)
                ? Math.round(dnaData[i["key"]])
                : dnaData[i["key"]]}
            </span>
          </div>
        ))}
      </div> */}

      {token ? (
        <div className="dnaDetailBlock d-flex flex-wrap">
          <Heading title="Price Details" />
          {priceDetails.map((i) => (
            <div className="detail">
              <span>{i.name}: </span>
              <span>
                {!dnaData[i.key]
                  ? '-'
                  : TableConfig.FLOAT_COLUMNS.includes(i.key)
                  ? parseFloat(dnaData[i['key']]).toFixed(2)
                  : TableConfig.ROUND_COLUMNS.includes(i.key)
                  ? Math.round(dnaData[i['key']])
                  : dnaData[i['key']]}
              </span>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}

      <div className="dnaDetailBlock d-flex flex-wrap">
        <Heading title="Make Details" />
        {makeDetails.map((i) => (
          <div className="detail">
            <span>{i.name}: </span>
            <span>
              {!dnaData[i.key]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(i.key)
                ? parseFloat(dnaData[i['key']]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(i.key)
                ? Math.round(dnaData[i['key']])
                : dnaData[i['key']]}
            </span>
          </div>
        ))}
      </div>

      <div className="dnaDetailBlock d-flex flex-wrap">
        <Heading title="Parameter Details" />
        {measurements.map((i) => (
          <div className="detail">
            <span>{i.name}: </span>
            <span>
              {!dnaData[i.key]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(i.key)
                ? parseFloat(dnaData[i['key']]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(i.key)
                ? Math.round(dnaData[i['key']])
                : dnaData[i['key']]}
            </span>
          </div>
        ))}
      </div>

      <div className="dnaDetailBlock d-flex flex-wrap three-col">
        <Heading title="Inclusion Details" />
        <div className="d-flex mobile-w-33">
          {inclusionDetail.map((i) => (
            <div className="detail">
              <span>{i.name}: </span>
              <span>
                {!dnaData[i.key]
                  ? '-'
                  : TableConfig.FLOAT_COLUMNS.includes(i.key)
                  ? parseFloat(dnaData[i['key']]).toFixed(2)
                  : TableConfig.ROUND_COLUMNS.includes(i.key)
                  ? Math.round(dnaData[i['key']])
                  : dnaData[i['key']]}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="dnaDetailBlock d-flex flex-wrap full-width">
        <Heading title="Other Details" />
        {inclusions.map((i) => (
          <div className="detail">
            <span>{i.name}: </span>
            <span>
              {!dnaData[i.key]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(i.key)
                ? parseFloat(dnaData[i['key']]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(i.key)
                ? Math.round(dnaData[i['key']])
                : dnaData[i['key']]}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default DNADetail;
