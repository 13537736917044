import { Storage } from 'services/Storage';

import { callApi as _callApi_ } from 'util/call-api';
import { getPathName, getUserName, isEmpty, objectToQueryString, pruneEmpty } from 'util/utils';

import { PAGES } from 'constants/Common';
import { CREATE_ANALYTICS, GET_SERVER_TIME, BID_CONFIG } from 'constants/apiConstant';

export { getUserName as getFullName } from 'util/utils';

export const callApi = _callApi_;

export const track = (data) => {
  if (isEmpty(Storage.get('token'))) return;
  const [page, section, action] = data.split('/');
  return callApi({ ...CREATE_ANALYTICS, request: { page, section, action } });
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    const objData = { ...GET_SERVER_TIME, url: `${GET_SERVER_TIME.url}?${Math.random()}` };
    callApi(objData, (err, res) => {
      if (err) return reject(err);
      if (res?.code === 'OK') return resolve(res.data);
      reject(err);
    });
  });
};

export const getBidConfig = (type = null) => {
  type = type ? type : [PAGES.DEAL_OF_THE_DAY, PAGES.MY_DEAL_OF_THE_DAY].includes(getPathName()) ? 2 : 1;
  return new Promise((resolve, reject) => {
    const request = {
      ...BID_CONFIG,
      url: `${BID_CONFIG.url}${objectToQueryString(pruneEmpty({ type }))}&t=${Math.random()} `,
    };

    callApi(request, (err, res) => {
      if (res && res.code === 'OK') return resolve(res.data);
      reject(err);
    });
  });
};

export default {
  callApi: _callApi_,
  getFullName: getUserName,
  getLocalStorageItem: (key) => Storage.get(key),
  setLocalStorageItem: (value, key) => Storage.set(key, value),
};
