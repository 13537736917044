import { LoaderService } from 'services/LoaderService';
import { Storage } from 'services/Storage';

import { axios } from 'util/Api';
import { history } from 'util/history';
import { logInfo } from 'util/utils';

import { USER_LOGOUT } from 'constants/apiConstant';

import { store } from 'appRedux/store';

export class AuthService {
  static async signOut() {
    AuthService.signOutRequest();
    Storage.deleteAll();
    history.push('/signin');
    store.dispatch({ type: '@@RESET' });
  }

  static async signOutRequest(token) {
    LoaderService.startLoading();
    try {
      token = token ?? Storage.get('token');
      await axios.request({ ...USER_LOGOUT, headers: { Authorization: `JWT ${token}` } });
    } catch (error) {
      logInfo({ error });
    }
    LoaderService.stopLoading();
  }
}

window.__DN = { ...window.__DN, AuthService };
