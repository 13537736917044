import { Action } from 'services/Action';
import { Storage } from 'services/Storage';

import { isArray, isEmpty, isObject } from 'util/utils';

export class MasterService {
  static data = {};
  static tree = {};

  static updateTreeEvent = new Action('@master/update-tree');
  static updateListEvent = new Action('@master/update-list');

  static setData() {
    this.data = Storage.get('master', false) ?? {};
    this.updateListEvent.emit(this.data);
  }

  static getData() {
    if (isEmpty(this.data)) this.setData();
    return this.data;
  }

  static getTree() {
    if (isEmpty(this.tree)) this.setTree();
    return this.tree;
  }

  static setTree() {
    this.tree = Object.fromEntries(
      Object.entries(this.getData()).map(([key, list]) => {
        const map = { byId: {}, byCode: {} };

        list.forEach((item) => {
          const idList = isArray(item.id) ? item.id : [item.id];
          const codeList = isArray(item.code) ? item.code : [item.code];
          idList.forEach((id) => void (map.byId[id] = { ...item }));
          codeList.forEach((code) => void (map.byCode[code] = { ...item }));
        });

        return [key, map];
      }),
    );
    this.updateTreeEvent.emit(this.tree);
  }

  static getList(key) {
    return MasterService.getData()?.[key];
  }

  static getMapById(key) {
    return MasterService.getTree()?.[key]?.byId;
  }

  static getMapByCode(key) {
    return MasterService.getTree()?.[key]?.byCode;
  }
}

const addListeners = () => {
  window.__DN = { ...window.__DN };
  MasterService.setTree();
  void window.__DN.MasterListeners?.forEach?.((unlisten) => unlisten?.());
  window.__DN.MasterService = MasterService;
  window.__DN.MasterListeners = [Storage?.listen?.('master', () => MasterService.setTree())];
};
addListeners();

void module?.hot?.accept?.(['./MasterService.js'], () => addListeners());
