import React, { Component } from 'react';

import { getGridHeight } from 'components/DiamondList/diamondListUtils';

import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import DiamondListingAction from '../../common/DiamondListing/DiamondListingAction';

class MobileDiamondDetail extends Component {
  state = { visible: false };

  render() {
    const data = this.props.data;
    const parent = this.props.parent;
    const gridHeight = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailBottomBox'], 15);
    return (
      <div className="mobilediamondDetail">
        <div className="mobilediamond diamondDetailLeft">{parent.leftPart(data)}</div>
        <div className="diamondDetailRight mobileRightDetail">{this.props.params}</div>
        {(window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
          window.location.pathname.split('/')[1] === LISTINGPAGES.DNA) && (
          <DiamondListingAction
            noquote
            nofinalcalc
            nooffice
            nohold
            noconfirm
            nowatch
            noenquiry
            nocart
            nonote
            nonocompare
            noWatch
            // noshare
            noreminder
            // nodownload
            noprint
            nopaginate
            quotes
            checked={data ? [data] : []}
            // viewcerti
            // downloadzip
            // sharexray
            downloadZip={() => parent.downloadZip(data)}
            // verifyCerti={() => parent.verifyCerti(data)}
            // shareXray={parent.sendMail}
          />
        )}
        {parent.getEmailModal(data)}
      </div>
    );
  }
}
export default MobileDiamondDetail;
