import React, { useEffect } from 'react';

import moment from 'moment';

import BidCartPrMobile from 'components/common/DiamondListing/BidCartPrMobile';
import BidCartValueMobile from 'components/common/DiamondListing/BidCartValueMobile';
import QuoteCtPrCalcMobile from 'components/common/DiamondListing/QuoteCtPrCalcMobile';
import QuoteDiscCalcMobile from 'components/common/DiamondListing/QuoteDisCalcMobile';
import QuoteValueCalcMobile from 'components/common/DiamondListing/QuoteValueCalcMobile';
import BidCartDis from 'components/common/DiamondListing/bidCartDiscountMobile';

import { isNumber, isNumeric } from 'util/utils';

import { LISTINGPAGES } from './DiamondListFunctions';

import minus from '../../assets/img/minus.jpg';
import plus from '../../assets/img/plus.jpg';
import { DIAMOND_BID } from '../../constants/Common';
import { OFFERSTATUS } from '../Offer';
import Countdown from '../Offer/Timer';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import { STATUS_COLOR } from '../common/DiamondListing/Status';

const MobileViewListCard = (props) => {
  useEffect(() => {
    // 👏👏👏👏👏
    if (props.areAllChecked) {
      props.value();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !props.quotePage ? (
    <div
      className={`diamondListMobile as_app ${props.activeClass ? props.activeClass : null} `}
      onClick={props.bidPopup ? null : props.onClick}
    >
      <div
        className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}
`}
      >
        {!props.quotePage && (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">{formatNumber(parseFloat(props.data?.crt).toFixed(2))}</span>
                <small className="cName">Carat</small>
              </p>
              <p className={`sStatus ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}`}>
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                <p className="sId">
                  <a href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}>{props.data?.vStnId}</a>
                </p>
                <p className="sShape">{props.data.shpNm}</p>
                <p className="priceCarat">
                  {props.data.ctPr ? <span>{formatNumber(parseFloat(props.data.ctPr).toFixed(2))} $/Cts</span> : ''}
                </p>
              </div>
              <div className="stoneInfo">
                <p>
                  <span className="sColor">
                    {props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(' ')
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(',', '')
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">{props.data?.clrNm}</span>
                  <span className="sc">{props.data?.cutNm ?? '-'}</span>
                  <span className="sc">{props.data?.polNm ?? '-'}</span>
                  <span className="sc">{props.data?.symNm ?? '-'}</span>
                  <span className="in">{props.data?.fluNm ?? '-'}</span>
                  <span className="lab">{props.data?.lbNm ?? '-'}</span>
                </p>
                <p className="sAmt">
                  {props.data.amt ? <span>{formatNumber(parseFloat(props.data.amt).toFixed(2))} $/Amt</span> : ''}
                </p>
              </div>
              <div className="smeasure">
                <p>
                  <span className="title">M : </span>
                  <span className="val">{props.data.msrmnt ?? '-'}</span>
                </p>
                <p>
                  <span className="title">R : </span>
                  <span className="val">{props.data.ratio ?? '-'}</span>
                </p>
                <p>
                  <span className="title">TD% : </span>
                  <span className="val">{props.data.depPer ?? '-'}</span>
                </p>
                <p>
                  <span className="title">TB% : </span>
                  <span className="val">{props.data.tblPer ?? '-'}</span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {props.myBidList && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Bid Dis(%): </span>
            <span>{parseFloat(props.data.bidDiscount).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Pr/Ct: </span>
            <span>{parseFloat(props.data.bidPricePerCarat).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Amount: </span>
            <span>{parseFloat(props.data.bidAmount).toFixed(2)}</span>
          </div>
        </div>
      )}
      {props.quote && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Final Quote: </span>
            <QuoteDiscCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Final Rate: </span>
            <QuoteCtPrCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Final Value: </span>
            <QuoteValueCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
        </div>
      )}
      {props.bid && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Type: </span>
            <span>{props.data.bidType === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Status: </span>
            <span>{props.data.status === DIAMOND_BID.STATUS.WIN ? 'Win' : 'Loss'}</span>
          </div>
        </div>
      )}
      {props.finalCalc && (
        <div className="d-flex j-space-between quoteExtraDetail">
          {/* <div className="quoteExtraItem">
            {props.data.calcDiscount ? <span>Final Disc%: </span> : ''}
            {!props.data.isFcCol ? (
              props.data.calcDiscount ? (
                <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div> */}
          <div className="quoteExtraItem">
            {props.data.calcPricePerCarat ? <span>Final Rate: </span> : ''}
            {props.data.calcPricePerCarat ? <span>${parseFloat(props.data.calcPricePerCarat).toFixed(2)}</span> : ''}
          </div>
          <div className="quoteExtraItem">
            {props.data.calcAmount ? <span>Final Value: </span> : ''}
            {props.data.calcAmount ? <span>${parseFloat(props.data.calcAmount).toFixed(2)}</span> : ''}
          </div>
        </div>
      )}
      {props.bidCartPopup && (
        <div className="d-flex j-space-between quoteExtraDetail" onClick={props.onClick}>
          <div className="quoteExtraItem">
            <span>Bid Disc(%): </span>
            <BidCartDis {...props.bidCartPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Pr/Ct: </span>
            <BidCartPrMobile {...props.bidCartPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Value: </span>
            <BidCartValueMobile {...props.bidCartPopup} original={props.data} />
          </div>
        </div>
      )}
      {props.bidPopup && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail">
            {/* <div className="quoteExtraItem">{props.bidPopup.getBidPrCell(props.data)}</div> */}
            <div className="quoteExtraItem">
              <span>Bid Disc(%): </span>
              <img
                onClick={() => {
                  const _value =
                    Number(props.data.bidDiscount) -
                    Number(props.bidPopup?.props?.bidConfig.sub_types?.diffInterval ?? 1);
                  return props.bidPopup?.handleDiscountChange(
                    isNumeric(_value, true) ? _value : 0,
                    props.data,
                    () => {},
                  );
                }}
                src={minus}
                width="15px"
                alt="minus"
              />
              {/* <span>{parseFloat(props.data.bidDiscount || 0).toFixed(2)}</span> */}
              <span>
                <input
                  disabled
                  type="number"
                  style={{
                    width:
                      props.data.bidDiscountNull !== null &&
                      (props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'all' ||
                        props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'back')
                        ? '30%'
                        : '40%',
                    paddingLeft:
                      Number(isNumber(props.data.bidDiscount) ? Number(props.data.bidDiscount) : '0.00') > 0 ? 8 : 2,
                  }}
                  value={
                    isNumber(props.data.bidDiscount)
                      ? Number(props.data.bidDiscount)
                      : isNumber(Number(Number(props.data.bidDiscount)))
                      ? Number(Number(props.data.bidDiscount))
                      : '0.00'
                  }
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />
              </span>
              <img
                onClick={() => {
                  const _value = Number(props.data.bidDiscount);
                  Number(props.bidPopup?.state?.bidConfig.sub_types?.diffInterval ?? 1);
                  return props.bidPopup?.handleDiscountChange(
                    isNumeric(_value, true) ? _value : 0,
                    props.data,
                    () => {},
                  );
                }}
                src={plus}
                width="15px"
                alt="plus"
              />
            </div>
            <div className="quoteExtraItem">
              <span>Bid Pr/Ct: </span>
              <img
                onClick={() => {
                  const countOnDis =
                    props.data.bidDiscountNull !== null &&
                    (props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'all' ||
                      props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'back');
                  if (countOnDis) {
                    const _value =
                      Number(props.data.bidDiscount) - props.bidPopup?.state?.bidConfig.sub_types?.diffInterval ??
                      (isNumber(Number(props.data.bidDiscount))
                        ? Number(props.data.bidDiscount)
                        : isNumber(Number(Number(props.data.bidDiscount)))
                        ? Number(Number(Number(props.data.bidDiscount)))
                        : '0.00');
                    return props.bidPopup?.handleDiscountChange(
                      isNumeric(_value, true) ? _value : 0,
                      props.data,
                      () => {},
                    );
                  } else {
                    const value = isNumber(props.data.bidPricePerCarat)
                      ? Number(props.data.bidPricePerCarat)
                      : isNumber(Number(Number(props.data.bidPricePerCarat)))
                      ? Number(Number(Number(props.data.bidPricePerCarat)))
                      : '0.00';
                    const _value = Number(value) - props.bidPopup?.state?.bidConfig?.sub_types?.diffInterval ?? value;
                    return props.bidPopup?.handlePriceCtChange(isNumeric(_value, true) ? _value : 0, props.data);
                  }
                }}
                src={minus}
                width="15px"
                alt="minus"
              />
              <span>
                <input
                  disabled
                  style={{
                    width: (
                      isNumber(props.data.bidPricePerCarat)
                        ? Number(props.data.bidPricePerCarat)
                        : isNumber(Number(Number(props.data.bidPricePerCarat)))
                        ? Number(Number(Number(props.data.bidPricePerCarat)))
                        : '0.00' &&
                          (props.bidPopup?.state.bidConfig.sub_types?.calColumn === 'all' ||
                            props.bidPopup?.state.bidConfig?.sub_types?.calColumn === 'ctPr')
                    )
                      ? '35%'
                      : '40%',
                  }}
                  value={
                    isNumber(props.data.bidPricePerCarat)
                      ? Number(props.data.bidPricePerCarat)
                      : isNumber(Number(Number(props.data.bidPricePerCarat)))
                      ? Number(Number(Number(props.data.bidPricePerCarat)))
                      : '0.00'
                  }
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />
              </span>
              {/* <span>{parseFloat(props.data.bidPricePerCarat || 0).toFixed(2)}</span> */}
              <img
                onClick={() => {
                  const countOnDis =
                    props.data.bidDiscountNull !== null &&
                    (props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'all' ||
                      props.bidPopup?.state?.bidConfig.sub_types?.calColumn === 'back');
                  if (countOnDis) {
                    const _value =
                      Number(props.data.bidDiscount) + props.bidPopup?.state?.bidConfig.sub_types?.diffInterval ??
                      (isNumber(Number(props.data.bidDiscount))
                        ? Number(props.data.bidDiscount)
                        : isNumber(Number(Number(props.data.bidDiscount)))
                        ? Number(Number(Number(props.data.bidDiscount)))
                        : '0.00');
                    return props.bidPopup.handleDiscountChange(
                      isNumeric(_value, true) ? _value : 0,
                      props.data,
                      () => {},
                    );
                  } else {
                    const _value =
                      Number(props.data.bidPricePerCarat) + props.bidPopup?.state?.bidConfig?.sub_types?.diffInterval ??
                      (isNumber(props.data.bidPricePerCarat)
                        ? Number(props.data.bidPricePerCarat)
                        : isNumber(Number(Number(props.data.bidPricePerCarat)))
                        ? Number(Number(Number(props.data.bidPricePerCarat)))
                        : '0.00');
                    isNumeric(_value, true)
                      ? props.bidPopup.handlePriceCtChange(_value, props.data)
                      : props.bidPopup.handlePriceCtChange(0, props.data);
                  }
                }}
                src={plus}
                width="15px"
                alt="plus"
              />
            </div>
          </div>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Bid Value: </span>
              <span>${parseFloat(props.data.bidAmount || 0).toFixed(2)}</span>
            </div>
          </div>
        </>
      )}
      {/* {props.watchpopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Exp. Disc(%): </span>
            <span>{props.watchpopup(props.data)}</span>
          </div>
        </div>
      )} */}
      {/* {props.data?.remarks && (
        <div className="d-flex j-space-between quoteExtraDetail" >
          <div className="quoteExtraItem">
            <span>Notes: </span>
            <span>{props.data.remarks || '-'}</span>
          </div>
        </div>
      )} */}
    </div>
  ) : (
    <div
      className={`diamondListMobile as_app ${props.activeClass ? props.activeClass : null} `}
      onClick={props.onClick}
    >
      <div
        className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}
    ${'quotePageWrapper'}`}
      >
        <div className="diamodListItemContent">
          <div className="mobileListItem activeStatus pending">
            <span>{OFFERSTATUS[props.data.offerStatus]}</span>
            {moment(props.data.offerValidDate) - moment() < 0 ? (
              <span>Offer Expired</span>
            ) : (
              <Countdown
                date={props.data.offerStatus === 1 ? moment(props.data.offerValidDate) : moment()}
                currTime={moment()}
              />
            )}
          </div>
          <div className="priceCaratContainer">
            <p className="sId">
              <a href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}>{props.data?.vStnId}</a>
            </p>
            <p className="sShape">{props.data.shpNm}</p>
            <p className="sDis">
              {' '}
              {(props.data.back ? parseFloat(props.data.back).toFixed(2) + ' / ' : '') +
                parseFloat(props.data.ctPr).toFixed(0)}
            </p>
            <p className="priceCarat">
              {' '}
              {(props.data.newDiscount ? parseFloat(props.data.newDiscount).toFixed(2) + ' / ' : '') +
                parseFloat(props.data.newPricePerCarat).toFixed(0)}
            </p>
          </div>
          <div className="stoneInfo">
            <p>
              <span className="sColor">
                {props.data.isFcCol
                  ? props.data?.fcColNm
                      ?.split(' ')
                      ?.map((x) => x.charAt(0))
                      ?.join()
                      .replaceAll(',', '')
                  : props.data.colNm}
              </span>
              <span className="sFlu">{props.data?.clrNm}</span>
              <span className="sc">{props.data?.cutNm ?? '-'}</span>
              <span className="sc">{props.data?.polNm ?? '-'}</span>
              <span className="sc">{props.data?.symNm ?? '-'}</span>
              <span className="in">{props.data?.fluNm ?? '-'}</span>
              <span className="lab">{props.data?.lbNm ?? '-'}</span>
            </p>
            <p className="sAmt">
              {props.data.newDiscount ? (
                <span>{formatNumber(parseFloat(props.data.newDiscount).toFixed(2))} Bargain(%)</span>
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="smeasure">
            <p>
              <span className="title">M : </span>
              <span className="val">{props.data.msrmnt ?? '-'}</span>
            </p>
            <p>
              <span className="title">R : </span>
              <span className="val">{props.data.ratio ?? '-'}</span>
            </p>
            <p>
              <span className="title">TD% : </span>
              <span className="val">{props.data.depPer ?? '-'}</span>
            </p>
            <p>
              <span className="title">TB% : </span>
              <span className="val">{props.data.tblPer ?? '-'}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileViewListCard;
