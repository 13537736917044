import React from 'react';

import { useIsMobile } from 'util/hooks';
import { isEmpty } from 'util/lodash';
import { formatFloat } from 'util/utils';

import { calculate } from './SelectStone';

const HeadCalc = React.memo((props) => {
  const { checked, values } = props;

  const [isMobile] = useIsMobile();

  const fields = React.useMemo(() => {
    if (!isEmpty(values)) return { ...values };

    if (!isEmpty(checked)) {
      const sum = calculate(checked, null);

      return {
        'Pieces : ': sum.total_pieces,
        'Crt : ': formatFloat(sum.total_carat),
        // 'Rap Price : ': formatFloat(sum.final_rapaport),
        // 'Disc% : ': formatFloat(sum.final_discount),
        'Pr/Ct : ': formatFloat(sum.final_price),
        'Amt. : ': formatFloat(sum.bid_final_amt),
      };
    }

    return {};
  }, [values, checked]);

  if (Object.keys(fields)?.length < 4) return null;
  if (isMobile && isEmpty(checked) && isEmpty(values)) return null;

  return (
    <div className="DiamondDetailPopup">
      {Object.entries(fields).map(([key, value]) => {
        return (
          <div key={key} className="DiamondDetailPopupItem">
            <span>{key}</span>
            <span>
              {isMobile && ['Bid Term Discount(%)', 'Avg. Discount(%)', 'TERMS'].includes(key) && value > 0
                ? `+${value}`
                : value}
            </span>
          </div>
        );
      })}
    </div>
  );
});

export default HeadCalc;

export function HeadingCalc(checked, values) {
  return <HeadCalc checked={checked} values={values} />;
}
