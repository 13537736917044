import React from 'react';

import { isNumber } from 'lodash';

import { classNames } from 'util/utils';
import './title.less';

export const Heading = (props) => {
  const { className, title, children, count, ...rest } = props;
  return (
    <h2 className={classNames([`mainHeadingTitle`, className])} {...rest}>
      {children ?? title}
      {isNumber(count) ? ` (${count})` : ''}
    </h2>
  );
};

export default Heading;
