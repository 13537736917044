import { TABLE_PAGE_LIMIT } from 'constants/Common';

import belgiumPng from 'assets/img/Flags/belgium.png';
import dubaiPng from 'assets/img/Flags/dubai.png';
import hongkongPng from 'assets/img/Flags/hongkong.png';
import indiaSvg from 'assets/img/Flags/india.svg';
import usaJpg from 'assets/img/Flags/usa.jpg';

export class TableConfig {
  static PAGE_LIMIT = TABLE_PAGE_LIMIT;
  static FLAG_ICONS = { INDIA: indiaSvg, HONGKONG: hongkongPng, NEWYORK: usaJpg, DUBAI: dubaiPng, BELGIUM: belgiumPng, MUMBAIOFFICE: indiaSvg, }; // prettier-ignore
  static SORTABLE_COLUMNS = { stoneId: 'Stone No', shpNm: 'Shape', crt: 'Carat',  rap: 'Rap.($)',  ctPr: 'Amount',amt: 'Price / Carat', colNm: 'Color', clrNm: 'Clarity', }; // prettier-ignore

  static ALT_COLUMNS = ['lbNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'back', 'rap', 'fluNm', ]; // prettier-ignore
  static FILTER_COLUMNS = ['blkSdNm', 'blkTblNm', 'brlncyNm', 'clrNm', 'colNm', 'cultNm', 'cutNm', 'eClnNm', 'fluNm', 'girdleStr', 'hANm', 'lbNm', 'locNm', 'opCrwnNm', 'opPavNm', 'opTblNm', 'polNm', 'shdNm', 'shpNm', 'symNm', 'wSdNm', 'wTblNm']; // prettier-ignore
  static FROZEN_COLUMNS = ['selection', 'Details', 'vStnId', 'shpNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'fluNm', ]; // prettier-ignore
  static NOCHECK_COLUMNS = ['Action', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'Details', 'expBack', 'finalquote', 'hours', 'note', 'offerDis', 'quote', ]; // prettier-ignore

  static ROUND_COLUMNS = ['amt', 'rap', ]; // prettier-ignore
  static FLOAT_COLUMNS = ['amt', 'back', 'cAng', 'cHgt', 'crt', 'ctPr', 'depPer', 'fnDis', 'grdlPer', 'height', 'length', 'lwrHal', 'pAng', 'pHgt', 'rapAvg', 'ratio', 'strLn', 'tblPer', 'width', ]; // prettier-ignore
  static TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt', 'back', 'fnCtpr', 'fnAmt', ]; // prettier-ignore

  // replaces values of this columns with '-' when diamond is not yet available
  static NOT_AVAILABLE_COLUMNS = ['rap', 'ctPr', 'back', 'amt', ]; // prettier-ignore
  static BOLD_COLUMNS = ['amt', 'back', 'brlncyNm', 'clrNm', 'colNm', 'crt', 'ctPr', 'cutNm', 'fluNm', 'polNm', 'rap', 'shdNm', 'shpNm', 'stoneId', 'symNm', 'vStnId', ] // prettier-ignore
}
