import { pruneEmpty } from 'util/utils';

const initialState = {
  loading: 0,
  raw: {},
  data: {},
  error: {},
  _previous: [],
};

export const SERVER_TIME_ACTION_TYPES = {
  SET_DATA: '@server-time/set',
  RESET: '@server-time/reset',
  START_LOADING: '@server-time/start-loading',
  STOP_LOADING: '@server-time/stop-loading',
};

export const SERVER_TIME_ACTIONS = {
  setData: (payload) => ({ type: SERVER_TIME_ACTION_TYPES.SET_DATA, payload }),
  startLoading: () => ({ type: SERVER_TIME_ACTION_TYPES.START_LOADING }),
  stopLoading: () => ({ type: SERVER_TIME_ACTION_TYPES.STOP_LOADING }),
  reset: () => ({ type: SERVER_TIME_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SERVER_TIME_ACTION_TYPES.SET_DATA:
      return {
        ...state,
        diff: payload?.diff,
        raw: payload?.raw,
        data: payload?.data,
        error: payload?.error,
        _previous: [...state._previous, ...[pruneEmpty({ raw: state?.raw, data: state?.data, error: state?.error })]],
      };

    case SERVER_TIME_ACTION_TYPES.START_LOADING:
      return { ...state, loading: ++state.loading };

    case SERVER_TIME_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: --state.loading };

    case '@@RESET':
    case SERVER_TIME_ACTION_TYPES.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
