import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from 'appRedux/reducers/Auth';
import bidConfig from 'appRedux/reducers/bidConfig';
import diamondData from 'appRedux/reducers/diamond';
import loader from 'appRedux/reducers/loader';
import permissions from 'appRedux/reducers/permissions';
import serverTime from 'appRedux/reducers/serverTime';
import tableValues from 'appRedux/reducers/tableValues';
import visitedFields from 'appRedux/reducers/visited-fields';

import commonData from './Common';
import sales from './DashData';
import deleteReminder from './deleteReminder';
import setTimer from './timer';

const reducers = combineReducers({
  auth,
  bidConfig,
  diamondData,
  loader,
  serverTime,
  permissions,
  tableValues,
  visitedFields,

  commonData,
  sales,
  deleteReminder,
  setTimer,
  routing,
});

export default reducers;
