import React, { memo, useCallback } from 'react';

import { Tooltip } from 'antd';
import { groupBy, memoize, uniq } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { MasterService } from 'services/MasterService';
import { Storage } from 'services/Storage';

import IntlMessage from 'util/IntlMessages';
import { history } from 'util/history';
import { formatDateTime, formatDecimal, formatNumber, sleep } from 'util/utils';

import { store } from 'appRedux/store';

import VStnIdCell from './VStnIdCell';
import { TableConfig } from './table-config';

import {
  BASE_DOWNLOAD_URL,
  BASE_URL,
  trackTypeObj,
  LOCAL_STORAGE_VAR,
  DIAMOND_WEB_STATUS,
  DIAMOND_BID,
  OFFICESTATUS,
  PAGES,
} from '../../constants/Common';
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  FEATURE_STONE,
  CREATE_MATCH_PAIR,
  BID_PAGINATE,
  BID_DELETE,
  UPDATE_BID,
  SEARCH_LIST,
  DOWNLOAD_ZIP,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  FETCH_NOTES,
  NOTE_DELETE,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  QUOTE_DAY_LIST,
  GET_BLOCK_DIAMONDS,
  BLOCK_MASTERS,
  ADD_HOLD,
  COUNTER_OFFER,
  BID_CART_PAGINATE,
  BLOCK_REALESE,
  CREATE_BID_CART,
  DIAMOND_TRACK_STATUS_UPDATE,
} from '../../constants/apiConstant';
import UtilService from '../../services/util';
import OpenNotification from '../common/CommonButton/OpenNotification';

const _ = require('lodash');

const LOCATION = {
  india: 'IND',
  beligium: 'BEL',
  newyork: 'NY',
  hongkong: 'HK',
  dubai: 'DXB',
};
const NOTUPCOMING = [];

export const LISTINGPAGES = PAGES;

export const TITLE = {
  [LISTINGPAGES.LIST]: 'Diamond List',
  [LISTINGPAGES.UPCOMING]: 'Upcoming',
  BID: 'DiAntwerp Bidding System',
  [LISTINGPAGES.STONE_OF_DAY]: 'Feature Stone',
  [LISTINGPAGES.NEW_ARRIVALS]: 'New Arrivals',
  [LISTINGPAGES.MATCH_PAIR]: 'Match Pair',
  [LISTINGPAGES.MY_BID]: 'My Bid',
  [LISTINGPAGES.SPECIAL_STONE]: 'Special Stone',
  [LISTINGPAGES.EXCLUSIVE]: 'Exclusive Collection',
  [LISTINGPAGES.CART]: 'My Cart',
  [LISTINGPAGES.WATCH_LIST]: 'My Watchlist',
  [LISTINGPAGES.REMINDER]: 'My Reminder',
  [LISTINGPAGES.CONFIRM]: 'My Confirm List',
  [LISTINGPAGES.QUOTE]: 'My Quote List',
  [LISTINGPAGES.NOTE]: 'My Notes',
  [LISTINGPAGES.OFFICE]: 'My Office List',
  [LISTINGPAGES.QUOTE_DAY]: 'Quote Day',
  [LISTINGPAGES.SUGGESTED_STOCK]: 'Suggested Stock',
  [LISTINGPAGES.ENQUIRY]: 'My Enquiry',
  [LISTINGPAGES.HOLD]: 'My Hold List',
};

export const getPath = () => window?.location?.pathname?.split?.('?')?.[0]?.split?.('/')?.pop?.();

const ColumnFilter = memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={require('../../assets/svg/DiamondList/close-black.svg')} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

/**
 * This is a deformity; a child of various mothers and fathers with other-worldly intelligence
 * @param {*} res unknown
 * @param {*} type unknown
 * @returns whatever it wants
 */
export function responseUglifier(res, type) {
  let rows = [];
  let count = 0;
  let sum = {};
  let seachFilterTag = {};
  const filterArray = {};
  let inTrackDiamonds = [];
  if (type === DIAMOND_WEB_STATUS.EXCLUSIVE) {
    rows = res.data?.diamonds ?? [];
    count = res.data?.count ?? rows?.length;

    TableConfig.FILTER_COLUMNS.map((x) => {
      filterArray[x] = _.uniq(rows.map((r) => r[x]));
    });
  } else if (res && res.code === 'OK') {
    seachFilterTag = res.data[0]?.filter;
    rows = res.data[0]?.diamonds;
    if (type === DIAMOND_WEB_STATUS.UPCOMING) {
      const grp = _.groupBy(rows, 'inDt');
      rows = [];
      Object.keys(grp).map((x) => {
        grp[x][0].isUpcomingHeader = true;
        grp[x][0].totalDiamonds = grp[x].length;
        rows = [...rows, ...grp[x]];
      });
    }
    sum = res.data[0]?.total?.[0] || {};
    count = res.data[0]?.count;
    TableConfig.FILTER_COLUMNS.map((x) => {
      filterArray[x] = _.uniq(rows.map((r) => r[x]));
    });
    inTrackDiamonds = res.data.inTrackDiamonds;
  }
  return { data: rows, seachFilterTag, sum, count, filterArray, selectedFilterArray: {}, inTrackDiamonds };
}

function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;
export function isMobile() {
  return window.innerWidth <= 767 ? true : false;
}
export function isIpade() {
  return window.innerWidth <= 991 ? true : false;
}

export function getNewStatus(row) {
  const diff = moment() - moment(row?.mkDt);

  const newInvent = diff > 15 * 24 * 60 * 60 * 1000 || row.wSts === 'B' || row.wSts === 'U' ? false : true;
  return newInvent;
}

const DATA_KEY_MASTER = {
  TO: 'OPEN_INCLUSION_TABLE',
  CO: 'OPEN_INCLUSION_CROWN',
  PO: 'OPEN_INCLUSION_PAVILION',
  CN: 'BLACK_INCLUSION_TABLE',
  SN: 'BLACK_INCLUSION_SIDE',
  CW: 'WHITE_INCLUSION_TABLE',
  SW: 'WHITE_INCLUSION_SIDE',
};

const cells = {
  fcColNm: (colNm) => (
    <div className="normalTableDropdown">
      <Tooltip title={`${colNm ? colNm : '-'}`} placement="bottomLeft" className="normalTableDropdown ">
        {colNm ?? '-'}
      </Tooltip>
    </div>
  ),
};

export function getColumn() {
  const columnList = null;
  const Columns = [];

  if (Storage.get(`${LOCAL_STORAGE_VAR}-column`, false) || columnList) {
    const getColumnList = columnList ? columnList : Storage.get(`${LOCAL_STORAGE_VAR}-column`, false);

    getColumnList.map((dataa) => {
      const data = {};
      Object.keys(dataa).map((x) => (data[x] = dataa[x]));
      if (data.isActive && data.field) {
        data.field = data.field.trim();
        data.title = data.title ? data.title.trim() : data.title;
        data.key = data.key ? data.key.trim() : data.key;
        if (_.find(Columns, { id: data.field })) return;
        if (data.field === 'reminderDate' && getPath() !== LISTINGPAGES.REMINDER) return false;
        const remarksList = [LISTINGPAGES.ENQUIRY, LISTINGPAGES.NOTE];
        if (data.field === 'remarks' && !remarksList.includes(getPath())) return false;
        if (data.field === 'remarks' && remarksList.includes(getPath()))
          data.title = getPath() === LISTINGPAGES.NOTE ? 'Notes' : 'Enquiry';

        if (NOTUPCOMING.includes(data.field) && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'back' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'rap' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'amt' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'status' && getPath() !== LISTINGPAGES.OFFICE) return false;
        if (data.field === 'purpose' && getPath() !== LISTINGPAGES.OFFICE) return false;
        if (data.field === 'purpose' && getPath() === LISTINGPAGES.OFFICE) data.title = 'Comments';
        if (data.field === 'ctPr' && getPath() === LISTINGPAGES.UPCOMING) return false;

        const canFilter = TableConfig.FILTER_COLUMNS.includes(data.field);
        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCH_LIST) data.title = "Today's Disc%";
        const master = Object.hasOwn(MasterService.data) ? MasterService.getMapByCode(DATA_KEY_MASTER[data.key]) : {};

        Columns.push({
          ...data,
          id: data.field,
          accessor: data.field === 'dna' ? 'vStnId' : data.field,
          filter: filterArrayIncludes,
          Filter: canFilter ? ColumnFilter : <></>,

          Header: data.title,
          Cell: React.memo(({ cell }) => {
            const content = (() => {
              const description = master?.[cell?.value]?.description;

              if (description) return cell.value;

              if (data.isFlag) return cell.value ? data.sLabel ?? 'Yes' : data.nLabel ?? '-';

              if (cell.value === true) return 'Y';

              if (!cell.value) return '-';

              if (cell.column.id === 'vStnId') return <VStnIdCell data={cell.row.original} />;

              if (cell.column.id === 'reminderDate') return formatDateTime(cell.row.original.reminderDate);

              if (cell.column.id === 'colNm' && cell.row.original.isFcCol)
                return cells.fcColNm(cell.row.original.fcColNm);

              if (cell.column.id === 'locNm') return LOCATION[cell.value.trim().toLowerCase()] ?? cell.value;

              if (cell.column.id === 'status' && getPath() === LISTINGPAGES.OFFICE) {
                return (
                  <div className={`officeStatus ${OFFICESTATUS[cell.value].toLowerCase()}`}>
                    <span>{OFFICESTATUS[cell.value]}</span>
                  </div>
                );
              }

              if (TableConfig.FLOAT_COLUMNS.includes(cell.column.id)) return formatDecimal(cell.value);

              if (TableConfig.ROUND_COLUMNS.includes(cell.column.id)) return formatNumber(Math.round(cell.value));

              return cell.value;
            })();

            return <div className={cell.column.id === 'dna' ? 'underline' : ''}>{content}</div>;
          }),
        });

        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCH_LIST) {
          Columns.push({
            id: 'remarks',
            accessor: 'remarks',
            Header: 'Comment',
            Cell: ({ cell }) => (cell.row.original.wSts !== 'U' ? cell.value : '-'),
          });
        }
      }
    });
  }

  return Columns;
}

export const memoizedGetColumn = memoize(
  (...args) => {
    return new Promise((resolve) => resolve(getColumn(...args)));
  },
  (...args) => args.join(''),
);

export function handleAlter(array, item, key) {
  let checked = _.cloneDeep(array) || [];
  if (_.find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}

export function getParams(props_row) {
  const params = [
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export function getStoneInfo(props_row) {
  const params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export function getStoneTitle(obj) {
  const params = obj.map((p) => ({
    shp: p.shpNm,
    crt: parseFloat(p.crt).toFixed(2),
    col: p.isFcCol ? p.fcColNm : p.colNm,
    clr: p.clrNm,
    cut: p.cutNm,
    pol: p.polNm,
    sym: p.symNm,
  }));
  let display = [];
  const keyArr = ['shp', 'crt', 'col', 'clr', 'cut', 'pol', 'sym'];
  keyArr.map((key) => {
    const word = _.groupBy(params, key);
    if (_.size(word) === 1) display.push(params[0][key]);
  });
  display = display.filter((el) => el && el).join(' ');
  return display;
}

export function compareStones(list, currentType = null) {
  if (list.length === 0) {
    OpenNotification({
      type: 'error',
      title: 'Please select stone(s) to compare.',
    });
    return;
  }
  if (list.length > 15) {
    OpenNotification({
      type: 'error',
      title: 'Maximum 15 stones can be compared.',
    });
    return;
  }
  let data = [];
  list.map((x) => data.push(window.location.pathname === '/account/confirm-list' ? x.diamondId : x.id));
  data = _.uniq(data);
  if (data.length === 1) {
    OpenNotification({
      type: 'error',
      title: 'Please select minimum two stones to compare.',
    });
    return;
  }
  if (data.length > 15) {
    data = data.slice(data.length - 15, data.length);
  }
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(data));
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-now`, JSON.stringify(window.location.pathname + window.location.search));
  ['MyDealOfTheDay'].includes(currentType) ? history.push('/compare', { currentType }) : window.open('/compare');
}

export function fetchDiamondList(state, filters, cb) {
  const sort = state.sort;
  const obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    filters,
    isAppendMasters: true,
    isSkipSave: true,
    page: state.page,
    limit: state.limit,
    sort: sort?.map((x) => (x.hasOwnProperty('clrNm') ? { clrSq: x.clrNm } : x)),
  };
  const output = {
    rows: [],
    count: 0,
    sum: {},
    seachFilterTag: {},
    inTrackDiamonds: [],
    inBlockDiamonds: [],
    filterArray: {},
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      output.seachFilterTag = data?.data[0].filter;
      output.rows = data?.data[0].diamonds;
      output.sum = data?.data[0]?.total[0];
      output.count = data?.data[0]?.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        output.filterArray[x] = _.uniq(output.rows.map((r) => r[x]));
      });
      output.inTrackDiamonds = data.data[0].inTrackDiamonds;
      output.inBlockDiamonds = data.data[0].inBlockDiamonds;
    }

    cb(output);
    return output;
  });
}

export function fetchStoneOfDay(state, filters, cb) {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
  };

  obj.request = {
    filters,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isAppendMasters: true,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      sum = data.data[0].total[0];
      rows = data.data[0].diamonds;
      count = data.data[0].count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function handleExclusiveSearchListId(cb) {
  const searchAPI = SEARCH_DIAMOND_LIST;
  const searchAPIObj = {
    filters: [
      {
        or: [
          {
            crt: { '>=': '5.00', '<=': '5.99' },
          },
          {
            crt: { '>=': '6.00', '<=': '9.99' },
          },
          {
            crt: { '>=': '10.00', '<=': '19.99' },
          },
          {
            crt: { '>=': '20.000', '<=': '100' },
          },
        ],
      },
    ],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };
  const objData = {
    ...searchAPI,
    request: searchAPIObj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (data && data.code === 'OK') {
      const obj = SEARCH_LIST;
      obj.request = {
        id: data.data[0].filter.id,
        isAppendMasters: true,
      };
      UtilService.callApi(obj, (err, data) => {
        cb(data && data.code === 'OK' ? data.data.list[0].searchData : null);
      });
    } else cb(null);
  });
}

export function fetchOffer(state, cb) {
  const obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.QUOTE,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        otherRemarks: x.otherRemarks,
        bargainTrack: x.bargainTrack,
        remarks: x.remarks,
        newDiscount: x.newDiscount,
        newAmount: x.newAmount,
        newPricePerCarat: x.newPricePerCarat,
        offerStatus: x.offerStatus,
        updatedAt: x.updatedAt,
        trackId: x.id,
        offerValidDate: x.offerValidDate,
        grpBy: x.updatedAt.split('T')[0],
      }));
      const grp = groupBy(rows, 'grpBy');
      rows = [];
      Object.keys(grp).forEach((key) => {
        grp[key][0].isOfferHeader = true;
        rows = [...rows, ...grp[key]];
      });
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchBid(state, configId, subTypeCode, status, cb, historyTab) {
  const request = {
    ...BID_PAGINATE,
    request: {
      page: state.page,
      limit: state.limit,
      sort: state.sort,
      status: status,
      from: state.date.from ? state.date.from.toISOString() : null,
      to: state.date.to ? state.date.to.toISOString() : null,
      bidConfigurationType: getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY ? 2 : store.getState()?.bidConfig?.data?.type,
    },
  };

  if (!historyTab) {
    request.request = { ...request.request, bidConfigurationId: configId, subTypeCode };
  }

  UtilService.callApi(request, (err, res) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (res && res.code === 'OK') {
      rows = res.data.list.map((x) => ({
        ...x.diamond,
        trackId: x.id,
        LowerBid: x.LowerBid,
        bidAmount: x.bidAmount,
        bidDiscount: x.bidDiscount,
        bidPricePerCarat: x.bidPricePerCarat,
        bidType: x.bidType,
        status: x.status,
        updatedAt: x.updatedAt,
        grpBy: x.updatedAt.split('T')[0],
        isLowerBid: x.isLowerBid,
      }));

      const grp = _.groupBy(rows, 'grpBy');
      rows = [];
      Object.keys(grp).map((grpNo) => {
        const val = grp[grpNo];
        val[0].isBidHeader = true;
        val[0].groupingTitle = moment(val[0].updatedAt).format('DD/MM/YYYY');
        rows = [...rows, ...val];
      });

      sum = res.data.total[0];
      count = res.data.count;
      inTrackDiamonds = res.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchBidCart(cb) {
  return new Promise((resolve, reject) => {
    const obj = BID_CART_PAGINATE;
    obj.request = {
      trackType: trackTypeObj.BIDCART,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) reject(err);
      if (data && data.code === 'OK') return resolve(data.data);
      reject(err);
    });
  });
}

export function fetchMatchPair(state, diamondSearchId, cb) {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: state.page,
    limit: state.limit,
    sort: state.sort,

    isSkipSave: true,
  };

  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      const grp = _.groupBy(data.data.list, 'groupNo');
      Object.keys(grp).map((grpNo) => {
        const val = grp[grpNo];
        val[0].isMatchHeader = true;
        val[0].groupingTitle = getStoneTitle(val);
        val[0].totalDiamonds = val.length;
        val[val.length - 1].groupData = {};
        val[val.length - 1].isMatchFooter = true;
        TableConfig.TOTAL_COLUMNS.map((i) => {
          val[val.length - 1].groupData[i] = [];
          val.map((x) => {
            if (x[i]) val[val.length - 1].groupData[i].push(x[i]);
            return true;
          });
          return true;
        });
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchConfirmStone(state, cb) {
  const obj = FETCH_CONFIRM_STONE;
  obj.request = { page: state.page, limit: state.limit };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    const sum = {};
    let seachFilterTag = {};
    const inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      const grp = data.data.list.filter((x) => x.memoDetails && x.memoDetails.length);
      _.each(grp, (x) => {
        const val = x.memoDetails;
        const obj = _.omit(x, 'memoDetails');
        val[0] = {
          ...obj,
          ...val[0],
          isConfirmHeader: true,
          totalDiamonds: val.length,
        };
        rows = [...rows, ...val];
      });

      count = data.data.count;
      seachFilterTag = data.data.filter;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.CART,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => x.diamond);
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOfficeList(state, cb) {
  const obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: 'ASC' }],

    filter: { date: { '>=': moment().startOf('day').toISOString() } },
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      if (!isEmpty(data.data)) {
        const grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);
        _.each(grp, (x) => {
          const val = x.diamonds;
          val[0] = {
            ...val[0],
            isOfficeHeader: true,
            cabinSlot: x.cabinSlot,
            date: x.date,
          };
          _.each(val, (all) => {
            all.slottId = x.id;
            all.meetingType = x.meetingType;
            all.status = x.status;
            all.purpose = x.purpose;
          });
          rows = [...rows, ...val];
        });

        count = data.data.count;
        sum = data.data.total;
        TableConfig.FILTER_COLUMNS.map((x) => {
          filterArray[x] = _.uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.WATCH_LIST,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        newDiscount: x.newDiscount,
        trackDiscount: x.trackDiscount,
        remarks: x.remarks,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const releaseBlockedDiamonds = (ids, cb) => {
  const obj = {
    ...BLOCK_REALESE,
    request: { diamondBlocks: ids, status: 4 },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function fetchNotes(state, cb) {
  const obj = FETCH_NOTES;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchEnquiries(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
    trackType: trackTypeObj.ENQUIRY,
  };

  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        remarks: x.remarks,
        ...x.diamond,
        trackId: x.id,
        createdAt: x.createdAt ? moment(x.createdAt).format('DD/MM/YYYY hh:mm A') : '-',
      }));
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      const grp = _.groupBy(rows, 'remarks');
      rows = [];
      Object.keys(grp).forEach((x) => {
        grp[x][0].isEnquiryRemarks = true;
        rows = [...rows, ...grp[x]];
      });

      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.REMINDER,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export async function fetchDiamondPaginate(state, type, cb, filtr = {}) {
  const obj = SEARCH_DIAMOND_LIST;
  const sort = getPath() === LISTINGPAGES.SPECIAL_STONE;

  obj.request = {
    page: state.page,
    limit: state.limit,
    filters: !_.isEmpty(filtr) ? [filtr] : [type.viewType ? {} : { wSts: type, ...filtr }],
    sort: sort ? [{ crt: 'DESC' }] : state.sort,
    viewType: type.viewType,
  };
  if (type === DIAMOND_WEB_STATUS.UPCOMING) {
    obj.request.isUpcoming = true;
  }

  const [, res] = await UtilService.callApi(obj);

  return cb(responseUglifier(res, type));
}

export const handleInsertTrack = (trackType, selected, cb, date, remarks) => {
  const diamonds = [];
  selected.map((s) =>
    diamonds.push({
      diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
      newDiscount: trackType === trackTypeObj.WATCH_LIST ? Number(s.expBack) : null,
      trackDiscount: trackType === trackTypeObj.WATCH_LIST ? s.back : null,
      vStnId: s?.vStnId,
      vnd: s?.vnd,
    }),
  );
  const obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remarks,
  };
  const objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    const flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : data &&
          data.data &&
          data.data.created &&
          data.data.created.length === 0 &&
          data.data.exists &&
          data.data.exists.length !== 0
        ? false
        : true;
    if (data && data.code === 'OK' && flag) {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export function handleNoteUpsert(selected, cb) {
  const obj = NOTE_UPSERT;
  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim(), vnd: d?.vnd, vStnId: d?.vStnId })),
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export const handleUpsertTrack = (trackType, selected, inTrackDiamonds, cb, date) => {
  const ids = selected.map((x) => x.id);
  const id = [];
  const diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    _.each(ids, (diamondId) => {
      const trackId = _.find(diamonds, { id: diamondId });
      if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    reminderDate: date,
    trackType,
    id,
  };
  const objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export const handleMemoPrint = (filter, orderDiamond, cb) => {
  let obj = {
    orderDiamond,
  };
  if (_.isEmpty(orderDiamond)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handlePrint = (filter, id, cb) => {
  let obj = {
    id,
  };
  if (_.isEmpty(id)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handleDownloadExcel = (filter = {}, id = [], cb) => {
  const objData = {
    ...EXPORT_EXCEL,
    request: _.isEmpty(filter) ? { id } : { filter },
  };
  UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
      cb(true);
    }
  });
};
export function shareExcel(checked, cb) {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      filter: {
        vStnId: checked,
      },
      isExcel: true,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(data.data.data);
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
  });
}

export function handleConfirmStone(ids, comment, date, company, pBack, cb) {
  const termDis = localStorage.getItem('TermsDis');
  const termObj = JSON.parse(localStorage.getItem('termObj'));
  termObj.value = termDis;
  const { id, minAmt, resetDis = '', ...reset } = termObj;
  const obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
      company,
      date,
      pBack,
      termsDis: reset,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export function handleNoteDelete(id, cb) {
  const obj = NOTE_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err.data.message,
      });
      cb(null);
    }
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  const id = [];
  const diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    _.each(ids, (diamondId) => {
      const trackId = _.find(diamonds, { id: diamondId });
      if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
    });
  }

  const obj = {
    ...DELETE_TRACK,
    request: {
      trackType,
      id,
    },
  };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export function handleBidDelete(id, cb) {
  const obj = BID_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.data.message : err ? err.message : err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export const handleSendEmail = (apiObj, cb) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: data.message,
      });
    }
  });
};

export function handleBidCartCreate(rows, id, code, result, cb) {
  const obj = CREATE_BID_CART;
  const subType = store.getState().bidConfig.data?.sub_types;
  obj.request = {
    trackType: trackTypeObj.BIDCART,
    bidResultDateTime: subType.currentBidEndDate,
    diamonds: rows.map((x) => ({
      diamond: x.id,
      trackPricePerCarat: parseFloat(x.ctPr).toFixed(2),
      trackAmount: x.amt,
      trackDiscount: x.back,
      newDiscount: null,
    })),
  };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export async function handleBidUpsert(rows, bidConfig, cb) {
  const id = bidConfig?.id;
  const code = bidConfig?.sub_types?.subTypeCode;
  const result = bidConfig?.sub_types?.result;

  const obj =
    getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY || getPath() === LISTINGPAGES.MY_BID ? UPDATE_BID : CREATE_BID;
  const isDealOfTheDay = getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY || getPath() === LISTINGPAGES.DEAL_OF_THE_DAY;
  obj.request =
    getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY || getPath() === LISTINGPAGES.MY_BID
      ? {
          id,
          bidConfigurationType: isDealOfTheDay ? 2 : undefined,
          result_type: parseFloat(result),
          diamonds: rows.map((x) => ({
            id: x.trackId,
            bidPricePerCarat: x.bidPricePerCarat,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            bidAmount: x.bidAmount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            deviceType: 1,
            status: x.status,
          })),
        }
      : {
          bidConfigurationId: id,
          subTypeCode: code,
          bidConfigurationType: isDealOfTheDay ? 2 : undefined,

          result_type: parseFloat(result),
          diamonds: rows.map((x) => ({
            diamond: x.id,
            bidPricePerCarat: x.bidPricePerCarat,
            bidAmount: x.bidAmount,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            vnd: x.vnd,
            vStnId: x.vStnId,
          })),
        };

  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      void cb?.(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      void cb?.(false);
    }
  });
}

export function handleCartBidUpsert(rows, id, code, result, cb) {
  const obj = CREATE_BID;
  obj.request = {
    bidConfigurationId: id,
    subTypeCode: code,

    result_type: parseFloat(result),
    diamonds: rows.map((x) => ({
      diamond: x.id,
      bidPricePerCarat: parseFloat(x.bidPricePerCarat ?? x.ctPr),
      bidAmount: x.bidAmount ?? x.amt ?? null,
      bidDiscount: x.isFcCol ? 0 : x.bidDiscount ?? x.back ?? null,
      trackPricePerCarat: x.ctPr,
      trackAmount: x.amt,
      trackDiscount: x.back,
      vnd: x.vnd,
      vStnId: x.vStnId,
    })),
  };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

function offerObject(item, comment) {
  if (!item.isFcCol) {
    return {
      tracks: [
        {
          remarks: comment,
          id: item.trackId,
          diamond: item.id,
          newPricePerCarat: item.calcPricePerCarat,
          newAmount: item.calcAmount,
          newDiscount: item.calcDiscount,
          offerValidDate: moment().add(item.hours, 'hours'),
        },
      ],
    };
  } else {
    return {
      tracks: [
        {
          remarks: comment,
          id: item.trackId,
          diamond: item.id,
          newPricePerCarat: item.calcPricePerCarat,
          newAmount: item.calcAmount,
          newDiscount: item.calcDiscount,
          offerValidDate: moment().add(item.hours, 'hours'),
        },
      ],
    };
  }
}

function offerCrateObject(item) {
  if (!item.isFcCol) {
    return {
      diamond: item.trackId,
      trackPricePerCarat: item.ctPr,
      trackAmount: item.amt,
      trackDiscount: item.back,
      vStnId: item.vStnId,
      newAmount: item.calcAmount,
      newPricePerCarat: item.calcPricePerCarat,
      newDiscount: item.calcDiscount,
      offerValidDate: moment().add(item.hours, 'hours'),
    };
  } else {
    return {
      diamond: item.trackId,
      trackPricePerCarat: item.ctPr,
      trackAmount: item.amt,
      trackDiscount: item.back,
      vStnId: item.vStnId,
      newAmount: item.calcAmount,
      newPricePerCarat: item.calcPricePerCarat,
      newDiscount: item.calcDiscount,
      offerValidDate: moment().add(item.hours, 'hours'),
    };
  }
}

export function handleSaveQuote(selectedItems, comment, company, cb, update) {
  const obj = update ? COUNTER_OFFER : CREATE_TRACK;
  const item = selectedItems[0];
  const offer = offerObject(item);
  if (!offer) return false;
  obj.request = update
    ? {
        ...offerObject(item, comment),
      }
    : {
        trackType: trackTypeObj.QUOTE,
        remarks: comment,
        company,
        diamonds: selectedItems.map((x) => ({
          ...offerCrateObject(x),
          diamond: x.id,
        })),
      };

  update && delete obj.request.offerValidDate;
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      if (update) {
        OpenNotification({
          type: 'success',
          title: data.message,
        });
        cb(true);
        return;
      } else {
        OpenNotification({
          type: 'success',
          title: data.message,
        });
        cb(true);
      }
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  const obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: data.message,
      });
    }
  });
};

export function allSortObject(columns) {
  const sort = {};
  _.each(columns, (col) => {
    if (col.sort) sort[col.sort] = col.Header;
  });
  return sort;
}

export function getProjectSetting(type, cb) {
  const obj = PROJECT_SETTING;
  obj.request = {
    filter: {
      type,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') cb(data.data);
    else cb([]);
  });
}

export const downloadZip = (dt, cb) => {
  const obj = {
    ...DOWNLOAD_ZIP,
    request: { ...dt },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_URL;
      const file_path = server + '/' + data.data;
      window.open(file_path, 'Download');
    } else if (data && data.code === 'E_NOT_FOUND') {
      OpenNotification({
        type: 'error',
        title: data.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    cb(true);
  });
};

export const downloadFile = (apiObj) => {
  const obj = {
    ...DOWNLOAD_FILE,
    request: apiObj,
  };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.path;

      const xhr = new XMLHttpRequest();
      xhr.open('GET', file_path, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };

      xhr.onerror = (err) => {
        return OpenNotification({
          type: 'error',
          title: 'This file is not available !',
        });
      };

      setTimeout(() => {
        xhr.send();
      }, 5000);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const downloadVideo = (apiObj) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', apiObj.path, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.target = '_blank';
    tag.download = apiObj.fileName + apiObj.ext;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.onerror = (err) => {
    return OpenNotification({
      type: 'error',
      title: 'This file is not available !',
    });
  };
  setTimeout(() => {
    xhr.send();
  }, 5000);
};

export const handleDeleteOffice = (filter, cb) => {
  const self = this;

  const objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: filter,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(true);
    }
  });
};

export const fetchQuotedayDiamonds = (cb) => {
  const obj = QUOTE_DAY_LIST;
  obj.request = {
    type: 2,
    isDeleted: false,
    isActive: true,
  };

  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    const sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    const inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = _.map(data.data.list, 'diamond');
      count = rows.length;
      TableConfig.FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
};

export const myAccountExcelTrack = (trackType) => {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const myHoldExcelTrack = (blockType) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { isExcel: true, page: 1, limit: 100000, blockType, sort: {} },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const featureStoneExcelTrack = (sectionType) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      page: 1,
      limit: 100000,
      filter: { sectionType },
      sort: {},
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const matchPairExcelTrack = (diamondSearchId) => {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: 1,
    limit: 100000,
    sort: {},
    isExcel: true,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const myAccountExcelMemo = (filter) => {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};
export const myAccountExcelOffice = () => {
  const obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
      filter: { date: { '>=': moment().startOf('day').toISOString() } },
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function myAccountNotesExcel() {
  const obj = {
    ...FETCH_NOTES,
    request: {
      isExcel: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
}

export function myBidExcel() {
  const obj = {
    ...BID_PAGINATE,
    request: {
      isExcel: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
}

export function upcomingExcel(filter) {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isExcel: true,
      page: 1,
      filter,
      limit: 100000,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
}

export const myAccountPDFTrack = (trackType) => {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const featureStonePDFTrack = (sectionType) => {
  const obj = {
    ...PRINT_PDF,
    request: {
      page: 1,
      limit: 100000,
      filter: { sectionType },
      sort: {},
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const myHoldPDFTrack = (blockType) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      blockType,
      sort: {},
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const matchPairPDFTrack = (diamondSearchId) => {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: 1,
    limit: 100000,
    sort: {},
    isPdf: true,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const myAccountPDFMemo = (filter) => {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};
export const myAccountPDFOffice = () => {
  const obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
      filter: { date: { '>=': moment().startOf('day').toISOString() } },
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function myAccountNotesPDF() {
  const obj = {
    ...FETCH_NOTES,
    request: {
      isPdf: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
}

export function myBidPDF() {
  const obj = {
    ...BID_PAGINATE,
    request: {
      isPdf: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
}

export const upcomingPDF = (filter) => {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isPdf: true,
      isUpcoming: true,
      page: 1,
      limit: 100000,
      sort: {},
      filter,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const getDiamondBlockMaster = (code, cb) => {
  const obj = {
    ...BLOCK_MASTERS,
    request: {
      filter: { blockCode: code },
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(data.data[0]);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const handleAddBlock = (block, checked, time, comment, cb) => {
  const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  const termDis = localStorage.getItem('TermsDis');
  const termObj = termDis && JSON.parse(localStorage.getItem('termObj'));
  if (termObj) termObj.value = termDis;
  const { id, minAmt, resetDis = '', ...reset } = termObj;
  const obj = {
    ...ADD_HOLD,
    request: {
      blockType: block,
      diamonds: checked,
      user: user?.id,
      userAccount: user.account?.id,
      seller: user.seller,
      remarks: comment,
      termsDis: reset,
    },
  };
  if (block === 1) obj.request = { ...obj.request, validTillDate: time };
  if (block === 2)
    obj.request = {
      ...obj.request,
      assistantSeller: user.seller,
      shippingCountry: user.country,
      shippingCity: user.city,
      stage: checked[0].blockSetting,
      broker: user.account.broker,
      shipMode: 'Ship To Direct',
    };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const fetchBlockDiamonds = (blockType, cb) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { blockType },
  };
  UtilService.callApi(obj, (err, data) => {
    const rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      seachFilterTag = data.data.filter ? data.data.filter : {};
      data.data.list &&
        data.data.list.map((l) => {
          rows.push({ ...l.diamond, ids: l.id });
        });
      sum = data.data.total ? data.data.total[0] : {};
      count = data.data.count;

      inTrackDiamonds = data.data.inTrackDiamonds ? data.data.inTrackDiamonds : [];
      inBlockDiamonds = data.data.inBlockDiamonds ? data.data.inBlockDiamonds : [];
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export const DiamondTrackStatusUpdate = (id, offerStatus, cb) => {
  const objData = {
    ...DIAMOND_TRACK_STATUS_UPDATE,
    request: { id, ...offerStatus },
  };
  UtilService.callApi(objData, async (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.data.message || data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
      cb(false);
    }
  });
};
