import React from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { Drawer } from 'antd';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/map';

import { RowSelectService } from 'services/RowSelectService';
import UtilService from 'services/util';

import IntlMessages from 'util/IntlMessages';
import { useIsMobile, usePathname } from 'util/hooks';

import { trackTypeObj, LOCAL_STORAGE_VAR } from 'constants/Common';

import { setCount } from 'appRedux/actions/deleteReminder';

import BidMobilePopup from './BidPopup';
import BidPopup from './BidStone';
import ConfirmStonePopup from './ConfirmStonePopup';
import DealoftheDayPopup from './DealOfDayPopup';
import DownloadDropdown from './DownloadDropdown';
import EnquiryPopup from './EnquiryPopup';
import FinalCalculations from './FinalCalculations';
import HoldPopup from './HoldPopup';
import NotePopup from './NotePopup';
import OfficeSidebar from './OfficeSidebar';
import QuotePopup from './QuotePopup';
import ShareDropdown from './ShareDropdown';
import WatchlistPopup from './WatchlistPopup';

// import MobileShareOption from './MobileShareOption';
// import MobileDownloadOption from './MobileDownloadOption';

import {
  compareStones,
  DiamondTrackStatusUpdate,
  featureStoneExcelTrack,
  featureStonePDFTrack,
  handleDownloadExcel,
  handleInsertTrack,
  handleMemoPrint,
  handlePrint,
  handleUpsertTrack,
  isMobile,
  LISTINGPAGES,
  matchPairExcelTrack,
  matchPairPDFTrack,
  myAccountExcelMemo,
  myAccountExcelOffice,
  myAccountExcelTrack,
  myAccountNotesExcel,
  myAccountNotesPDF,
  myAccountPDFMemo,
  myAccountPDFOffice,
  myAccountPDFTrack,
  myBidExcel,
  myBidPDF,
  myHoldExcelTrack,
  myHoldPDFTrack,
} from '../../DiamondList/DiamondListFunctions';
import { GuestUserPopup } from '../../GuestUserPopup';
import OpenNotification from '../CommonButton/OpenNotification';
import CommonModal from '../CommonModal';
import Pagination from '../Pagination';
import Reminder from '../Reminder';
import UpdateReminder from '../UpdateReminder';

const DiamondListingAction = (props) => {
  const [, isTablet] = useIsMobile();
  const pathname = usePathname();

  const currentType = props.currentType ?? pathname;
  const history = useHistory();

  const selectedRows = useSelector((state) => state.diamondData.selectedRows[props.currentType]);
  const checked =
    window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
    window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
      ? props.checked
      : props.checkedData ?? selectedRows ?? [];

  const [visible, setVisible] = React.useState('');
  const [moreSearch, moreSearchOption] = React.useState(false);
  const [more, setMore] = React.useState(false);
  const [reminder, setReminder] = React.useState(false);
  const [disclaimer, setDisclaimer] = React.useState(false);
  const [showGuest, setShowGuest] = React.useState(false);

  const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

  const moreSearchDrawer = () => {
    moreSearchOption(true);
  };
  const onClose = (current = currentType) => {
    setVisible('');
    setReminder('');
    moreSearchOption(false);
    props.setCount(!props.deleteReminder.count);
    RowSelectService.resetSelectedRows(current);
  };

  const path = window.location.pathname.split('/')[1];
  const module = window.location.pathname.split('/')[2];

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const actions = [
    !props.bidStone
      ? null
      : {
          name: <span>Make Deal</span>,
          key: 'bid',
          img: require('assets/svg/DiamondList/bid-stone.svg'),
          onClick: () => {
            const selectTerm = localStorage.getItem('Terms');
            if (!isGuest) {
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to bid.',
                });
              } else {
                setVisible('bid');
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    !props.dealOfTheDay
      ? null
      : {
          name: <IntlMessages id="app.bidStone" />,
          key: 'bid',
          img: require('assets/svg/DiamondList/bid-stone.svg'),
          onClick: () => {
            const selectTerm = localStorage.getItem('Terms');
            if (!isGuest) {
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to bid.',
                });
              } else {
                setVisible('bid');
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    !props.myBid
      ? null
      : {
          name: <IntlMessages id="app.myBid" />,
          key: 'mybid',
          img: require('assets/svg/DiamondList/my-bid.svg'),
          onClick: () => {
            if (!isGuest) {
              history.push('/my-bid');
            } else {
              setShowGuest(true);
            }
          },
        },
    !props.myBidRemove
      ? null
      : {
          name: 'Remove Bid',
          key: 'mybidremove',
          img: require('assets/svg/DiamondList/my-bid.svg'),
          onClick: () => {
            if (!isGuest) {
              void props?.onRemoveBidStones();
            } else {
              setShowGuest(true);
            }
          },
        },
    !props.myBidDealList && get(USER_PERMIT, 'MY_BID.view', false)
      ? null
      : {
          name: <span>My Deal Of The Day</span>,
          key: 'deal',
          img: require('assets/svg/DiamondList/my-bid.svg'),
          onClick: () => {
            if (!isGuest) {
              history.push('/my-deal-of-the-day');
            } else {
              setShowGuest(true);
            }
          },
        },
    !props.cancel
      ? null
      : {
          name: <span>Cancel</span>,
          key: 'mybidcart',
          img: require('assets/svg/DiamondList/my-bid-cart.svg'),
          onClick: props.onClose,
        },

    props.update
      ? {
          name: <IntlMessages id="app.updateStone" />,
          img: require('assets/svg/DiamondList/updatestone.svg'),
          key: 'update',
          onClick: () => {
            if (!isGuest) {
              if (pathname === LISTINGPAGES.REMINDER) {
                isEmpty(checked)
                  ? OpenNotification({
                      type: 'error',
                      title: 'Please select stone(s) to update reminder.',
                    })
                  : setReminder('update');
              } else if (
                pathname === LISTINGPAGES.MY_BID ||
                pathname === LISTINGPAGES.MY_DEAL_OF_THE_DAY ||
                pathname === LISTINGPAGES.DEAL_OF_THE_DAY
              ) {
                isEmpty(checked)
                  ? OpenNotification({
                      type: 'error',
                      title: 'Please select stone(s) to update bid.',
                    })
                  : setVisible('bid');
              } else if (pathname === LISTINGPAGES.NOTE) {
                isEmpty(checked)
                  ? OpenNotification({
                      type: 'error',
                      title: 'Please select stone(s) to update note.',
                    })
                  : setVisible('note');
              }
            } else {
              setShowGuest(true);
            }
          },
        }
      : null,
    // props.noconfirm
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.confirmStone" />,
    //       key: 'confirm',
    //       disabled:
    //         checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
    //         !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
    //           const allIds = [
    //             ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
    //             ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
    //           ];
    //           return allIds.includes(e);
    //         }),

    //       onClick: () => {
    //         const selectTerm = localStorage.getItem('Terms');
    //         if (!isGuest) {
    //           if (
    //             window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
    //             window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
    //           ) {
    //             setVisible('confirm');
    //           }
    //           if (isEmpty(checked)) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Please select stone(s) to confirm.',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'U')) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Sorry, you can not perform any action on upcoming stone(s).',
    //             });
    //           } else {
    //             //setDisclaimer('confirm');
    //             setVisible('confirm');
    //           }
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //       img: require('assets/svg/DiamondSearch/confirm.svg'),
    //     },
    // props.noreminder
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.reminder" />,
    //       key: 'reminder',
    //       img: require('assets/svg/DiamondList/reminder.svg'),
    //       onClick: () => {
    //         if (!isGuest) {
    //           if (isEmpty(checked)) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Please select stone(s) to add in reminder.',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'U')) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Sorry, you can not perform any action on upcoming stone(s).',
    //             });
    //           } else {
    //             setReminder('insert');
    //           }
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //     },
    !props.rejectOffer
      ? null
      : {
          name: 'Reject',
          key: 'rejectoffer',
          onClick: () => {
            isEmpty(checked)
              ? OpenNotification({
                  type: 'error',
                  title: 'Please select stone(stones) to reject offer.',
                })
              : DiamondTrackStatusUpdate(
                  checked.map((x) => x.trackId),
                  { offerStatus: 3 },
                  () => {
                    props.clearAll();
                    props.fetch();
                  },
                );
          },
        },

    // props.nocart
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.Cart" />,
    //       key: 'cart',
    //       onClick: () => {
    //         if (!isGuest) {
    //           if (isEmpty(checked)) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Please select stone(s) to add in cart.',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'U')) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Sorry, you can not perform any action on upcoming stone(s).',
    //             });
    //           } else {
    //             handleInsertTrack(trackTypeObj.CART, checked, (status) => {
    //               props.clearAll();
    //               if (status) props.setCount(!props.deleteReminder.count);
    //             });
    //           }
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //       img: require('assets/svg/DiamondList/cart.svg'),
    //     },
    props.noenquiry
      ? null
      : {
          name: <IntlMessages id="app.my-enquiry" />,
          key: 'enquiry',
          disabled:
            checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
            !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
              const allIds = [
                ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
                ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
              ];

              return allIds.includes(e);
            }),
          onClick: () => {
            if (!isGuest) {
              if (
                window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
                window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
              ) {
                setVisible('enquiry');
              }
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) for enquiry.',
                });
              } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.find((chk) => chk.wSts === 'U')) {
                OpenNotification({
                  type: 'error',
                  title: 'Sorry, you can not perform any action on upcoming stone(s).',
                });
              } else {
                setVisible('enquiry');
              }
            } else {
              setShowGuest(true);
            }
          },
          img: require('assets/svg/enquiry-full.svg'),
        },
    props.nowatch
      ? null
      : {
          name: <IntlMessages id="app.Watch" />,
          key: 'watch',
          onClick: () => {
            if (!isGuest) {
              if (
                window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
                window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
              ) {
                setVisible('watchlist');
              } else if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to add in watch.',
                });
              } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else {
                setVisible('watchlist');
              }
            } else {
              setShowGuest(true);
            }
          },
          img: require('assets/svg/DiamondList/watch.svg'),
        },

    props.noquote
      ? null
      : {
          name: <IntlMessages id="app.Offer" />,
          key: 'quote',
          disabled:
            //isEmpty(props.inBlockDiamonds) &&
            checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
            !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
              const allIds = [
                ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
                ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
              ];

              return allIds.includes(e);
            }),
          onClick: () => {
            if (!isGuest) {
              if (
                window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
                window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
              ) {
                setVisible('quote');
              }
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to apply quote.',
                });
              } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.find((chk) => chk.wSts === 'U')) {
                OpenNotification({
                  type: 'error',
                  title: 'Sorry, you can not perform any action on upcoming stone(s).',
                });
              } else {
                setVisible('quote');
              }
            } else {
              setShowGuest(true);
            }
          },
          img: require('assets/svg/DiamondDetail/offer.png'),
        },

    props.nohold
      ? null
      : {
          name: 'Hold',
          key: 'hold',
          onClick: () => {
            const selectTerm = localStorage.getItem('Terms');
            if (
              window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
              window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
            ) {
              setVisible('hold');
            }
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) for hold.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Sorry, you can not perform any action on upcoming stone(s).',
              });
            } else {
              setVisible('hold');
            }
          },
          img: require('assets/svg/DiamondDetail/reminder.svg'),
        },
    props.nonote
      ? null
      : {
          name: <IntlMessages id="app.comment" />,
          key: 'note',
          onClick: () => {
            if (!isGuest) {
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to add note.',
                });
              } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.find((chk) => chk.wSts === 'U')) {
                OpenNotification({
                  type: 'error',
                  title: 'Sorry, you can not perform any action on upcoming stone(s).',
                });
              } else {
                setVisible('note');
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    props.nonocompare
      ? null
      : props.nocompare
      ? {
          name: (
            <span>
              <IntlMessages id="app.CompareStoneNow" />
              {' (' + props.data.length + ')'}
            </span>
          ),
          key: 'compare',
          img: require('assets/svg/DiamondList/compare.svg'),
          onClick: () => {
            if (!isGuest) {
              localStorage.setItem(`${LOCAL_STORAGE_VAR}-more`, true);
              history.push(JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)));
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Sorry, you can not perform any action on upcoming stone(s).',
              });
            } else {
              setShowGuest(true);
            }
          },
        }
      : {
          name: <IntlMessages id="app.CompareStone" />,
          key: 'compare',
          img: require('assets/svg/DiamondList/compare.svg'),
          onClick: () => {
            if (!isGuest) {
              if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.find((chk) => chk.wSts === 'U') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'Sorry, you can not perform any action on upcoming stone(s).',
                });
              } else {
                compareStones(checked, props.currentType);
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    props.noshare
      ? null
      : {
          name: <IntlMessages id="app.Share" />,
          key: 'share',
          img: require('assets/svg/DiamondList/share.svg'),
          onClick: () => {
            if (!isGuest) {
              if (
                window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
                window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
              ) {
                setVisible('share');
              }
              if (isEmpty(checked)) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to share.',
                });
              } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else {
                setVisible('share');
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    props.nodownload
      ? null
      : {
          name: <IntlMessages id="app.Download" />,
          key: 'download',
          img: require('assets/svg/DiamondList/download.svg'),
          onClick: () => {
            if (!isGuest) {
              if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.length > 0) {
                setVisible('download');
              } else {
                if (props.data.length > 0) {
                  if (path === 'account') {
                    if (module === 'watchlist') {
                      myAccountExcelTrack(2);
                    } else if (module === 'cart') {
                      myAccountExcelTrack(1);
                    } else if (module === 'quote-list') {
                      myAccountExcelTrack(4);
                    } else if (module === 'reminder') {
                      myAccountExcelTrack(5);
                    } else if (module === 'enquiry') {
                      myAccountExcelTrack(6);
                    } else if (module === 'hold') {
                      myHoldExcelTrack(1);
                    } else if (module === 'confirm-list') {
                      myAccountExcelMemo();
                    } else if (module === 'office-list') {
                      myAccountExcelOffice();
                    } else if (module === 'notes') {
                      myAccountNotesExcel();
                    }
                  } else if (path === 'my-bid') {
                    myBidExcel();
                  } else if (path === 'match-pair') {
                    matchPairExcelTrack(window.location.search.split('?')[1]);
                  } else if (path === 'stone-of-the-day') {
                    featureStoneExcelTrack(11);
                  } else if (path === 'compare') {
                    const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                      : false;
                    if (confirmCompare) {
                      //
                      handleDownloadExcel(
                        {},
                        localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                          : [],
                        (status) => {
                          if (status) {
                            //props.clearAll();
                          }
                        },
                        path === 'upcoming' ? true : false,
                        [],
                        confirmCompare,
                      );
                    } else {
                      //
                      handleDownloadExcel(
                        {},
                        localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                          : [],
                        (status) => {
                          if (status) {
                            //props.clearAll();
                          }
                        },
                        path === 'upcoming' ? true : false,
                        [],
                        confirmCompare,
                      );
                    }
                  } else {
                    handleDownloadExcel(
                      props.seachFilterTag ? props.seachFilterTag.searchData : {},
                      checked.map((x) => x.id),
                      () => {},
                    );
                  }
                } else {
                  return OpenNotification({
                    type: 'error',
                    title: 'No Stones Found',
                  });
                }
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    props.noprint
      ? null
      : {
          name: <IntlMessages id="app.PrintList" />,
          key: 'print',
          img: require('assets/svg/DiamondList/print-list.svg'),
          onClick: () => {
            if (!isGuest) {
              if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.length > 0) {
                if (module === 'confirm-list') {
                  handlePrint(
                    {},
                    checked.map((chk) => chk.diamondId),
                  );
                } else if (path === 'compare') {
                  const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                    : false;
                  if (confirmCompare) {
                    handleMemoPrint(
                      {},
                      checked.map((chk) => chk.diamondId),
                    );
                  } else {
                    handlePrint(
                      {},
                      checked.map((chk) => chk.id),
                    );
                  }
                } else {
                  handlePrint(
                    {},
                    checked.map((chk) => chk.id),
                  );
                }
              } else {
                if (props.data.length > 0) {
                  if (path === 'account') {
                    if (module === 'watchlist') {
                      myAccountPDFTrack(2);
                    } else if (module === 'cart') {
                      myAccountPDFTrack(1);
                    } else if (module === 'quote-list') {
                      myAccountPDFTrack(4);
                    } else if (module === 'reminder') {
                      myAccountPDFTrack(5);
                    } else if (module === 'enquiry') {
                      myAccountPDFTrack(6);
                    } else if (module === 'hold') {
                      myHoldPDFTrack(1);
                    } else if (module === 'confirm-list') {
                      myAccountPDFMemo();
                    } else if (module === 'office-list') {
                      myAccountPDFOffice();
                    } else if (module === 'notes') {
                      myAccountNotesPDF();
                    }
                  } else if (path === 'match-pair') {
                    matchPairPDFTrack(window.location.search.split('?')[1]);
                  } else if (path === 'stone-of-the-day') {
                    featureStonePDFTrack(11);
                  } else if (path === 'my-bid') {
                    myBidPDF();
                  } else if (path === 'compare') {
                    const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                      : false;
                    if (confirmCompare) {
                      handleMemoPrint(
                        {},
                        localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                          : [],
                      );
                    } else {
                      handlePrint(
                        {},
                        localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                          : [],
                      );
                    }
                  } else {
                    handlePrint(props.seachFilterTag ? props.seachFilterTag.searchData : {}, {});
                  }
                } else {
                  return OpenNotification({
                    type: 'error',
                    title: 'No Stones Found',
                  });
                }
              }
            } else {
              setShowGuest(true);
            }
          },
        },
    props.sharexray
      ? {
          name: <>Share X-Ray</>,
          key: 'xray',
          onClick: () => {
            if (!isGuest) {
              props.shareXray();
            } else {
              setShowGuest(true);
            }
          },
          img: require('assets/svg/DiamondList/certi.svg'),
        }
      : null,
    props.viewcerti
      ? {
          name: <>Verify Certificate</>,
          key: 'certi',
          onClick: () => {
            if (!isGuest) {
              props.verifyCerti();
              onClose();
            } else {
              setShowGuest(true);
            }
          },
        }
      : null,
    props.downloadzip
      ? {
          name: <>Download Zip</>,
          key: 'zip',
          onClick: () => {
            if (!isGuest) {
              props.downloadZip();
              onClose();
            } else {
              setShowGuest(true);
            }
          },
        }
      : null,
  ].filter((el) => el !== null);

  const noConfirmButton = pathname === LISTINGPAGES.MY_BID || props.fsNow === LISTINGPAGES.MY_BID;

  const lastSomeBtn = 4;
  return (
    <>
      <div className="mobileSearchBottom">
        {props.modify && (
          <div className="mobileSearchActionItem">
            <a onClick={props.onModify}>
              <img alt="" src={require('assets/img/Mobileback.svg')} />
              <span>
                <IntlMessages id="app.modify" />
              </span>
            </a>
          </div>
        )}
        {actions.slice(0, actions.length > 5 ? 4 : 5).map((x) => {
          return (
            <div className="mobileSearchActionItem" key={x.key}>
              <a onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>{x.key === 'calc' ? <IntlMessages id="app.FinalCalc" /> : x.name}</span>
              </a>
            </div>
          );
        })}
        {actions.length > 5 && (
          <div className="mobileSearchActionItem">
            <a onClick={moreSearchDrawer}>
              <img alt="" src={require('assets/svg/DiamondSearch/more.svg')} />
              <span>More</span>
            </a>
          </div>
        )}
      </div>
      {moreSearch && (
        <Drawer onClose={onClose} visible={moreSearch} wrapClassName="mobileSidebar mobileBottomSidebar">
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(4, actions.length).map((x) => {
                return (
                  <li onClick={x.onClick} key={x.key}>
                    <a>{x.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      )}

      <div
        className={
          'botoomStickyBar diamondListingAction' + (props.ActionFalse === false ? ' justify-content-center' : '')
        }
      >
        <div className="d-flex listingAction">
          {props.modify && (
            <button className="bottomStickyButton" onClick={props.onModify}>
              <IntlMessages id="app.modify" />
            </button>
          )}
          {actions
            .slice(0, isTablet ? 3 : actions.length > 7 ? actions.length - lastSomeBtn : actions.length)
            .map((x) => {
              return (
                <a key={x.key} className={`bottomStickyButton ${x.disabled && `disable`}`} onClick={x.onClick}>
                  <span>{x.name}</span>
                </a>
              );
            })}
          {((isTablet && actions.length > 3) || actions.length > 7) && (
            <Dropdown
              isOpen={more}
              toggle={() => {
                setMore(!more);
              }}
              onClick={() => {
                setMore(!more);
              }}
            >
              <DropdownToggle className="bottomStickyButton" caret>
                <span>
                  <IntlMessages id="app.More" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="diamondListBottomDropdown">
                <ul>
                  {actions.slice(isTablet ? 3 : actions.length - lastSomeBtn, actions.length).map((x, i) => {
                    return (
                      <li key={i + 'li'} onClick={x.onClick}>
                        <a>
                          <div className="dropdownBottomItem">
                            <span>{x.name}</span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </Dropdown>
          )}
          {Boolean(props.showStatus) && (
            <>
              <div className="status-display">
                <div className="circle-high"></div>
                <span>Winning</span>
              </div>
              <div className="status-display">
                <div className="circle-equal"></div>
                <span>Equal</span>
              </div>
              <div className="status-display">
                <div className="circle-low"></div>
                <span>Losing</span>
              </div>
            </>
          )}
        </div>
        {props.nopaginate ? null : <Pagination {...props} />}
      </div>
      {visible === 'confirm' && (
        <Drawer onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <ConfirmStonePopup {...props} checked={checked} onClose={onClose} promotionDis={props.promotionDis} />
        </Drawer>
      )}
      {visible === 'quote' || props.editOffer ? (
        <Drawer
          onClose={() => {
            onClose();
            if (props.editOffer) props.fetch();
          }}
          visible={true}
          wrapClassName="diamondListinSidebar offerPopup xl-size"
        >
          <QuotePopup
            quoteDay={pathname === LISTINGPAGES.QUOTE_DAY}
            {...props}
            checked={checked}
            offerDis={props.offerDis}
            onClose={() => {
              onClose();
              if (props.editOffer) props.fetch();
            }}
          />
        </Drawer>
      ) : null}
      <Drawer onClose={onClose} visible={visible === 'bid'} wrapClassName="diamondListinSidebar xl-size" destroyOnClose>
        {visible === 'bid' &&
          ([LISTINGPAGES.DEAL_OF_THE_DAY, LISTINGPAGES.MY_DEAL_OF_THE_DAY].includes(pathname) ? (
            <DealoftheDayPopup {...props} checked={checked} onClose={onClose} />
          ) : (
            <BidPopup {...props} checked={checked} currentType={currentType} onClose={(e) => onClose(e)} />
          ))}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'note'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === 'note' && (
          <NotePopup {...props} checked={checked} onClose={(currentType) => onClose(currentType)} />
        )}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'office'}
        wrapClassName="diamondListinSidebar OfficePopup"
        destroyOnClose
      >
        {visible === 'office' && (
          <OfficeSidebar
            {...props}
            quoteDay={pathname === LISTINGPAGES.QUOTE_DAY}
            checked={checked}
            onClose={onClose}
          />
        )}
      </Drawer>
      {visible === 'finalCalc' && (
        <Drawer onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <FinalCalculations
            {...props}
            checked={checked}
            onClose={onClose}
            noConfirmButton={noConfirmButton}
            promotionDis={props.promotionDis}
          />
        </Drawer>
      )}
      {visible === 'watchlist' && (
        <Drawer onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <WatchlistPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === 'enquiry' && <EnquiryPopup visible={true} checked={checked} handleCancel={onClose} />}
      {visible === 'hold' && (
        <Drawer onClose={onClose} visible={visible === 'hold'} wrapClassName="diamondListinSidebar xl-size">
          <HoldPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === 'share' && (
        <CommonModal modalSize="md-size" title="Share Stone" handleCancel={onClose} visible={true} wrapClassName="">
          <ShareDropdown {...props} checked={checked} onCloseSharePopup={onClose} />
        </CommonModal>
      )}
      {/* {visible === 'share' && isMobile && (
        <Drawer  onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <MobileShareOption {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )} */}
      {visible === 'download' && (
        <CommonModal
          modalSize="md-size"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={true}
        >
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === '/account/confirm-list' ||
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                : false)
                ? true
                : false
            }
            onCloseDownloadPopup={onClose}
          />
        </CommonModal>
      )}
      {/* {visible === 'download' && isMobile && (
        <CommonModal
          modalSize="md-size"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={true}
        >
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === '/account/confirm-list' ||
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                : false)
                ? true
                : false
            }
            onCloseDownloadPopup={onClose}
          />
        </CommonModal>
      )} */}
      {reminder === 'insert' && (
        <Drawer onClose={onClose} visible={true} wrapClassName="diamondListinSidebar">
          <Reminder
            handleCancel={() => {
              setReminder(false);
              //props.clearAll();
            }}
            handleSetReminder={(e) =>
              handleInsertTrack(
                trackTypeObj.REMINDER,
                checked,
                () => {
                  //props.clearAll();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}
      {reminder === 'update' && (
        <Drawer onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <UpdateReminder
            handleCancel={() => {
              setReminder(false);
              //props.clearAll();
            }}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                trackTypeObj.REMINDER,
                checked,
                props.inTrackDiamonds,
                () => {
                  //props.clearAll();
                  props.fetch();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}
      {checked && checked.length ? (
        <CommonModal
          handleCancel={() => setDisclaimer(false)}
          handleOk={() => {
            setVisible(disclaimer);
            setDisclaimer(false);
          }}
          visible={disclaimer}
          modalSize="xs-size"
          footerShow
          title={<IntlMessages id="app.Disclaimer" />}
          submitTitle={<IntlMessages id="app.IAgree" />}
          cancelTitle={<IntlMessages id="app.Quit" />}
        >
          {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).filter((el) => el !== 'INDIA').length ? (
            <p>
              <IntlMessages id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},
              <IntlMessages id="app.isLocatedIn" />
              {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).join('/')}
              <IntlMessages id="app.notAvail" />
              <br />
              <p className="mt-10">
                <IntlMessages id="app.pandemicNotice" />
              </p>
            </p>
          ) : (
            <p>
              <IntlMessages id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},{' '}
              <IntlMessages id="app.deliverIndia" />
            </p>
          )}
        </CommonModal>
      ) : null}
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            window.open('/signup', '_self');
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          handleCancel={() => {
            setShowGuest(false);
          }}
        />
      )}
    </>
  );
};

export default connect(
  (store) => ({
    deleteReminder: store.deleteReminder,
  }),
  { setCount },
)(DiamondListingAction);
