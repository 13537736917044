import { TABLE_VALUES_ACTIONS } from 'appRedux/reducers/tableValues';
import { store } from 'appRedux/store';

export class TableValuesService {
  constructor({ table }) {
    this.table = table;
    this.setValues({});
  }

  get state() {
    return store.getState().tableValues[this.table] ?? {};
  }

  setValues(data) {
    const payload = { table: this.table, data };
    return store.dispatch(TABLE_VALUES_ACTIONS.setValues(payload));
  }

  resetValues() {
    const payload = { table: this.table };
    return store.dispatch(TABLE_VALUES_ACTIONS.resetValues(payload));
  }
}
