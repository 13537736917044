import { Storage } from 'services/Storage';

const initialState = {
  ...(Storage.get('userPermissions') ?? undefined),
};

export const PERMISSION_ACTION_TYPES = {
  SET_PERMISSIONS: '@settings/set-permissions',
  RESET: '@settings/reset',
};

export const PERMISSION_ACTIONS = {
  setPermissions: (payload) => ({ type: PERMISSION_ACTION_TYPES.SET_PERMISSIONS, payload }),
  reset: () => ({ type: PERMISSION_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PERMISSION_ACTION_TYPES.SET_PERMISSIONS: {
      const newState = { ...state, ...payload };
      Storage.set('userPermissions', newState);
      return newState;
    }

    case '@@RESET':
    case PERMISSION_ACTION_TYPES.RESET: {
      Storage.delete('userPermissions');
      return { ...initialState };
    }

    default:
      return state;
  }
};
