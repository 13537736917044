import React from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'util/utils';

export function useUpdatedBidList(currentType) {
  const selectedRows = useSelector((state) => state.diamondData?.selectedRows?.[currentType] ?? []);
  const updatedValues = useSelector((state) => state.tableValues?.[currentType] ?? {});

  return React.useMemo(() => {
    return selectedRows.map((stone) =>
      isEmpty(updatedValues[stone.vStnId]) ? stone : { ...stone, ...updatedValues[stone.vStnId] },
    );
  }, [selectedRows, updatedValues]);
}
