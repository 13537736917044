import { TableConfig } from 'components/DiamondList/table-config';

import { memoize } from 'util/lodash';
import { castToNumber, catchError, formatNumber, isArray, isEmpty, isNotEmpty, isObject } from 'util/utils';

export function formatValues(value, key) {
  return isEmpty(value) || value === 0
    ? '-'
    : TableConfig.FLOAT_COLUMNS.includes(key)
    ? formatNumber(value, 2)
    : TableConfig.ROUND_COLUMNS.includes(key)
    ? formatNumber(Math.round(value))
    : value;
}

export function updateRecord(record, inBlockList = [], options = {}) {
  if (!isObject(record)) return;
  record._original = { ...(record?._original ?? record) };

  const bt2Found =
    isArray(inBlockList) &&
    inBlockList.findIndex((blockRecord) => {
      return record?.id === blockRecord?._id?.diamond && blockRecord?._id?.blockType === 2;
    }) > -1;

  if (record?.isFcCol) {
    record.colNm =
      !isEmpty(record.colNm) && !isEmpty(record.fcColDesc)
        ? [record.colNm, record.fcColDesc].join(', ')
        : record.fcColDesc ?? record.colNm;
  }

  record.lbNm = record?.sSts === 'C' || record?.sStsDesc === 'GIA Result' ? record?.sStsDesc : record?.lbNm;
  record.cutNm = ['NON'].includes(record.cutNm) ? '-' : record.cutNm;
  record.sgiMemo = !options?.inOrder && record?.webSts !== 'I' && record?.wSts === 'M' && !bt2Found;
  record.sgiHold = !options?.inOrder && record?.sSts === 'Y' && record?.wSts === 'H';
  record.bestBuyMemo = record?.wSts === 'D' && record?.sgiSts === 'I';
  record.bestBuyHold = record?.initSts === 'D';

  const output = Object.fromEntries(Object.entries(record).map(([key, value]) => [key, formatValues(value, key)]));

  return output;
}

export function updateList(list, inBlockList = [], options = {}) {
  if (!isArray(list)) return;
  return list.map((record) => updateRecord(record, inBlockList, options));
}

export function filterRecord(record, remove = []) {
  if (!isObject(record)) return;
  record = { ...record, ...record?._original };
  const byUpdater = []; // 'sgiMemo', 'sgiHold', 'bestBuyMemo'
  const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds']; // '_groupBy_'
  const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
  const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...remove];
  keysToDelete.forEach((key) => delete record[key]);
  return record;
}

export function filterList(list, remove = []) {
  if (!isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, remove));
}

export const getStickyStyle = memoize((noStatus = false) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstWidth = noStatus ? 40 : 140;
  const secondWidth = 160;
  const firstLeft = -10;
  const secondLeft = firstWidth + firstLeft - 5;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export function getGroupTitle(record = {}, options = {}) {
  const dateTitle = record?.dateTitle ?? '';
  const country = record?.countryNm ?? '-';
  const salesPerson = record?.seller ?? '-';
  const date = record?.userDate ?? record?.createdAt ?? '-';

  const user = [
    record?.user?.account?.companyName ?? record?.user?.companyName,
    record?.userName,
    record?.userMobile,
    record?.userEmail,
  ]
    .filter(isNotEmpty)
    .join(' | ');

  return options?.user && options?.date
    ? `${dateTitle} ${date} | ${user}`
    : options?.sortStone
    ? record?.vStnId ?? '-'
    : options?.user
    ? user
    : options?.date
    ? `${dateTitle} ${date}`
    : options?.country
    ? country
    : options?.salesPerson
    ? salesPerson
    : options?.stageShow
    ? `${record?.blockCode ?? '-'}`
    : options?.orderShow
    ? `${record?.memoNo ?? '-'}`
    : `${dateTitle} ${date} | ${user}`;
}

export const memoizedGetGroupTitle = memoize(getGroupTitle, (...args) => JSON.stringify(args));

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    options?.dateList && options?.customerList
      ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
      : options?.dateList
      ? columns.filter((el) => el.id !== 'createdAt')
      : options?.customerList
      ? columns.filter((el) => el.id !== 'user')
      : columns;

  if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

  return columns;
};

export function updateRowClassList(accessor, rowId, select = false) {
  return catchError(() => {
    const tableRows = document.querySelectorAll(accessor ? `[table-row="${accessor}${rowId}"]` : `tr[table-row]`);
    if (!isEmpty(tableRows)) for (const row of tableRows) row.classList[select ? 'add' : 'remove']('selectTr');
  });
}

/**
 * freezes table columns
 * @note: When using `React`, call this inside `React.useLayoutEffect` callback with ref to table
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 * @param {HTMLTableElement} table table element
 * @param {Object} options
 */
export function freezeColumns(table, options = {}) {
  options = { index: 5, offset: -0, ignoreClasses: ['groupingTableAmount', 'activebg'], ...options };

  const stickyIndex = options.index;
  const rows = table.querySelectorAll('tr');
  const testRow = table.querySelectorAll('tbody tr')[0] ?? rows[0];
  const cellSlice = Array.prototype.slice.call(testRow.children, 0, stickyIndex);

  const widthList = Array.prototype.map.call(cellSlice, (cell) => cell.getBoundingClientRect().width);
  const leftOffsetList = widthList.map((_, index) => {
    let output = options.offset;
    for (let i = 0; i < index; i++) output = output + widthList[i];
    return output;
  });

  table.style['border-collapse'] = 'separate';
  table.style['border-spacing'] = 0;

  Array.prototype.forEach.call(rows, (row) => {
    const cellList = Array.prototype.slice.call(row.children, 0, stickyIndex);

    Array.prototype.forEach.call(cellList, (cell, index) => {
      if (cell.classList.contains(...options.ignoreClasses)) return;

      cell['data-sticky'] = true;
      cell['data-sticky-first'] = index === 0;
      cell['data-sticky-last'] = index === cellList.length - 1;
      cell['data-orig-z-index'] = castToNumber(cell['data-orig-z-index'] ?? cell.style['z-index']);

      cell.style['position'] = 'sticky';
      cell.style['left'] = `${leftOffsetList[index] - 1}px`;
      cell.style['background'] = cell.tagName === 'TD' ? '#fff' : '';
      cell.style['z-index'] = cell.tagName === 'TD' ? cell['data-orig-z-index'] + 1 : cell['data-orig-z-index'] + 101;
    });
  });
}
