import React from 'react';
import { useSelector } from 'react-redux';

const ConnectedValue = React.memo((props) => {
  const { currentType, record, column, formatter } = props;

  const originalValue = record?.[column];

  const value = useSelector((store) => store?.tableValues?.[currentType]?.[record?.vStnId]?.[column] ?? originalValue);

  return <div>{formatter?.(value) ?? value ?? '-'}</div>;
});
ConnectedValue.displayName = 'ConnectedValue';

export default ConnectedValue;
