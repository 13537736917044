import React from 'react';
import { connect } from 'react-redux';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import Table from 'components/DiamondList/TableBack';
import { HeadingCalc } from 'components/common/DiamondListing/HeadCalc';
import { Heading } from 'components/common/Heading';

import { BidService } from 'services/BidService';

import { IntlMessage } from 'util/IntlMessages';
import { compose, withMobile } from 'util/hocs';
import { notify } from 'util/notify';
import { castToNumber, formatDecimal, formatNumber, isEmpty, parseDecimal, reduceTotal } from 'util/utils';

import { SELECT_STONE_TERMS_NEW } from 'constants/Common';

import BidStoneMobile from './BidStone/BidStoneMobile';
import { newDiamondPrice } from './FinalCalculations';

import { getColumn, getPath, handleBidUpsert, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';

import minusJpg from 'assets/img/minus.jpg';
import plusJpg from 'assets/img/plus.jpg';

const currentType = 'bidStones';

class BidPopup extends React.Component {
  state = {
    columns: [],
    data: [],
    summ:
      getPath() !== LISTINGPAGES.COMPARE
        ? localStorage.getItem('Terms') === 'true'
          ? localStorage.getItem('Termvalue') ?? ''
          : localStorage.getItem('Terms')
        : '',
    summation: {},
    bidConfig: {},
    showModal: false,
  };

  get isAuction() {
    return this.props.bidType === BidService.BID_TYPES.AUCTION;
  }

  get isTender() {
    return this.props.bidType === BidService.BID_TYPES.TENDER;
  }

  get columns() {
    const _columns = getColumn();

    let lbNmIndex = _columns.findIndex((col) => col.id === 'lbNm');
    if (!lbNmIndex) lbNmIndex = 1;

    const columns = [
      ..._columns.slice(0, lbNmIndex + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      _columns.find((col) => col.id === 'back'),
      _columns.find((col) => col.id === 'ctPr'),
      _columns.find((col) => col.id === 'amt'),
      {
        Header: 'Bid Disc(%)',
        accessor: 'bidDiscount',
        id: 'bidDiscount',
        width: 150,
        Cell: ({ row, cell }) => {
          const value = cell.value;

          const allowEdit =
            !isEmpty(row.original.hasBidDiscount) &&
            ['all', 'back'].includes(this.props.bidConfig?.sub_types?.calColumn);

          if (row.original.isFcCol) return '-';

          return (
            <div className="tableInput width-full">
              {allowEdit && (
                <img
                  onClick={() => {
                    const _value = castToNumber(value, 0) - (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                    this.handleDiscountChange(_value, row.original);
                  }}
                  src={minusJpg}
                  width="15px"
                  alt="minus"
                />
              )}
              <input type="text" value={formatDecimal(value)} style={{ textAlign: 'right' }} disabled />
              {allowEdit && (
                <img
                  onClick={() => {
                    const _value = castToNumber(value, 0) + (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                    this.handleDiscountChange(_value, row.original);
                  }}
                  src={plusJpg}
                  width="15px"
                  alt="plus"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        id: 'bidPricePerCarat',
        width: 150,
        Cell: ({ row, cell }) => {
          const value = cell.value;

          const allowEdit =
            !isEmpty(row.original.hasBidDiscount) &&
            ['all', 'back', 'ctPr'].includes(this.props.bidConfig?.sub_types?.calColumn);

          const countOnDis =
            !isEmpty(row.original.hasBidDiscount) &&
            ['all', 'back'].includes(this.props.bidConfig?.sub_types?.calColumn);

          return (
            <div className="tableInput">
              {allowEdit && (
                <img
                  onClick={() => {
                    if (countOnDis) {
                      const _value =
                        castToNumber(row.original.bidDiscount, 0) -
                        (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                      return this.handleDiscountChange(_value, row.original);
                    }

                    const _value = castToNumber(value, 0) - (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                    this.handlePriceCtChange(_value, row.original);
                  }}
                  src={minusJpg}
                  width="15px"
                  alt="minus"
                />
              )}
              <input type="text" value={formatDecimal(value)} style={{ textAlign: 'right' }} disabled />
              {allowEdit && (
                <img
                  onClick={() => {
                    if (countOnDis) {
                      const _value =
                        castToNumber(row.original.bidDiscount, 0) +
                        (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                      return this.handleDiscountChange(_value, row.original);
                    }

                    const _value = castToNumber(value, 0) + (this.props.bidConfig?.sub_types?.diffInterval ?? 0.1);
                    this.handlePriceCtChange(_value, row.original);
                  }}
                  src={plusJpg}
                  width="15px"
                  alt="plus"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Bid Value',
        accessor: 'bidAmount',
        id: 'bidAmount',
        width: 100,
        Cell: ({ cell }) => {
          return (
            <div className="tableInput">
              <input
                type="text"
                value={formatDecimal(cell.value, '-')}
                style={{ textAlign: 'right', width: '100%' }}
                disabled
              />
            </div>
          );
        },
      },
      ..._columns.slice(lbNmIndex + 1, _columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ].filter(Boolean);

    if (this.isAuction) {
      const bidAmtIndex = columns.findIndex((col) => col.id === 'bidAmount');

      columns.splice(
        bidAmtIndex + 1,
        0,
        {
          Header: 'Max Bid Disc%',
          accessor: 'maxBidDisc',
          id: 'maxBidDisc',
          Cell: ({ cell }) => formatDecimal(cell.value) ?? '-',
        },
        {
          Header: 'Max Bid Price/Ct',
          accessor: 'maxBidCtpr',
          id: 'maxBidCtpr',
          Cell: ({ cell }) => formatDecimal(cell.value),
        },
        {
          Header: 'Max Bid Amount',
          accessor: 'maxBidAmt',
          id: 'maxBidAmt',
          Cell: ({ cell }) => formatDecimal(cell.value),
        },
      );
    }

    return columns;
  }

  get data() {
    return this.props.checked.map((stone) => {
      stone = {
        ...stone,

        bidDiscount: castToNumber(stone.bidDiscount ?? stone.back, 0),
        hasBidDiscount: !isEmpty(castToNumber(stone.bidDiscount ?? stone.back)),

        bidPricePerCarat: castToNumber(stone.bidPricePerCarat ?? stone.ctPr, 0),
        hasBidPricePerCarat: !isEmpty(castToNumber(stone.bidPricePerCarat ?? stone.ctPr)),

        bidAmount: castToNumber(stone.bidAmount ?? stone.amt, 0),
        hasBidPrAmount: !isEmpty(castToNumber(stone.bidAmount ?? stone.amt)),
      };

      stone = newDiamondPrice(stone, 'bid');

      stone =
        getPath() !== LISTINGPAGES.MY_BID
          ? {
              ...stone,
              bidDiscount:
                stone.ctPr < stone.rap
                  ? -1 * Math.abs(castToNumber(stone.bidDiscount, 0))
                  : Math.abs(castToNumber(stone.bidDiscount, 0)),
            }
          : {
              ...stone,
              bidDiscount:
                castToNumber(stone.bidDiscount, 0) < 0
                  ? -1 * Math.abs(castToNumber(stone.bidDiscount, 0))
                  : Math.abs(castToNumber(stone.bidDiscount, 0)),
            };

      return stone;
    });
  }

  get disableBidStone() {
    return this.data.some((item) => {
      const back =
        item.ctPr < item.rap ? -1 * Math.abs(castToNumber(item.back, 0)) : Math.abs(castToNumber(item.back, 0));
      return back === item.bidDiscount;
    });
  }

  get valueList() {
    return this.props.isMobile
      ? {
          ['TOTAL CARAT']: formatDecimal(this.state.summation.totalCarats),
          ['BID AVG DISCOUNT(%)']: formatNumber(this.state.summation.AvgDisc, 2),
          ['AVG. DISCOUNT(%)']: formatDecimal(this.state.summation.avgDisc),
          ['BID PRICE/CARAT']: formatNumber(this.state.summation.BidPerCarat, 2),
          ['PRICE/CARAT']: formatDecimal(this.state.summation.perCarat),
          ['BID AMOUNT']: formatNumber(this.state.summation.netValue, 2),
          ['AMOUNT']: formatNumber(this.state.summation.amt),
        }
      : [
          { title: 'Total Carats', value: formatDecimal(this.state.summation.totalCarats) },
          { title: 'Bid Avg Discount(%)', value: formatDecimal(this.state.summation.AvgDisc) },
          { title: 'Avg. Discount(%)', value: formatDecimal(this.state.summation.avgDisc) },
          { title: 'Bid Per/Carat', value: formatDecimal(this.state.summation.BidPerCarat) },
          { title: 'Per/Carat', value: formatDecimal(this.state.summation.perCarat) },
          { title: 'Bid Amount', value: formatDecimal(this.state.summation.netValue) },
          { title: 'Amount', value: formatDecimal(this.state.summation.amt) },
        ];
  }

  handleCalculation = (list) => {
    const stoneList = list ?? this.state.data?.filter?.((stone) => this.props.selectedRowIds.includes(stone.id)) ?? [];

    const calc = {};
    stoneList.map((d) => {
      d.rap_avg = Number.isNaN(d.rap * d.crt) ? 0 : d.rap * d.crt;
      return d;
    });
    const totalRapAvg = reduceTotal(stoneList, 'rap_avg');
    calc.pieces = stoneList.length;
    const avgPricePerCarat = reduceTotal(stoneList, 'bidAmount') / reduceTotal(stoneList, 'crt');
    calc.totalCarats = reduceTotal(stoneList, 'crt');
    calc.avgDisc = totalRapAvg ? (1 - reduceTotal(stoneList, 'amt') / reduceTotal(stoneList, 'rap_avg')) * -100 : 0;
    calc.AvgDisc = totalRapAvg
      ? (1 - avgPricePerCarat / (reduceTotal(stoneList, 'rap_avg') / calc.totalCarats)) * -100
      : 0;
    calc.finalNetValue = reduceTotal(stoneList, 'bidAmount');
    //calc.finalNetRate = ((100 - calc.finalAvgDisc) * reduceTotal(map(diams, 'rap'))) / 100;
    calc.finalNetRate = reduceTotal(stoneList, 'bidAmount') / reduceTotal(stoneList, 'crt');
    calc.netValue = reduceTotal(stoneList, 'bidAmount');
    calc.perCarat = reduceTotal(stoneList, 'amt') / calc.totalCarats;
    calc.BidPerCarat = reduceTotal(stoneList, 'bidAmount') / calc.totalCarats;
    calc.amt = reduceTotal(stoneList, 'amt');
    calc.addDis = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    calc.finalAvgDisc = totalRapAvg
      ? (1 -
          reduceTotal(stoneList, 'bidAmount') /
            reduceTotal(stoneList, 'crt') /
            (reduceTotal(stoneList, 'rap_avg') / calc.totalCarats)) *
        -100
      : 0;

    if (this.state.summ) {
      const totalRapAvg = reduceTotal(stoneList, 'rap_avg');
      const options = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
      const discCalc = options.minAmt
        ? castToNumber(calc.amt, 0) >= options.minAmt
          ? options.value
          : options.resetDis
        : options.value;
      const termDis = discCalc;

      const avg = totalRapAvg ? (1 - calc.finalNetValue / reduceTotal(stoneList, 'rap_avg')) * -100 : 0;
      const final = calc.finalNetRate + calc.finalNetRate * (parseInt(termDis) / 100);
      const rap = totalRapAvg ? reduceTotal(stoneList, 'rap_avg') / calc.totalCarats : 0;
      //let final =  calc.finalNetValue + (calc.finalNetValue *(parseInt(termDis)));
      const amount = calc.finalNetValue + calc.finalNetValue * (parseInt(termDis) / 100);
      calc.BidPerCarat = reduceTotal(stoneList, 'bidAmount') / calc.totalCarats;
      calc.perCarat = reduceTotal(stoneList, 'amt') / calc.totalCarats;
      calc.addDis = termDis;
      calc.AvgDisc = totalRapAvg ? avg : 0;
      calc.finalNetValue = amount;
      calc.finalNetRate = final;
      calc.finalAvgDisc = totalRapAvg ? (1 - castToNumber(final, 0) / rap) * -100 : 0;
    }
    this.setState({ summation: calc });
  };

  handleDiscountChange = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.vStnId === row.vStnId);
    data[index] = { ...data[index] };

    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) || 0 : -1 * Math.abs(data[index].back) || 0;
    data[index].back = back;

    const upInterval = this.props.bidConfig?.sub_types?.upwardInterval;
    const downInterval = this.props.bidConfig?.sub_types?.downwardInterval;
    const max = back + upInterval;
    const min = back - downInterval;

    notify.destroy();

    if (this.props.auction && value < data[index].maxDiscount) {
      notify.error({
        message: `Your bid must be greater than ${parseDecimal(data[index].maxDiscount)}`,
      });
      return;
    }

    if (value < min) {
      notify.error({
        message: `Your bidding discount cannot be lower then ${parseDecimal(min)}%. Please, try again.`,
      });
      return;
    }

    if (value > max) {
      notify.error({
        message: `Your bidding discount cannot be higher then ${parseDecimal(max)}%. Please, try again.`,
      });
      return;
    }

    data[index].bidDiscount = parseDecimal(value);
    const bidPricePerCarat = parseDecimal(data[index].rap + data[index].rap * (value / 100));
    data[index].bidPricePerCarat = parseDecimal(bidPricePerCarat);
    data[index].bidAmount = parseDecimal(bidPricePerCarat * data[index].crt);

    this.setState({ data });
  };

  handlePriceCtChange = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.vStnId === row.vStnId);
    data[index] = { ...data[index] };

    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    data[index].back = back;

    const upInterval = this.props.bidConfig?.sub_types?.upwardInterval;
    const downInterval = this.props.bidConfig?.sub_types?.downwardInterval;
    const max = data[index].ctPr + upInterval;
    const min = data[index].ctPr - downInterval;

    notify.destroy();

    if (this.props.auction && value < data[index].maxPricePerCarat) {
      notify.error({
        message: `Your bid must be greater than ${parseDecimal(data[index].maxPricePerCarat)}`,
      });
      return;
    }

    if (value < min) {
      notify.error({
        message: `Your bidding amount cannot be lower then ${parseDecimal(min)}. Please, try again.`,
      });
      return;
    }

    if (value > max) {
      notify.error({
        message: `Your bidding amount cannot be higher then ${parseDecimal(max)}. Please, try again.`,
      });
      return;
    }

    data[index].bidPricePerCarat = parseDecimal(value);
    data[index].bidAmount = parseDecimal((value || 0) * data[index].crt);
    data[index].bidDiscount = !data[index]?.hasBidDiscount ? 0 : parseDecimal((1 - value / data[index].rap) * -100);

    this.setState({ data });
  };

  handleSubmit = async () => {
    if (isEmpty(this.props.selectedRowIds)) {
      notify.error({ message: 'Please select stone(s) to bid.' });
      return;
    }

    const list = this.state.data?.filter?.((stone) => this.props.selectedRowIds.includes(stone.id)) ?? [];
    await handleBidUpsert(list, this.props.bidConfig);
    void this.props?.onClose?.();
  };

  async componentDidMount() {
    this.setState(
      { columns: this.columns, data: this.data, oldData: this.data, showModal: this.disableBidStone },
      () => {
        this.handleCalculation();
      },
    );
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.selectedRowIds !== this.props.selectedRowIds || nextState.data !== this.state.data) {
      this.handleCalculation();
    }
  }

  render() {
    localStorage.setItem('TermsDis', this.state.summation.addDis);
    localStorage.setItem(
      'termObj',
      SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ),
    );

    if (this.props.isMobile) {
      return (
        <BidStoneMobile
          {...this.state}
          currentType={currentType}
          onClose={this.props.onClose}
          VALUES={this.valueList}
          parent={this}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20">
            <IntlMessage id="app.bidStone" />
          </Heading>
          {HeadingCalc(
            this.state.data?.filter?.((stone) => this.props.selectedRowIds.includes(stone.id)) ?? [],
            null,
            true,
          )}
        </div>
        <div className="searchPopupCommonTable">
          <GridHeightContainer className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              currentType={currentType}
              loading={this.state.loading}
              columns={this.state.columns}
              data={this.state.data}
              bidStatus={this.props.bidConfig?.sub_types?.subTypeCode}
              FilterOption={false}
              canSort={false}
              areAllChecked
              noSticky
              nodots
              noGrp
            />
          </GridHeightContainer>
          <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {this.valueList.map((value) => {
                return (
                  <div className="DiamondDetailPopupItem" key={value.title}>
                    <span>{value.title && `${value.title} :`} </span>
                    <span>
                      {value.title === 'Bid Term Discount(%)' ||
                      value.title === 'Avg. Discount(%)' ||
                      value.title === 'TERMS'
                        ? value.value > 0
                          ? `+${value.value}`
                          : value.value
                        : value.value}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sideBarPopupButton">
            <a
              id="bid-stone"
              className="commonButton"
              onClick={() => {
                const stoneList =
                  this.state.data?.filter?.((stone) => this.props.selectedRowIds.includes(stone.id)) ?? [];

                const hasStonesWithSameValues = stoneList.some(
                  (stone) =>
                    castToNumber(stone.bidDiscount, 0) === castToNumber(stone.back, 0) ||
                    castToNumber(stone.bidPricePerCarat, 0) === castToNumber(stone.ctPr, 0),
                );

                if (hasStonesWithSameValues) {
                  notify.error({ message: 'Bid discount and discount can not be same for bid.' });
                  return;
                }

                this.handleSubmit();
              }}
            >
              <IntlMessage id="app.bidStone" />
            </a>
            <a className="commonButton" onClick={this.props.onClose}>
              <IntlMessage id="app.CancelBid" />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  connect((store) => ({
    bidConfig: store.bidConfig?.data,
    bidType: store.bidConfig?.data?.type,
    selectedRows: store.diamondData.selectedRows?.[currentType] ?? [],
    selectedRowIds: store.diamondData.selectedRowIds?.[currentType] ?? [],
  })),
  withMobile,
)(BidPopup);
