import React, { Component } from 'react';

import { BidDrawerCalc, BidDrawerHeaderCalc } from './BidStone/BidDrawerCalc';
import { HeadingCalc } from './ConfirmStonePopup';
import { ListingCards } from './MobileComfirmStone';

import IntlMessage from '../../../util/IntlMessages';
import { getPath } from '../../DiamondList/DiamondListFunctions';
import Heading from '../Heading';

function BIDStoneMobile(props) {
  const { VALUES, checked, currentType, data, onClose, parent } = props;

  return (
    <div className="offerWrapper">
      <div className="d-flex align-items-center justify-space-between offerTopBlock">
        <div className="d-flex align-items-center">
          <Heading className="popupInnerTitle" title={<IntlMessage id="app.bidStone" />}></Heading>
        </div>
        {HeadingCalc({ display: 'flex', width: '48%' }, VALUES)}
      </div>
      <div className="searchInnerResult tabInnerTable">
        <div className="diamondListMobile mobileOfferScroll">
          <ListingCards bidPopup={parent} checked={checked} data={data} currentType={currentType} areAllChecked />
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
      </div>
      {(getPath() === 'deal-of-the-day' || getPath() === 'my-deal-of-the-day') && (
        <div style={{ display: 'flex' }}>
          <span>{this.props.parent.getAvgDisc()}</span>
          <span>{this.props.parent.getPrCt()}</span>
          <span>{this.props.parent.getAmount()}</span>
        </div>
      )}
      <div className="sideBarPopupButton">
        <a className="commonButton" onClick={parent.submit}>
          <IntlMessage id="app.bidStone" />
        </a>
        <a className="commonButton" onClick={onClose}>
          <IntlMessage id="app.CancelBid" />
        </a>
      </div>
    </div>
  );
}

export default BIDStoneMobile;
