import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Drawer } from 'antd';
import moment from 'moment';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import { BidCountdown } from 'components/Bid/BidCountdown';
import { BidEndModal } from 'components/Bid/BidEndModal';
import Table from 'components/DiamondList/TableBack';
import { emptyData, getState } from 'components/DiamondList/diamondListUtils';
import BidTermsConditions from 'components/NewArrivalBidIt/BidTermsConditions';
import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import { ListingCards } from 'components/common/DiamondListing/MobileComfirmStone';
import SelectStone from 'components/common/DiamondListing/SelectStone';
import { Heading } from 'components/common/Heading';

import { BidService } from 'services/BidService';
import { RowSelectService } from 'services/RowSelectService';
import { Storage } from 'services/Storage';

import IntlMessages from 'util/IntlMessages';
import { useCompositeState, useIsMobile, useLoading, usePathname } from 'util/hooks';
import { classNames, catchError, isEmpty, formatDecimal, logWarn, isNumber } from 'util/utils';

import { DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from 'constants/Common';

import { isArrayEqual } from '../DiamondList';
import { getColumn, TITLE, fetchDiamondPaginate, LISTINGPAGES } from '../DiamondList/DiamondListFunctions';

function NewArrivalBidIt() {
  const P = React.useMemo(() => Storage.get(`userPermissions`), []);

  const location = useLocation();
  const history = useHistory();

  const pathname = usePathname();
  const currentType = pathname;

  const [state, setState] = useCompositeState(() => getState());
  const sortRef = React.useRef([]);
  const [terms, showTerm] = React.useState(false);

  const [isMobile] = useIsMobile();

  const bidLoading = useSelector((state) => state.bidConfig?.loading);
  const timeLoading = useSelector((store) => store?.serverTime?.loading);

  const bidConfig = useSelector((state) => state.bidConfig?.data);
  const bidIsActive = !isEmpty(bidConfig);
  const bidStatus = useSelector((state) => state.bidConfig?.data?.sub_types?.allowDiamondFilterWSts);
  const bidType = useSelector((state) => state.bidConfig?.data?.type);

  const [loading, startLoading, stopLoading] = useLoading();

  React.useEffect(() => {
    if (!bidIsActive) BidService.fetchRunningBid();
  }, [bidIsActive]);

  const columns = React.useMemo(() => {
    if (!bidIsActive) return [];

    const columns = getColumn(state.page);

    if (bidType === BidService.BID_TYPES.AUCTION) {
      const amtIndex = columns.findIndex((col) => col.id === 'amt');

      columns.splice(
        amtIndex + 1,
        0,
        {
          Header: 'Max Bid Disc%',
          accessor: 'maxBidDisc',
          id: 'maxBidDisc',
          Cell: ({ cell }) => formatDecimal(cell.value) ?? '-',
        },
        {
          Header: 'Max Bid Price/Ct',
          accessor: 'maxBidCtpr',
          id: 'maxBidCtpr',
          Cell: ({ cell }) => formatDecimal(cell.value),
        },
        {
          Header: 'Max Bid Amount',
          accessor: 'maxBidAmt',
          id: 'maxBidAmt',
          Cell: ({ cell }) => formatDecimal(cell.value),
        },
      );
    }

    return columns;
  }, [bidIsActive, bidType, state.page]);

  const setResponse = React.useCallback(
    (res) => {
      startLoading();

      if (res) {
        const diamondData = res.data ?? [];
        let defaultChecked = [];
        if (Storage.get(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked =
            catchError(
              () => JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)),
              () => [],
            ) ?? [];

          if (!isEmpty(defaultChecked)) {
            diamondData.forEach((stone) => {
              if (defaultChecked.includes(stone.id)) stone['isDefaultChecked'] = true;
            });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }
        history.replace(`${window.location.pathname}?${res.seachFilterTag.id}`);
        setState({ ...res, data: diamondData, defaultChecked });
      } else {
        setState({ defaultChecked: [] });
      }

      stopLoading();
    },
    [history, setState, startLoading, stopLoading],
  );

  const fetch = React.useCallback(async () => {
    if (!bidIsActive) return;
    startLoading();
    setState({ data: [] });
    const payload = { page: state?.page, limit: state?.limit, sort: sortRef.current };

    try {
      await fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse, {
        diamondSearchId: location.search.split('?')[1],
        configId: bidConfig?.id,
        wSts: bidStatus,
      });
    } catch (error) {
      logWarn(error);
    }

    stopLoading();
  }, [
    bidIsActive,
    bidConfig?.id,
    bidStatus,
    location.search,
    setResponse,
    setState,
    startLoading,
    state?.limit,
    state?.page,
    stopLoading,
  ]);

  const handleSort = React.useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const clearAllSelectedRows = React.useCallback(() => {
    RowSelectService.resetSelectedRows(currentType);

    catchError(() => {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) {
        row.classList.remove('selectTr');
      }
    });
  }, [currentType]);

  const onPaginationChange = React.useCallback((page, limit) => setState({ page, limit }), [setState]);

  const title = React.useMemo(() => {
    return (
      <React.Fragment>
        {TITLE.BID ?? 'Bid System'}
        {!(loading || bidLoading) && isNumber(state.count) ? ` (${state.count})` : ''}
      </React.Fragment>
    );
  }, [bidLoading, loading, state.count]);

  const selection = React.useMemo(() => {
    return (
      <SelectStone sum={state.sum} profilListing="listingSelected diamondListStone bid_it" currentType={currentType} />
    );
  }, [currentType, state.sum]);

  const timer = React.useMemo(() => {
    return (
      <div className={classNames(['bidEndBlock', !isMobile && 'd-flex align-items-center'])}>
        <span className="bidEndLabel">
          <IntlMessages id="app.BidEndIn" /> :
        </span>
        <BidCountdown />
      </div>
    );
  }, [isMobile]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        {isMobile ? (
          <div className="listingTopBlock width-100">
            <div className="d-flex justify-content-between align-items-center width-100">
              <Heading title={title} />
            </div>
            {bidIsActive && selection}
          </div>
        ) : (
          <div
            className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap"
            style={{ minHeight: '60px' }}
          >
            <Heading title={title} />
            {bidIsActive && !isEmpty(state.data) && <div className="d-flex align-items-center">{timer}</div>}
            {bidIsActive && selection}
          </div>
        )}
      </div>
      {isMobile && timer}
      <div className="searchInnerResult">
        {isMobile ? (
          <div className="diamondListMobile">
            <ListingCards data={state.data} currentType={currentType} />
            {emptyData(loading, state.data.length)}
          </div>
        ) : (
          <GridHeightContainer
            className="searchResultTable"
            subtractFrom=".HeaderSticky,.selectStonHeader,.accountTopBlock,.accountTabBox,.botoomStickyBar"
          >
            {!loading && (
              <Table
                data={state.data}
                loading={loading || bidLoading}
                columns={columns}
                handleSort={handleSort}
                currentType={currentType}
                defaultChecked={state.defaultChecked}
                bidStatus={bidConfig?.sub_types?.subTypeCode}
                bidStatusTooltip={bidConfig?.sub_types?.tooltip}
              />
            )}
            {!(loading || bidLoading || timeLoading) && (
              <React.Fragment>
                {!bidIsActive ? (
                  <div className="empty-data">
                    <h3>Currently DBS is Inactive, Kindly visit again. You will be notified when DBS is Active.</h3>
                    <Link to="/my-bid">Go to My Bid</Link>
                  </div>
                ) : isEmpty(state.data) ? (
                  <div className="empty-data">
                    <h3>No Diamonds Found.</h3>
                  </div>
                ) : null}
              </React.Fragment>
            )}
          </GridHeightContainer>
        )}
      </div>
      <BidEndModal />
      {bidIsActive && !isEmpty(state.data) && (
        <DiamondListingAction
          {...state}
          bid={bidConfig}
          currentType={currentType}
          onPaginationChange={onPaginationChange}
          clearAll={clearAllSelectedRows}
          fetch={fetch}
          dealOfTheDay={P?.BID?.view}
          createBidCartStone
          myBidCartStone
          nocart
          nowatch
          myBid
          noreminder
          noquote
          noconfirm={bidConfig?.sub_types?.subTypeCode !== 'BIWI'}
          nonote
          nohold={!P?.HOLD?.view || pathname === LISTINGPAGES.BID}
          nofinalcalc
          nonocompare={!P?.COMPARE?.view}
          noenquiry
          //nooffice={getBidType() === DIAMOND_BID.TYPE.BLIND}
          noshare={!P?.SHARE_VIA_MAIL?.view || !P?.SHARE_VIA_WHATSAPP?.view || !P?.SHARE_VIA_SKYPE?.view}
          nodownload={!P?.DOWNLOAD?.view}
          noprint={!P?.PRINT?.view}
          modify
          onModify={() => {
            const id = window.location.search.split('?')[1];
            id
              ? history.push(`/${LISTINGPAGES.SEARCH_DIAMOND}/${id}`)
              : history.push({ pathname: `/${LISTINGPAGES.SEARCH_DIAMOND}`, state: { isActive: true } });
          }}
        />
      )}
      {bidIsActive && (
        <Drawer
          onClose={() => showTerm(false)}
          visible={terms && !Storage.get('bid-doc')}
          afterVisibleChange={(visible) => !visible && Storage.set('bid-doc', true)}
          wrapClassName="diamondListinSidebar sm-size"
          destroyOnClose
        >
          {terms && <BidTermsConditions bidConfig={bidConfig} />}
        </Drawer>
      )}
    </div>
  );
}

export default NewArrivalBidIt;

function getBidStartTime(bidType) {
  return bidType === DIAMOND_BID.TYPE.OPEN
    ? moment().startOf('day').add(11, 'hours')
    : moment().startOf('day').add(15, 'hours');
}

function getBidEndTime(bidType) {
  return bidType === DIAMOND_BID.TYPE.OPEN
    ? moment().startOf('day').add(14, 'hours').add(59, 'minutes')
    : moment().startOf('day').add(10, 'hours').add(59, 'minutes');
}

export function getBidType() {
  return moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
    ? DIAMOND_BID.TYPE.OPEN
    : moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
    ? DIAMOND_BID.TYPE.BLIND
    : 0;
}
