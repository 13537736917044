import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Popover } from 'antd';
import { filter, get, isEqual, join, map, split, xorWith } from 'lodash';
import invert from 'lodash/invert';

import { SEARCH_PAGES } from 'containers/APIFunctions';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import FilterTop from 'components/common/DiamondListing/FilterTop';
import ListingTop from 'components/common/DiamondListing/ListingTop';
import SelectStone from 'components/common/DiamondListing/SelectStone';
import { Heading } from 'components/common/Heading';

import { RowSelectService } from 'services/RowSelectService';
import { SearchService } from 'services/SearchService';
import { Storage } from 'services/Storage';

import IntlMessage from 'util/IntlMessages';
import { useCompositeState, useCurrentType, useIsMobile, usePathname } from 'util/hooks';
import { isEmpty } from 'util/utils';

import {
  trackTypeObj,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  SEARCH_TAGS,
  MULTIPLE_SEARCH,
  PROJECT_SETTINGS_TYPE,
  PAGE_TITLE,
} from 'constants/Common';

import DiamondGridView from './DiamondGrid';
import {
  fetchDiamondList,
  fetchDiamondPaginate,
  getPath,
  LISTINGPAGES,
  fetchCart,
  fetchWatch,
  TITLE,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  fetchNotes,
  fetchEnquiries,
  getProjectSetting,
  memoizedGetColumn,
  responseUglifier,
  IntlTITLE,
} from './DiamondListFunctions';
import MasterTags from './MasterTags';
import MobileViewList from './MobileViewList';
import Table from './TableBack';
import { emptyData, getGridHeight, getState } from './diamondListUtils';

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
const exclusiveTitle = '';

const DiamondList = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isMobile] = useIsMobile();
  const pathname = usePathname();
  const currentType = useCurrentType();

  const [state, setState, resetState] = useCompositeState(() => getState({ sort: [] }));
  const [listView, setListView] = React.useState(true);
  const [ids, setIds] = React.useState([]);

  const isAccount = pathname.startsWith('account');
  const sortRef = React.useRef([{ back: 'DESC' }]);

  // DisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDisDis
  const [promotionDis, setPromotionDis] = React.useState({});
  const [offerDis, setOfferDis] = React.useState({});

  const hasSelectedRows = useSelector(
    (state) => Boolean(state.diamondData.selectedRows?.[location.pathname]?.length) ?? [],
  );

  const USER_PERMIT = Storage.get(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const { onSumChange } = props;

  React.useEffect(() => {
    if (isAccount && !isMobile) void onSumChange?.(state.sum, currentType);
  }, [currentType, isAccount, isMobile, onSumChange, state.sum]);

  React.useEffect(() => {
    getProjectSetting(PROJECT_SETTINGS_TYPE.PROMOTION_DISCOUNT, setPromotionDis);
    getProjectSetting(PROJECT_SETTINGS_TYPE.OFFER_DISCOUNT, setOfferDis);
  }, []);

  React.useEffect(() => {
    resetState();
  }, [isAccount, resetState]);

  React.useEffect(() => {
    const searchParam = location.search.substring(1)?.split('&')[0];
    setIds(split(searchParam, ',').map((id, index) => ({ id, isActive: index === 0 })));

    if (!searchParam && getPath() === LISTINGPAGES.LIST) history.push('/' + LISTINGPAGES.DASHBOARD);
  }, [history, location.search]);

  const setResponse = React.useCallback(
    (res) => {
      const setResponse_ = async () => {
        const columns = await memoizedGetColumn();

        if (res) {
          setState({ loading: true });

          // CHECK THIS
          const diamondData = res?.rows ?? res?.data ?? [];
          let defaultChecked = [];
          if (Storage.get(`${LOCAL_STORAGE_VAR}-more`)) {
            defaultChecked = Storage.get(`${LOCAL_STORAGE_VAR}-compare-diamond`)
              ? JSON.parse(Storage.get(`${LOCAL_STORAGE_VAR}-compare-diamond`))
              : [];

            if (defaultChecked && defaultChecked.length > 0) {
              diamondData.map((d) => {
                d['isDefaultChecked'] = defaultChecked.includes(
                  JSON.parse(Storage.get(`${LOCAL_STORAGE_VAR}-now`)) === '/account/confirm-list' ? d.diamondId : d.id,
                );

                return d;
              });
            }

            Storage.delete(`${LOCAL_STORAGE_VAR}-more`);
          }
          setState({ ...res, data: diamondData, defaultChecked, columns });
          setState({ loading: false });
        } else {
          setState(() => ({ loading: false, defaultChecked: [] }));
        }
      };

      setResponse_();
    },
    [setState],
  );

  const fetch = React.useCallback(async () => {
    setState(() => ({ loading: true, data: [] }));
    const currentId = ids.find((id) => !!id.isActive);

    const payload = {
      sort: sortRef.current,
      date: state.date,
      limit: state.limit,
      page: state.page,
    };

    if (getPath() === LISTINGPAGES.LIST) {
      if (currentId && currentId?.id) {
        fetchDiamondList(payload, [{ diamondSearchId: currentId?.id }], setResponse);
      }
    } else if (getPath() === LISTINGPAGES.EXCLUSIVE) {
      if (currentId && currentId.id) {
        fetchDiamondList(payload, [{ diamondSearchId: currentId.id }], setResponse);
      }
    } else if (getPath() === LISTINGPAGES.CART) {
      fetchCart(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.WATCH_LIST) {
      fetchWatch(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.REMINDER) {
      fetchReminder(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.CONFIRM) {
      fetchConfirmStone(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.STONE_OF_DAY) {
      fetchStoneOfDay(payload, [{ sectionType: 11 }], setResponse);
    } else if (getPath() === LISTINGPAGES.NEW_ARRIVALS) {
      fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
    } else if (getPath() === LISTINGPAGES.MATCH_PAIR) {
      fetchMatchPair(payload, window.location.search.split('?')[1], setResponse);
    } else if (getPath() === LISTINGPAGES.SPECIAL_STONE) {
      const [, res] = await SearchService.getSpecialStones(payload);
      setResponse(responseUglifier(res, DIAMOND_WEB_STATUS.EXCLUSIVE));
    } else if (getPath() === LISTINGPAGES.NOTE) {
      fetchNotes(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.SUGGESTED_STOCK) {
      fetchDiamondList(payload, [{ sectionType: 14 }], setResponse);
    } else if (getPath() === LISTINGPAGES.ENQUIRY) {
      fetchEnquiries(payload, setResponse);
    }
  }, [ids, setResponse, setState, state.date, state.limit, state.page]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const clearAllSelectedRows = React.useCallback(() => {
    RowSelectService.resetSelectedRows(currentType);
  }, [currentType]);

  const handleSort = React.useCallback(
    (currentSort) => {
      if (
        currentSort.length !== sortRef.current.length ||
        JSON.stringify(currentSort) !== JSON.stringify(sortRef.current)
      ) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const selection = React.useMemo(() => {
    if (isAccount && !isMobile) return null;

    return (
      <SelectStone
        sum={state.sum}
        currentType={currentType}
        profilListing={
          isAccount
            ? 'profileStonSelect'
            : getPath() === LISTINGPAGES.QUOTE_DAY
            ? 'listingSelected'
            : 'diamondListStone'
        }
        promotionDis={promotionDis}
      />
    );
  }, [currentType, isAccount, isMobile, promotionDis, state.sum]);

  const listingTop = React.useMemo(() => {
    return (
      <ListingTop
        listGridIcon={location.pathname.split('/')[1] !== 'account' && getPath() !== LISTINGPAGES.QUOTE_DAY}
        timer={getPath() === LISTINGPAGES.QUOTE_DAY ? true : false}
        Cart={getPath() === LISTINGPAGES.CART}
        onClick={() => setListView((v) => !v)}
        image={listView ? require('assets/svg/DiamondList/grid.svg') : require('assets/svg/DiamondList/list.svg')}
      />
    );
  }, [listView, location.pathname]);

  const onPaginationChange = React.useCallback((page, limit) => setState(() => ({ page, limit })), [setState]);

  const onFilterChange = React.useCallback(
    (_id) => {
      setIds((ids) => [...ids].map((i) => ({ ...i, isActive: i.id === _id })));
      setState(() => ({ page: 1 }));
    },
    [setState],
  );

  const handleRemoveFilter = React.useCallback(
    (_id) => {
      setIds((ids) => {
        const __ids = filter(ids, (val) => val.id !== _id);
        history.push(`/${LISTINGPAGES.LIST}?${join(map(__ids, 'id'), ',')}`);
        return __ids;
      });
    },
    [history],
  );

  const handleModify = React.useCallback(() => {
    const REDIRECT = getPath() === LISTINGPAGES.MATCH_PAIR ? SEARCH_PAGES.MATCH_PAIR : SEARCH_PAGES.SEARCH;
    history.push(`${REDIRECT}/${ids.find((id) => id.isActive)?.id}`);
  }, [history, ids]);

  const action = React.useMemo(() => {
    return (
      <DiamondListingAction
        modify={
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.MATCH_PAIR
        }
        onModify={handleModify}
        trackType={trackTypeObj[invert(LISTINGPAGES)[getPath()]]}
        {...state}
        currentType={currentType}
        nonocompare={
          !get(USER_PERMIT, 'COMPARE.view', false) ||
          getPath() === LISTINGPAGES.QUOTE_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nowatch={
          !get(USER_PERMIT, 'WATCHLIST.view', false) ||
          getPath() === LISTINGPAGES.WATCH_LIST ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.QUOTE_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nocart={
          !get(USER_PERMIT, 'CART.view', false) ||
          getPath() === LISTINGPAGES.CART ||
          getPath() === LISTINGPAGES.WATCH_LIST ||
          getPath() === LISTINGPAGES.NOTE ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.ENQUIRY ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        noreminder={
          !get(USER_PERMIT, 'REMINDER.view', false) ||
          getPath() === LISTINGPAGES.REMINDER ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.QUOTE_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        delete={
          (get(USER_PERMIT, 'CART.delete', false) && getPath() === LISTINGPAGES.CART) ||
          (get(USER_PERMIT, 'WATCH_LIST.delete', false) && getPath() === LISTINGPAGES.WATCH_LIST) ||
          (get(USER_PERMIT, 'REMINDER.delete', false) && getPath() === LISTINGPAGES.REMINDER) ||
          (get(USER_PERMIT, 'NOTES.delete', false) && getPath() === LISTINGPAGES.NOTE) ||
          (get(USER_PERMIT, 'OFFICE.delete', false) && getPath() === LISTINGPAGES.OFFICE) ||
          (get(USER_PERMIT, 'HOLD.delete', false) && getPath() === LISTINGPAGES.HOLD)
        }
        noconfirm={
          !get(USER_PERMIT, 'CONFIRM_STONE.view', false) ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.WATCH_LIST ||
          getPath() === LISTINGPAGES.NOTE ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.ENQUIRY ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.QUOTE_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nooffice={
          !get(USER_PERMIT, 'OFFICE.view', false) ||
          getPath() === LISTINGPAGES.OFFICE ||
          getPath() === LISTINGPAGES.WATCH_LIST ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.NOTE ||
          getPath() === LISTINGPAGES.ENQUIRY ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        nonote={
          !get(USER_PERMIT, 'NOTES.view', false) ||
          getPath() === LISTINGPAGES.NOTE ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.QUOTE_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        noquote={
          !get(USER_PERMIT, 'QUOTE.view', false) ||
          getPath() === LISTINGPAGES.QUOTE ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.STONE_OF_DAY ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        update={
          (get(USER_PERMIT, 'REMINDER.update', false) && getPath() === LISTINGPAGES.REMINDER) ||
          (get(USER_PERMIT, 'NOTES.update', false) && getPath() === LISTINGPAGES.NOTE)
        }
        nofinalcalc={
          !get(USER_PERMIT, 'FINAL_CALCULATIONS.view', false) ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.SUGGESTED_STOCK
        }
        noenquiry={
          !get(USER_PERMIT, 'ENQUIRY.view', false) ||
          getPath() === LISTINGPAGES.ENQUIRY ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING
        }
        nohold={
          !get(USER_PERMIT, 'HOLD.view', false) ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.LIST ||
          getPath() === LISTINGPAGES.HOLD
        }
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        onPaginationChange={onPaginationChange}
        nopaginate={getPath() === LISTINGPAGES.MATCH_PAIR}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        promotionDis={promotionDis}
        offerDis={offerDis}
      />
    );
  }, [
    USER_PERMIT,
    clearAllSelectedRows,
    currentType,
    fetch,
    handleModify,
    offerDis,
    onPaginationChange,
    promotionDis,
    state,
  ]);

  const classList = ['.HeaderSticky', '.selectStonHeader', '.accountTopBlock', '.accountTabBox', '.botoomStickyBar'];
  const gridHeight = getGridHeight(classList);

  const gridView = React.useMemo(() => {
    return (
      <>
        <DiamondGridView data={state.data} loading={state.loading} currentType={currentType} />
        {emptyData(state.loading, state.data.length)}
      </>
    );
  }, [currentType, state.data, state.loading]);

  const title = React.useMemo(() => {
    const exclusive = exclusiveTitle && exclusiveTitle.find((list) => `?${list.id}` === window.location.search)?.name;
    return (getPath() === LISTINGPAGES.EXCLUSIVE ? exclusive : TITLE[getPath()]) + ' (' + Number(state.count) + ')';
  }, [state.count]);

  const parent = React.useMemo(() => {
    return {
      getTitle: () => title,
      getListTop: () => listingTop,
      getSelection: () => selection,
      getGridView: () => gridView,
      getAction: () => action,
    };
  }, [action, gridView, listingTop, selection, title]);

  return (
    <>
      {isMobile && (
        <MobileViewList {...props} {...state} listView={listView} currentType={currentType} parent={parent} />
      )}
      {isAccount && (
        <div className="searchResultListWrapper profilePage">
          <div className="searchInnerResult">
            <div className="position-relative">{getSelection()}</div>
            <div
              className="searchResultTable profilePageTable"
              style={
                `${location.pathname.split('/')[1] === 'account'}`
                  ? {
                      height: `${hasSelectedRows}`
                        ? `${location.pathname}` === '/account/confirm-list'
                          ? `calc(100vh - 210px)`
                          : `calc(100vh - 277px)`
                        : `calc(100vh - 210px)`,
                      overflow: 'auto',
                    }
                  : { height: gridHeight - 17, overflow: 'auto' }
              }
            >
              <Table
                data={state.data}
                loading={state.loading}
                handleSort={handleSort}
                currentType={currentType}
                defaultChecked={state.defaultChecked}
                page={state.page}
              />
              {emptyData(state.loading, state.data.length)}
            </div>
            {action}
          </div>
        </div>
      )}
      {!isAccount && !isMobile && (
        <div className="searchResultListWrapper">
          <div className="selectStonHeader">
            <div className="d-flex justify-content-between align-end width-100  pos_rel flex-wrap">
              <div className="d-flex align-items-center  flex-wrap search_para">
                <div className="d-flex width-full item-centre justify-space-between">
                  <Heading count={state.count}>
                    <IntlMessage id={PAGE_TITLE[pathname]} />
                  </Heading>
                  <ul className="diamond_legends">
                    <li>
                      <span className="legend_clr avail"></span>
                      <span className="legend_name">Available (A)</span>
                    </li>
                    <li>
                      <span className="legend_clr bus"></span>
                      <span className="legend_name">Hold (H)</span>
                    </li>
                    <li>
                      <span className="legend_clr memo"></span>
                      <span className="legend_name">In Memo (M)</span>
                    </li>
                    {/* <li>
                      <span className="legend_clr up"></span>
                      <span className="legend_name">Upcoming (U)</span>
                    </li> */}
                  </ul>

                  <div className="diamondlistInfo">
                    <Popover
                      overlayClassName="infoPopover"
                      placement="bottomLeft"
                      content={
                        <p>
                          <b>Note:</b>
                          1) The prices mentioned over here are fixed and hence not negotiable.
                          <p>
                            {' '}
                            2) The grading, patterns & parameters mentioned on our website beyond GIA's grading is
                            solely our perspective based on examinations conducted by our grading department and we do
                            not hold ourself responsible for any conflicts in this regard.
                          </p>
                        </p>
                      }
                    >
                      <img src={require('assets/svg/info.svg')} alt="" />
                    </Popover>
                  </div>
                </div>

                {SEARCH_TAGS && (
                  <div className="d-flex width-100 listTag flex-wrap">
                    {state.seachFilterTag && <MasterTags filterData={state.seachFilterTag.displayData} />}
                  </div>
                )}
              </div>

              <div className={`d-flex align-end ${MULTIPLE_SEARCH && ids.length > 1 && 'multi-search'}`}>
                <div>
                  {MULTIPLE_SEARCH && ids.length > 1 && (
                    <FilterTop handleRemoveFilter={handleRemoveFilter} handleFilterChange={onFilterChange} ids={ids} />
                  )}
                </div>
                {listingTop}
              </div>
              {selection}
            </div>
          </div>
          <div className="searchInnerResult">
            {listView ? (
              <GridHeightContainer
                className="searchResultTable"
                subtractFrom={GridHeightContainer.selectors([
                  '.HeaderSticky',
                  '.selectStonHeader',
                  '.accountTopBlock',
                  '.accountTabBox',
                  '.botoomStickyBar',
                ])}
              >
                <Table
                  data={state.data}
                  loading={state.loading}
                  handleSort={handleSort}
                  currentType={currentType}
                  defaultChecked={state.defaultChecked}
                  // page={state.page}
                  columns={state.columns}
                />
                {emptyData(state.loading, state.data.length)}
              </GridHeightContainer>
            ) : (
              <GridHeightContainer
                className="searchResultGridView"
                subtractFrom={GridHeightContainer.selectors([
                  '.HeaderSticky',
                  '.selectStonHeader',
                  '.accountTopBlock',
                  '.accountTabBox',
                  '.botoomStickyBar',
                ])}
              >
                {gridView}
              </GridHeightContainer>
            )}
            {action}
          </div>
        </div>
      )}
    </>
  );
};

export default DiamondList;
