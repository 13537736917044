import React from 'react';
import { useSelector } from 'react-redux';

import { getPath, LISTINGPAGES, isMobile } from 'components/DiamondList/DiamondListFunctions';

import { IntlMessage } from 'util/IntlMessages';
import { useIsMobile } from 'util/hooks';
import { calcTotal } from 'util/total.calc';
import { formatDecimal, isArray, isEmpty } from 'util/utils';

import { SELECT_STONE_TERMS_NEW, LOCAL_STORAGE_VAR } from 'constants/Common';

import { store } from 'appRedux/store';

export const formatNumber = formatDecimal;
export const calculate = (stoneList = [], term = 0) => {
  const calc = calcTotal(stoneList, { term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term) ?? 0 });
  return {
    total_pieces: calc?.count,
    total_carat: calc?.sumCrt,
    total_rapaport: calc?.sumRap,
    final_rapaport: calc?.avgRapByCrt,
    final_discount: calc?.avgBack,
    final_price: calc?.sumCtPr,
    final_value: calc?.sumAmt,
    addDis: calc?.termDis,
    sysDis: calc?.sysDis,
    final_term_discount: calc?.sysBack,
    final_rate: calc?.finalCtPr,
    final_net_value: calc?.finalAmt,
    final_avg_disc: calc?.finalAvgDisc,
    bid_final_disc: calc?.bidFinalDisc,
    bid_final_Pr: calc?.bidFinalPr,
    bid_final_amt: calc?.bidFinalAmt,
    term_sys_dis: calc?.termSysDis,
    avg_back: calc?.avgBack,
    final_back: calc?.finalBack,
    offerFinalAmt: calc?.offerFinalAmt,
    offerFinalPr: calc?.offerFinalPr,
    offerFinalDisc: calc?.offerFinalDisc,
  };
};

const term = undefined;

const StoneTotal = (props) => {
  const { currentType, sum, profilListing, blockStyle } = props;

  const selectedRows = useSelector((state) => state.diamondData?.selectedRows?.[currentType] ?? []);
  const clearSelection = React.useCallback(() => {
    if (document.getElementsByTagName('table')[0]) {
      store.dispatch({ type: 'RESET_SELECTED_ROWS' });
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) row.classList.remove('selectTr');
    } else {
      store.dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, []);

  const content = React.useMemo(() => {
    const guest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

    if (isEmpty(selectedRows)) return [];
    const isUpcoming = Boolean(getPath() === LISTINGPAGES.UPCOMING);
    const total = {
      count: sum?.count ?? 0,
      sumCrt: sum?.totalCarat ?? 0,
      avgRapByCrt: sum?.rapPrice ?? 0,
      avgBack: sum?.avgDiscount ?? 0,
      sumCtPr: sum?.totalPricePerCarat ?? 0,
      sumAmt: sum?.totalAmount ?? 0,
      finalBack: sum?.avgDiscount ?? 0,
      finalCtPr: sum?.totalPricePerCarat ?? 0,
      finalAmt: sum?.totalAmount ?? 0,
    };

    const calc = calcTotal(selectedRows, {
      term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term) ?? 0,
    });

    const common = [
      { label: 'Pieces', check: Number(calc.count), sum: Number(total.count) },
      { label: 'Carat', check: formatDecimal(calc.sumCrt), sum: formatDecimal(total.sumCrt) },
    ];

    if (isUpcoming) return common;

    if (guest) {
      return [
        ...common,
        // { label: 'Rap Price', check: formatDecimal('-'), sum: formatDecimal('-') },
        // { label: 'Avg. Dis(%)', check: formatDecimal('-'), sum: formatDecimal('-') },
        { label: 'Total Pr/Ct', check: formatDecimal('-'), sum: formatDecimal('-') },
        getPath() !== LISTINGPAGES.BID ||
        getPath() !== LISTINGPAGES.MY_BID ||
        getPath() !== LISTINGPAGES.DEAL_OF_THE_DAY
          ? { label: <IntlMessage id="app.Amount" />, check: formatDecimal('-'), sum: formatDecimal('-') }
          : {
              label: <IntlMessage id="app.Amount" />,
              check: formatDecimal(calc.bidFinalAmt),
              sum: formatDecimal(total.sumAmt),
            },
        getPath() !== LISTINGPAGES.BID ||
          getPath() !== LISTINGPAGES.MY_BID ||
          (getPath() !== LISTINGPAGES.DEAL_OF_THE_DAY && {
            label: 'Final Amount',
            check: formatDecimal('-'),
            sum: formatDecimal('-'),
          }),
      ];
    }
    return [
      ...common,
      // { label: 'Rap Price', check: formatDecimal(calc.avgRapByCrt), sum: formatDecimal(total.avgRapByCrt) },
      // { label: 'Avg. Dis(%)', check: formatDecimal(calc.avgBack), sum: formatDecimal(total.avg_back) },
      { label: 'Total Pr/Ct', check: formatDecimal(calc.sumCtPr), sum: formatDecimal(total.sumCtPr) },
      getPath() !== LISTINGPAGES.BID || getPath() !== LISTINGPAGES.MY_BID
        ? {
            label: <IntlMessage id="app.Amount" />,
            check: formatDecimal(calc.sumAmt),
            sum: formatDecimal(total.sumAmt),
          }
        : {
            label: <IntlMessage id="app.Amount" />,
            check: formatDecimal(calc.bidFinalAmt),
            sum: formatDecimal(total.sumAmt),
          },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MY_BID &&
        getPath() !== LISTINGPAGES.DEAL_OF_THE_DAY &&
        getPath() !== LISTINGPAGES.MY_DEAL_OF_THE_DAY &&
        props.promotionDis?.data !== 0 && {
          label: 'Promotional Dis(%)',
          check:
            parseFloat(calc.finalAmt) > props.promotionDis?.maxAmt
              ? `${props.promotionDis?.data} ($${Math.abs(
                  Math.round((parseFloat(props.promotionDis?.maxAmt) * props.promotionDis?.data) / 100),
                )})`
              : `${props.promotionDis?.data} ($${Math.abs(
                  parseFloat(parseFloat(calc.finalAmt) * (props.promotionDis?.data / 100)).toFixed(2),
                )})`,
          sum: props.promotionDis?.data,
        },
    ];
  }, [
    props.promotionDis?.data,
    props.promotionDis?.maxAmt,
    selectedRows,
    sum?.avgDiscount,
    sum?.count,
    sum?.rapPrice,
    sum?.totalAmount,
    sum?.totalCarat,
    sum?.totalPricePerCarat,
  ]);

  const dealContent = props?.myDealOfTheDay
    ? [
        { label: 'Deal Disc', check: formatDecimal(sum?.avgDiscount) },
        { label: 'Deal Total Pr/ct', check: formatDecimal(sum?.totalPricePerCarat) },
        { label: 'Deal Amount', check: formatDecimal(sum?.totalAmount) },
      ]
    : [];

  if (isMobile())
    return (
      <>
        <div className="diamondListLegends">
          <p>
            <span className="lDot available"></span>
            <span className="lName">A (Available)</span>
          </p>
          <p>
            <span className="lDot ibp"></span>
            <span className="lName">I (In Business Process)</span>
          </p>
          <p>
            <span className="lDot bid"></span>
            <span className="lName">B (In Bid)</span>
          </p>
          <p>
            <span className="lDot upcoming"></span>
            <span className="lName">U (upcoming)</span>
          </p>
        </div>
        <div className="diamondListSummary">
          <div className="diamondListSummaryWrap">
            {content.splice(0, 6).map((item, index) => {
              return (
                <p key={index}>
                  <span className="sVal">{item.check}</span>
                  <span className="sTitle">{item.label}</span>
                </p>
              );
            })}
          </div>
        </div>
      </>
    );

  if (!isArray(selectedRows) || isEmpty(selectedRows)) return null;

  return (
    <div className={`selectStoneValueBlock ${profilListing}`} style={blockStyle}>
      <div className="scroll_wrap">
        {content.map((item, i) => {
          return (
            <div className="selectStoneValueItem" key={item.label + i}>
              <span className="selectStoneLabel">
                {item.label && item.label}
                {item.label && ':'}{' '}
              </span>
              <span className="selectStopnValue">
                <span className="redColor">
                  {item.label === 'Avg. Dis(%)' || item.label === 'Final Dis(%)'
                    ? item.check > 0
                      ? `+${item.check}`
                      : item.check
                    : item.check}
                </span>
              </span>
            </div>
          );
        })}
        {!!dealContent.length && (
          <div style={{ marginLeft: '250px', display: 'flex' }}>
            {dealContent.map((item, i) => {
              return (
                <div className="selectStoneValueItem" key={item.label + i}>
                  <span className="selectStoneLabel" style={{ color: '#db1c1c' }}>
                    {item.label && item.label}
                    {item.label && ':'}{' '}
                  </span>
                  <span className="selectStopnValue">
                    <span className="redColor" style={{ color: '#0027f1' }}>
                      {item.label === 'Avg. Dis(%)' || item.label === 'Final Dis(%)'
                        ? item.check > 0
                          ? `+${item.check}`
                          : item.check
                        : item.check}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!props.hideClearAll && (
        <div className="searchStoneClose" onClick={clearSelection}>
          Clear all
        </div>
      )}
    </div>
  );
};

export default React.memo(StoneTotal);
