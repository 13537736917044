import { Storage } from 'services/Storage';

const VisitedFieldsStorageKey = 'visited-fields';

const VisitedFieldsInitialState = Storage.get(VisitedFieldsStorageKey) ?? [];

const VisitedFieldsActionTypes = {
  ADD: '@visited-fields/add',
  RESET: '@visited-fields/reset',
};

export const VisitedFieldsActions = {
  addField: (id) => ({ type: VisitedFieldsActionTypes.ADD, data: id }),
  reset: () => ({ type: VisitedFieldsActionTypes.RESET }),
};

export default function VisitedFields(state = VisitedFieldsInitialState, { type, data }) {
  switch (type) {
    case VisitedFieldsActionTypes.ADD: {
      const newState = [...state, data];
      Storage.set(VisitedFieldsStorageKey, newState);
      return newState;
    }

    case '@@RESET':
    case VisitedFieldsActionTypes.RESET: {
      Storage.delete(VisitedFieldsStorageKey);
      return [];
    }

    default:
      return state;
  }
}
