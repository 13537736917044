import { Storage } from 'services/Storage';

import {
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  MASTER_DATA_LOGIN,
  KEYWORDS_DATA,
  OTP_VERIFICATION,
  SIGNOUT_USER,
} from 'constants/ActionTypes';

const defaultState = {
  token: undefined,
  initURL: undefined,
  authUser: undefined,
  masterData: undefined,
  keywordsData: undefined,
};

const INIT_STATE = {
  ...defaultState,
  initURL: '',
  token: Storage.get('token'),
  authUser: Storage.get('user'),
  masterData: Storage.get('master', false),
  keywordsData: Storage.get('keywords', false),
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SIGNOUT_USER_SUCCESS:
      return { ...state, token: null, authUser: null, masterData: null, initURL: '' };

    case USER_DATA:
      return { ...state, authUser: payload };

    case OTP_VERIFICATION:
      return { ...state, authUser: payload };

    case USER_TOKEN_SET:
      return { ...state, token: payload };

    case MASTER_DATA_LOGIN:
      return { ...state, masterData: payload };

    case KEYWORDS_DATA:
      return { ...state, keywordsData: payload };

    case '@@RESET':
    case SIGNOUT_USER:
      return { ...defaultState };

    default:
      return state;
  }
};
