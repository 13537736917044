import React from 'react';
import { useSelector } from 'react-redux';

const ConnectedCell = React.memo((props) => {
  const { currentType, cell, row } = props;

  const { column } = cell;
  const { original: record } = row;
  const { id: name, formatter } = column;

  const value = useSelector((store) => store?.tableValues?.[currentType]?.[record?.vStnId]?.[name] ?? cell.value);

  return <div>{formatter?.(value) ?? value ?? '-'}</div>;
});
ConnectedCell.displayName = 'ConnectedCell';

export default ConnectedCell;
