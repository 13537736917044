import { formatDecimal } from 'util/utils';

import ConnectedCell from './ConnectedCell';
import ConnectedCellInput from './ConnectedCellInput';
import { handleChange, handleDecrease, handleIncrease } from './_functions';

export const BID_STONE_COLUMNS = {
  BID_BACK: {
    id: 'bidDiscount',
    accessor: 'bidDiscount',
    Header: 'Bid Disc(%)',
    Cell: ConnectedCellInput,
    onChange: handleChange,
    onDecrease: handleDecrease,
    onIncrease: handleIncrease,
    width: 150,
  },
  BID_CTPR: {
    id: 'bidPricePerCarat',
    accessor: 'bidPricePerCarat',
    Header: 'Bid Price/Ct',
    Cell: ConnectedCellInput,
    onChange: handleChange,
    onDecrease: handleDecrease,
    onIncrease: handleIncrease,
    width: 150,
  },
  BID_AMT: {
    id: 'bidAmount',
    accessor: 'bidAmount',
    Header: 'Bid Value',
    Cell: ConnectedCell,
    formatter: formatDecimal,
    width: 100,
  },
  MAX_BID_BACK: {
    id: 'maxBidDisc',
    accessor: 'maxBidDisc',
    Header: 'Max Bid Disc%',
    Cell: ({ cell }) => formatDecimal(cell.value) ?? '-',
  },
  MAX_BID_CTPR: {
    id: 'maxBidCtpr',
    accessor: 'maxBidCtpr',
    Header: 'Max Bid Price/Ct',
    Cell: ({ cell }) => formatDecimal(cell.value),
  },
  MAX_BID_AMT: {
    id: 'maxBidAmt',
    accessor: 'maxBidAmt',
    Header: 'Max Bid Amount',
    Cell: ({ cell }) => formatDecimal(cell.value),
  },
};
