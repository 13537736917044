import React, { Component } from 'react';
import { connect } from 'react-redux';

import find from 'lodash/find';

import Table from 'components/DiamondList/TableBack';
import BIDStoneMobile from 'components/common/DiamondListing/BIDStoneMobile';
import { Heading } from 'components/common/Heading';

import { BidService } from 'services/BidService';

import IntlMessages from 'util/IntlMessages';
import { notify } from 'util/notify';
import { calcStoneValues, calcTotal } from 'util/total.calc';
import { comparePathname, formatFloat, isNumber, parseDecimal, reduceTotal } from 'util/utils';

import { SELECT_STONE_TERMS_NEW } from 'constants/Common';

import { HeadingCalc } from './ConfirmStonePopup';
import { newDiamondPrice } from './FinalCalculations';

import { getColumn, getPath, handleBidUpsert, isMobile, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';

const currentType = 'bidStones';

class DealOfDayPopup extends Component {
  state = {
    columns: [],
    checked: [],
    data: [],
    summ: !comparePathname(LISTINGPAGES.COMPARE)
      ? localStorage.getItem('Terms') === 'true'
        ? localStorage.getItem('Termvalue')
          ? localStorage.getItem('Termvalue')
          : ''
        : localStorage.getItem('Terms')
      : '',
    summation: {},
    showModal: false,
    minCtPr: 0,
    avgDisc: 0,
    avgPrCt: 0,
    avgAmt: 0,
  };

  get values() {
    const { summation: calc, bidConfig } = this.state;

    const { downwardInterval, upwardInterval, calColumn } = { ...bidConfig?.sub_types };

    const avgBack = parseDecimal(calc?.AvgDisc);
    const avgCtPr = parseDecimal(calc?.finalNetRate);
    const avgRap = parseDecimal(calc?.avgRap ?? avgCtPr);

    const sumCrt = parseDecimal(calc?.totalCarats);
    const sumAmt = parseDecimal(calc?._sumBidAmt);

    const down = isNumber(Number(downwardInterval)) ? parseDecimal(-Math.abs(downwardInterval)) : undefined;
    const up = isNumber(Number(upwardInterval)) ? parseDecimal(+Math.abs(upwardInterval)) : undefined;

    const addTo = calColumn ?? 'back';

    return { avgBack, avgCtPr, avgRap, sumCrt, sumAmt, down, up, addTo };
  }

  componentDidMount() {
    const data = this.props.checked.map((x) => {
      x = newDiamondPrice(
        {
          ...x,
          bidPricePerCarat: parseDecimal((x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) || 0),
          bidAmount: x.bidAmount ? parseDecimal(x.bidAmount) : parseDecimal(x.amt) || 0,
          bidDiscount: parseDecimal(x.bidDiscount ? x.bidDiscount : x.back || 0),
        },
        'bid',
      );
      return {
        ...x,
        bidDiscount: !comparePathname(LISTINGPAGES.MY_DEAL_OF_THE_DAY)
          ? x.ctPr < x.rap
            ? -1 * Math.abs(parseDecimal(Number(x.bidDiscount)))
            : Math.abs(parseDecimal(Number(x.bidDiscount)))
          : Number(x.bidDiscount) < 0
          ? -1 * Math.abs(parseDecimal(Number(x.bidDiscount)))
          : Math.abs(parseDecimal(Number(x.bidDiscount))),
      };
    });

    const columns = getColumn();
    this.setState({ columns, data, oldData: data, checked: data }, () => {
      const newData = [];
      this.state.data.forEach((d) => {
        this.state.checked.forEach((c) => {
          if (d.id === c.id) newData.push(d);
        });
      });
      // this.getNextBidConfig();
      this.handleCalculation(newData);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length != this.state.checked.length) {
      this.setState(
        {
          checked: this.state.data.filter((x) => (find(nextProps.checkedData, { id: x.id }) ? true : false)),
        },
        () => {
          const newData = [];
          this.state.data.forEach((d) => {
            this.state.checked.forEach((c) => {
              if (d.id === c.id) newData.push(d);
            });
          });
          this.handleCalculation(newData);
        },
      );
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.data != this.state.data) {
      const newData = [];
      this.state.data.forEach((d) => {
        this.state.checked.forEach((c) => {
          if (d.id === c.id) newData.push(d);
        });
      });
      this.handleCalculation(newData);
    }
  }

  showError = () => {
    this.setState({ showModal: true });
  };

  hideError = () => {
    this.setState({ showModal: false });
  };

  checkCheck = () => {
    if (!this.state.checked.length) notify.error({ message: 'Please select stone(s) to bid.' });
    return Boolean(this.state.checked.length);
  };

  submit = () => {
    if (!this.checkCheck()) return;

    const newData = [];
    const calc = calcTotal(this.state.data, { isBidDis: true, term: 0 });
    const disDiff = Number(this.state.avgDisc) - Number(calc?.avgBack);

    this.state.data.forEach((d) => {
      this.state.checked.forEach((c) => {
        if (d.id === c.id) newData.push(d);
      });
    });

    const value = newData.map((x) => {
      const bidDiscount = x.back + disDiff;
      const bidPricePerCarat = x.rap + (x.rap * bidDiscount) / 100;
      const bidAmount = bidPricePerCarat * x.crt;

      return {
        ...x,
        bidDiscount: parseDecimal(bidDiscount),
        bidPricePerCarat: parseDecimal(bidPricePerCarat),
        bidAmount: parseDecimal(bidAmount),
      };
    });

    handleBidUpsert(value, this.props.bidConfig, (flag) => {
      this.props.onClose();
      this.props.clearAll();
      if (flag && this.props.fetch) this.props.fetch();
    });
  };

  getTerms = () => {
    return (
      <div>
        <select
          name="summ"
          value={this.state.summ}
          placeholder="Select Terms"
          onChange={(e) => {
            localStorage.setItem('Termvalue', e.target.value);
            this.setState({ summ: e.target.value }, () => {
              const newData = [];
              this.state.data.forEach((d) => {
                this.state.checked.forEach((c) => {
                  if (d.id === c.id) newData.push(d);
                });
              });
              this.handleCalculation(newData);
            });
          }}
        >
          <option value="" disabled selected hidden>
            Select Terms
          </option>
          {SELECT_STONE_TERMS_NEW.map((x) => {
            return (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  handleCalculation = async (diams) => {
    const calc = {};

    diams.forEach((d) => void (d.rap_avg = (d.rap ?? d.ctPr) * d.crt));

    calc.pieces = diams.length;
    calc._sumCrt = parseDecimal(reduceTotal(diams, 'crt'));
    calc._sumRapAvg = parseDecimal(reduceTotal(diams, 'rap_avg'));
    calc._sumBidAmt = parseDecimal(reduceTotal(diams, 'bidAmount'));
    calc._avgCtPr = parseDecimal(calc._sumBidAmt / calc._sumCrt);

    const avgPricePerCarat = reduceTotal(diams, 'bidAmount') / reduceTotal(diams, 'crt');
    calc.totalCarats = reduceTotal(diams, 'crt');
    calc.avgDisc = calc._sumRapAvg ? (1 - reduceTotal(diams, 'amt') / reduceTotal(diams, 'rap_avg')) * -100 : 0;
    calc.AvgDisc = calc._sumRapAvg
      ? (1 - avgPricePerCarat / (reduceTotal(diams, 'rap_avg') / calc.totalCarats)) * -100
      : 0;
    calc.finalNetValue = reduceTotal(diams, 'bidAmount');

    calc.finalNetRate = calc._avgCtPr;
    calc.netValue = reduceTotal(diams, 'bidAmount');
    calc.perCarat = reduceTotal(diams, 'amt') / calc.totalCarats;
    calc.BidPerCarat = reduceTotal(diams, 'bidAmount') / calc.totalCarats;
    calc.amt = reduceTotal(diams, 'amt');

    calc.addDis = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    calc.finalAvgDisc = calc._sumRapAvg
      ? (1 -
          reduceTotal(diams, 'bidAmount') /
            reduceTotal(diams, 'crt') /
            (reduceTotal(diams, 'rap_avg') / calc.totalCarats)) *
        -100
      : 0;
    calc.avgRap = calc._sumRapAvg ? calc._sumRapAvg / reduceTotal(diams, 'crt') : 0;

    if (this.state.summ) {
      const totalRapAvg = reduceTotal(diams, 'rap_avg');
      const options = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
      const discCalc = options.minAmt
        ? Number(calc.amt) >= options.minAmt
          ? options.value
          : options.resetDis
        : options.value;
      const termDis = discCalc;

      const avg = totalRapAvg ? (1 - calc.finalNetValue / reduceTotal(diams, 'rap_avg')) * -100 : 0;
      const final = calc.finalNetRate + calc.finalNetRate * (Number(termDis) / 100);
      const rap = totalRapAvg ? reduceTotal(diams, 'rap_avg') / calc.totalCarats : 0;
      // const final = calc.finalNetValue + calc.finalNetValue * Number(termDis);
      const amount = calc.finalNetValue + calc.finalNetValue * (Number(termDis) / 100);
      calc.BidPerCarat = reduceTotal(diams, 'bidAmount') / calc.totalCarats;
      calc.perCarat = reduceTotal(diams, 'amt') / calc.totalCarats;

      // Calculation for term
      calc.addDis = termDis;
      calc.AvgDisc = totalRapAvg ? avg : 0;
      calc.finalNetValue = amount;
      calc.finalNetRate = final;
      calc.finalAvgDisc = totalRapAvg ? (1 - Number(final) / rap) * -100 : 0;
      calc.avgRap = rap;

      calc._avgCtPr = calc.finalNetRate;
    }

    const bidConfig = await BidService.fetchRunningBid();
    await this.setState({ bidConfig }, () => {
      const calcStoneOutput = calcStoneValues(1, { field: 'ctPr', ...this.values });
      const isMyBidPage = getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY;
      this.setState({
        summation: calc,
        avgPrCt: parseFloat(isMyBidPage ? calc.BidPerCarat : calcStoneOutput.minCtPr).toFixed(2),
        avgDisc: parseFloat(isMyBidPage ? calc.AvgDisc : calcStoneOutput.minBack).toFixed(2),
        avgAmt: parseFloat(isMyBidPage ? calc.netValue : calcStoneOutput.minAmt).toFixed(2),
      });
    });
  };

  setMinData = (calcStoneOutput) => {
    this.setState({
      avgPrCt: parseFloat(calcStoneOutput.minCtPr).toFixed(2),
      avgDisc: parseFloat(calcStoneOutput.minBack).toFixed(2),
      avgAmt: parseFloat(calcStoneOutput.minAmt).toFixed(2),
    });
  };

  validateAvgAmount = (value, fieldKey) => {
    value = Number(value);
    if (!isNumber(value)) return false;

    const backModified = fieldKey === 'back'; // eslint-disable-line no-unused-vars
    const ctPrModified = fieldKey === 'ctPr';
    const amtModified = fieldKey === 'amt';

    const calcStoneOutput = calcStoneValues(value, { field: fieldKey, ...this.values });
    const { back, minBack, minCtPr, minAmt, maxBack, maxCtPr, maxAmt } = calcStoneOutput;

    const currentMaxValue = parseDecimal(ctPrModified ? maxCtPr : amtModified ? maxAmt : maxBack);
    const currentMinValue = parseDecimal(ctPrModified ? minCtPr : amtModified ? minAmt : minBack);

    const fieldName = 'Bid Price/Ct'; //STONE_FIELDS_MAP[fieldKey];
    if (back < minBack) {
      notify.error({ message: `Avg. ${fieldName} can not be less than ${minCtPr}` });
      this.setMinData(calcStoneOutput);
    }
    if (back > maxBack) {
      notify.error({ message: `Avg. ${fieldName} can not be more than ${maxCtPr}` });
      this.setMinData(calcStoneOutput);
    }

    return back >= minBack && back < maxBack;
  };

  getAvgDisc = () => {
    const handleChange = (e) => this.setState({ avgDisc: e.target.value });

    const handleBlur = (e) => {
      const value = e.target.value ?? 0.0;
      const avgCtPr = parseFloat(this.state.summation?.avgRap + this.state.summation?.avgRap * (value / 100)).toFixed(
        2,
      );
      const avgPrCt = parseFloat(avgCtPr).toFixed(2);
      const avgAmt = parseFloat(avgCtPr * this.state.summation?.totalCarats).toFixed(2);

      if (getPath() !== LISTINGPAGES.MY_DEAL_OF_THE_DAY && !this.validateAvgAmount(Number(value), 'back')) {
        return;
      }

      if (
        getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY &&
        Number(value) < Number(parseFloat(this.state.summation.AvgDisc).toFixed(2))
      ) {
        notify.error({ message: 'Kindly raise your bid.' });
        this.setState({
          avgDisc: parseFloat(this.state.summation.AvgDisc).toFixed(2),
          avgPrCt: parseFloat(this.state.summation.BidPerCarat).toFixed(2),
          avgAmt: parseFloat(this.state.summation.netValue).toFixed(2),
        });
        return;
      }

      this.setState({
        avgDisc: parseFloat(value).toFixed(2),
        avgPrCt,
        avgAmt,
      });
    };

    const sign = Number(this.state.avgDisc) > 0 ? '+' : '';

    return (
      <div className="d-flex j-space-between quoteExtraDetail deal-of-the-day-continer">
        <div className="quoteExtraItem ">
          <span>Bid Disc(%): </span>
          <div className="tableInput">
            <span className="add-sign-deal-list">{sign}</span>
            <input
              type="number"
              style={{ paddingLeft: Number(this.state.avgDisc) > 0 ? 8 : 2 }}
              value={this.state.avgDisc}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    );
  };

  getPrCt = () => {
    const { summation } = this.state;

    const handleChange = (e) => {
      this.setState({ avgPrCt: e.target.value });
    };

    const handleBlur = (e) => {
      const value = e.target.value ?? 0.0;
      const avgAmt = (value || 0) * summation?.totalCarats;
      const avgDisc = parseFloat((1 - value / summation?.avgRap) * -100).toFixed(2);

      if (getPath() !== LISTINGPAGES.MY_DEAL_OF_THE_DAY && !this.validateAvgAmount(Number(value), 'ctPr')) return;

      if (
        getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY &&
        Number(avgDisc) < Number(parseFloat(summation.AvgDisc).toFixed(2))
      ) {
        notify.error({ message: 'Kindly raise your bid.' });
        return this.setState({
          avgDisc: parseFloat(summation.AvgDisc).toFixed(2),
          avgPrCt: parseFloat(summation.BidPerCarat).toFixed(2),
          avgAmt: parseFloat(summation.netValue).toFixed(2),
        });
      }

      this.setState({
        avgPrCt: parseFloat(value).toFixed(2),
        avgAmt: parseFloat(avgAmt).toFixed(2),
        avgDisc,
      });
    };

    return (
      <div className="d-flex j-space-between quoteExtraDetail deal-of-the-day-continer">
        <div className="quoteExtraItem ">
          <span>Bid Price/Ct: </span>
          <div className="tableInput">
            <input type="number" value={this.state.avgPrCt} onChange={handleChange} onBlur={handleBlur} />{' '}
          </div>
        </div>
      </div>
    );
  };

  getAmount = () => {
    const { summation } = this.state;

    const handleChange = (e) => {
      this.setState({ avgAmt: e.target.value });
    };

    const handleBlur = (e) => {
      const value = e.target.value ?? 0.0;
      const bidPricePerCarat = parseFloat(value / summation?.totalCarats).toFixed(2);
      const discountValue = parseFloat((1 - bidPricePerCarat / summation?.avgRap) * -100).toFixed(2);
      const avgPrCt = parseFloat(summation?.avgRap + (discountValue * summation?.avgRap) / 100).toFixed(2);

      if (getPath() !== LISTINGPAGES.MY_DEAL_OF_THE_DAY && !this.validateAvgAmount(Number(value), 'amt')) return;

      if (
        getPath() === LISTINGPAGES.MY_DEAL_OF_THE_DAY &&
        Number(discountValue) < Number(parseFloat(summation.AvgDisc).toFixed(2))
      ) {
        notify.error({ message: 'Kindly raise your bid.' });
        return this.setState({
          avgPrCt: parseFloat(summation.BidPerCarat).toFixed(2),
          avgDisc: parseFloat(summation.AvgDisc).toFixed(2),
          avgAmt: parseFloat(summation.netValue).toFixed(2),
        });
      }

      this.setState({
        avgAmt: parseFloat(value).toFixed(2),
        avgDisc: discountValue,
        avgPrCt,
      });
    };

    return (
      <div className="d-flex j-space-between quoteExtraDetail deal-of-the-day-continer">
        <div className="quoteExtraItem ">
          <span>Bid Amount: </span>
          <div className="tableInput">
            <input value={this.state.avgAmt} onChange={handleChange} onBlur={handleBlur} type="number" />{' '}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { summation } = this.state;
    const options = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;

    localStorage.setItem('termObj', JSON.stringify(options));
    localStorage.setItem('TermsDis', summation.addDis);
    localStorage.setItem('disc', summation.addDis);

    const list = [
      { title: 'Total Carats', value: formatFloat(summation.totalCarats || 0) },
      { title: 'Bid Avg Discount(%)', value: formatFloat(summation.AvgDisc || 0) },
      { title: 'Avg. Discount(%)', value: formatFloat(summation.avgDisc || 0) },
      { title: 'Bid Per/Carat', value: formatFloat(summation.BidPerCarat || 0) },
      { title: 'Per/Carat', value: formatFloat(summation.perCarat || 0) },
      { title: 'Bid Amount', value: formatFloat(summation.netValue || 0) },
      { title: 'Amount', value: formatFloat(summation.amt || 0) },
    ];

    if (isMobile())
      return (
        <BIDStoneMobile
          {...this.state}
          onClose={this.props.onClose}
          currentType={currentType}
          VALUES={Object.fromEntries(list.map((item) => [item?.title, item?.value]))}
          parent={this}
          areAllChecked={true}
        />
      );

    return (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20" title={<IntlMessages id="app.bidStone" />} />
          {HeadingCalc(this.state.checked, null, true)}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              bidStatus={this.props.bidConfig?.sub_types?.subTypeCode}
              handleCheck={(e) => this.setState({ checked: e })}
              currentType={currentType}
              FilterOption={false}
              canSort={false}
              areAllChecked
              noCheckBox
              nodots
              noGrp
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {this.getAvgDisc()}
            {this.getPrCt()}
            {this.getAmount()}
          </div>
          <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {list.map((x, index) => (
                <div key={index} className="DiamondDetailPopupItem">
                  <span>{x.title && `${x.title} :`} </span>
                  <span>
                    {x.title === 'Bid Term Discount(%)' || x.title === 'Avg. Discount(%)' || x.title === 'TERMS'
                      ? x.value > 0
                        ? `+${x.value}`
                        : x.value
                      : x.value}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="sideBarPopupButton">
            <a id="bid-stone" className="commonButton" onClick={this.submit}>
              <IntlMessages id="app.bidStone" />
            </a>
            <a className="commonButton" onClick={this.props.onClose}>
              <IntlMessages id="app.CancelBid" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData, bidConfig }) => {
  return {
    bidConfig: bidConfig.data ?? {},
    checkedData: diamondData.selectedRows[currentType] || [],
  };
};

export default connect(mapStateToProps)(DealOfDayPopup);
