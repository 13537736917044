import React from 'react';
import { Link } from 'react-router-dom';

import { Modal } from 'antd';
import { Duration } from 'luxon';

import { BidService } from 'services/BidService';
import { TimeService } from 'services/TimeService';

import { useBoolean } from 'util/hooks';
import { catchError } from 'util/utils';

export function BidEndModal() {
  const [showModal, setShowModal] = useBoolean();

  const bidEndSecondsRef = React.useRef();

  React.useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      if (BidService.state.loading) return;
      const bidEndDuration =
        Duration.isDuration(BidService.bidEndDuration) && BidService.bidEndDuration?.isValid
          ? BidService.bidEndDuration
          : Duration.fromMillis(0);

      const totalSeconds = bidEndDuration.as('seconds');
      if (bidEndSecondsRef.current >= 1 && totalSeconds < 1) {
        setShowModal.true();
        catchError(async () => {
          await TimeService.getServerTime();
          await BidService.fetchRunningBid();
        });
      }
      bidEndSecondsRef.current = totalSeconds;
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [setShowModal]);

  return (
    <Modal className="commonModal" visible={showModal} footer={null} onCancel={setShowModal.false} destroyOnClose>
      <div style={{ textAlign: 'center' }}>
        <h3>Bid Ended</h3>
        <p>Bid has ended and your result should be declared soon.</p>
        <p>
          Check Your <Link to="/my-bid">Bid History</Link>
        </p>
      </div>
    </Modal>
  );
}
