import React from 'react';

import { StoneService } from 'services/StoneService';

import { useIsMobile, useSelectedRows } from 'util/hooks';
import { formatDecimal, formatFloat, isEmpty, reduceTotal } from 'util/utils';

import { BID_STONE_TYPE } from './_constants';
import { useUpdatedBidList } from './_hooks';

export function BidDrawerHeaderCalc() {
  const [selectedRows] = useSelectedRows(BID_STONE_TYPE);

  const fields = React.useMemo(() => {
    if (!isEmpty(selectedRows)) {
      const calc = StoneService.calculateTotal(selectedRows);

      return [
        ['Pieces : ', calc.count],
        ['Crt : ', formatFloat(calc.sumCrt)],
        ['Rap Price : ', formatFloat(calc.avgRapByCrt)],
        ['Disc% : ', formatFloat(calc.avgBack)],
        ['Pr/Ct : ', formatFloat(calc.avgBack)],
        ['Amt. : ', formatFloat(calc.sumAmt)],
      ];
    }

    return {};
  }, [selectedRows]);

  if (Object.keys(fields)?.length < 4) return null;
  if (isEmpty(selectedRows)) return null;

  return (
    <div className="DiamondDetailPopup">
      {fields.map(([label, value]) => (
        <div key={label} className="DiamondDetailPopupItem">
          <span>{label}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
}

export function BidDrawerCalc() {
  const selectedRows = useUpdatedBidList(BID_STONE_TYPE);

  const calc = React.useMemo(() => {
    const amt = reduceTotal(selectedRows, 'amt');
    const avgRap = reduceTotal(selectedRows.map((stone) => Number(stone.rap * stone.crt)));
    const bidAmt = reduceTotal(selectedRows, 'bidAmount');
    const crt = reduceTotal(selectedRows, 'crt');

    const avgBack = avgRap ? (1 - amt / avgRap) * -100 : 0;
    const bidAvgBack = avgRap ? (1 - bidAmt / avgRap) * -100 : 0;
    const totalBidCtPr = bidAmt / crt;
    const totalCtPr = amt / crt;

    return [
      ['Total Carat', formatDecimal(crt)],
      ['Bid Avg. Discount(%)', formatDecimal(bidAvgBack)],
      ['Avg. Discount(%)', formatDecimal(avgBack)],
      ['Bid Price/Carat', formatDecimal(totalBidCtPr)],
      ['Price/Carat', formatDecimal(totalCtPr)],
      ['Bid Amount', formatDecimal(bidAmt)],
      ['Amount', formatDecimal(amt)],
    ];
  }, [selectedRows]);

  return (
    <div className="DiamondDetailPopup">
      {calc.map(([label, value]) => {
        return (
          <div className="DiamondDetailPopupItem" key={label}>
            <span>{label && `${label} :`} </span>
            <span>{value}</span>
          </div>
        );
      })}
    </div>
  );
}
