import React from 'react';
import { connect, useSelector } from 'react-redux';

import { BidService } from 'services/BidService';

import { isFunction, isNumber, isNumeric, formatFloat, classNames } from 'util/utils';

import minusJpg from 'assets/img/minus.jpg';
import plusJpg from 'assets/img/plus.jpg';

const ConnectedCellInput = React.memo((props) => {
  const { currentType, cell, row } = props;

  const { column } = cell;
  const { original: record } = row;
  const { id: name, onChange } = column;
  const { onDecrease, onIncrease } = column;
  const allowChange =
    (name == 'bidDiscount' && BidService.calculateOnDiscount) ||
    (name == 'bidPricePerCarat' && BidService.calculateOnPricePerCarat);

  const values = useSelector((store) => store?.tableValues?.[currentType]?.[record?.vStnId]);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    const originalValue = Number(cell.value);
    const updatedValue = values?.[name];

    const initialValue = isNumber(updatedValue)
      ? formatFloat(updatedValue)
      : isNumber(originalValue)
      ? formatFloat(originalValue)
      : '0.00';

    setValue(initialValue);
  }, [cell.value, name, values]);

  const handleChange = React.useCallback((e) => {
    const number = e?.target?.value;
    if (isNumeric(number)) setValue(number);
  }, []);

  const handleBlur = React.useCallback(() => {
    onChange({ row: record, name, value, originalValue: cell.value });
  }, [cell.value, name, onChange, record, value]);

  const handleDecrease = React.useCallback(() => {
    onDecrease({ row: record, name, value, originalValue: cell.value });
  }, [cell.value, name, onDecrease, record, value]);

  const handleIncrease = React.useCallback(() => {
    onIncrease({ row: record, name, value, originalValue: cell.value });
  }, [cell.value, name, onIncrease, record, value]);

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode !== 13) return;
      e.preventDefault();
      handleBlur();
    },
    [handleBlur],
  );

  return (
    <span className={classNames(['tableInput width-full'])}>
      {allowChange && isFunction(onDecrease) && (
        <img onClick={handleDecrease} src={minusJpg} width="15px" alt="decrease" />
      )}
      <input
        type="text"
        min={record?.minAllowedValues?.[name]}
        max={record?.maxAllowedValues?.[name]}
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ textAlign: 'right' }}
      />
      {allowChange && isFunction(onIncrease) && (
        <img onClick={handleIncrease} src={plusJpg} width="15px" alt="increase" />
      )}
    </span>
  );
});
ConnectedCellInput.displayName = 'CellEditBack';

export default connect((props) => {
  return { bid: props?.bidConfig?.data?.sub_types?.calColumn };
}, {})(ConnectedCellInput);
