import { DateTime } from 'luxon';

import { callApi, withQueryParams } from 'util/call-api';
import { catchError } from 'util/utils';

import { GET_SERVER_TIME } from 'constants/apiConstant';

import { SERVER_TIME_ACTIONS } from 'appRedux/reducers/serverTime';
import { store } from 'appRedux/store';

export class TimeService {
  static get state() {
    return store.getState().serverTime;
  }

  static get currentTime() {
    const { diff } = TimeService.state;
    return catchError(() => DateTime.local().plus(diff));
  }

  static startLoading() {
    return store.dispatch(SERVER_TIME_ACTIONS.startLoading());
  }

  static stopLoading() {
    return store.dispatch(SERVER_TIME_ACTIONS.stopLoading());
  }

  static async getServerTime() {
    if (TimeService.state.loading) return;
    TimeService.startLoading();
    const [err, res, raw] = await callApi(withQueryParams(GET_SERVER_TIME, { t: Math.random() }));
    const initTime = DateTime.fromISO(res?.data?.iso);
    const payload = {
      raw,
      diff: initTime.diffNow(),
      data: err ? {} : res?.data,
      error: err ? res?.data : {},
    };
    store.dispatch(SERVER_TIME_ACTIONS.setData(payload));
    TimeService.stopLoading();
    return !err ? res?.data : undefined;
  }
}

window.__DN = { ...window.__DN, TimeService };
