import React from 'react';

import { useIsMobile, useCurrentType, useLoading, usePathname } from 'util/hooks';

export const compose = (...funcs) => {
  if (funcs.length === 0) return (arg) => arg;
  if (funcs.length === 1) return funcs[0];
  return funcs.reduce((a, b) => (...args) => a(b(...args)));
};
compose.displayName = 'compose';

export const withMobile = (WrappedComponent) => {
  return function WithMobile(props) {
    const [isMobile, isTablet] = useIsMobile();
    return <WrappedComponent {...props} isMobile={isMobile} isTablet={isTablet} />;
  };
};
withMobile.displayName = 'withMobile';

export const withCurrentType = (WrappedComponent) => {
  return function WithCurrentType(props) {
    const currentType = useCurrentType();
    return <WrappedComponent {...props} currentType={currentType} />;
  };
};
withCurrentType.displayName = 'withCurrentType';

export const withPathname = (WrappedComponent) => {
  return function WithPathname(props) {
    const pathname = usePathname();
    return <WrappedComponent {...props} pathname={pathname} />;
  };
};
withPathname.displayName = 'withPathname';

export const withLoader = (...args) => (WrappedComponent) => {
  return function WithLoader(props) {
    const [loading, startLoading, stopLoading] = useLoading(...args);
    return <WrappedComponent {...props} loading={loading} startLoading={startLoading} stopLoading={stopLoading} />;
  };
};
withLoader.displayName = 'withLoader';
