import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { getBidType } from 'components/NewArrivalBidIt';

import { parseDecimal } from 'util/utils';

import FinalCalcMobile from './FinalCalcMobile';
import { calculate, formatNumber } from './SelectStone';
import SendEmailPopup from './SendEmail';

import {
  LOCAL_STORAGE_VAR,
  DIAMOND_BID,
  PROJECT_SETTINGS_TYPE,
  SELECT_STONE_TERMS,
  SELECT_STONE_TERMS_NEW,
} from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import {
  handleDownloadExcel,
  getColumn,
  handleConfirmStone,
  getProjectSetting,
  isMobile,
  getPath,
  LISTINGPAGES,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import CheckBox from '../CheckBox';
import OpenNotification from '../CommonButton/OpenNotification';
import SelectOption from '../SelectOption';

// ../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../../
// 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏

const currentType = 'FinalCalculation';
const FMTERMS = [
  { id: 'COD', name: 'COD Fixed', charge: 1 },
  { id: 'THIRTY', name: '30 Days Fixed', charge: 1.01 },
  { id: 'SIXTY', name: '60 Days Fixed', charge: 1.02 },
];

export function getExtraPer() {
  const terms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`))
    : {};
  let extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  // if (extraPer < 0)
  extraPer = extraPer * -1;
  return extraPer;
}

export function newDiamondPrice(diamond, qt = false, field = '') {
  if (diamond.isBid) diamond.fmCharge = 0;
  let extraPer = getExtraPer();
  const quotePer = qt === true ? diamond.finalquote * -1 : 0;
  const bidPer = (qt === 'bid' || diamond.isBid) && getBidType() === DIAMOND_BID.TYPE.BLIND ? 0.5 : 0;
  let pricePerCarat =
    qt === 'bid'
      ? diamond.bidPricePerCarat
      : qt === true
      ? parseDecimal(diamond.rap - (diamond.rap * quotePer) / 100)
      : diamond.ctPr;

  if (field == 'fnCtpr') {
    diamond.calcPricePerCarat = parseFloat(diamond.fnCtpr);
    diamond.calcDiscount = (parseFloat(diamond.fnCtpr) * 100) / diamond.rap - 100;
    diamond.calcAmount = parseFloat(diamond.fnCtpr) * diamond.crt;
    return diamond;
  }

  if (field == 'fnAmt') {
    diamond.calcPricePerCarat = parseFloat(diamond.fnAmt) / diamond.crt;
    diamond.calcDiscount = (parseFloat(diamond.calcPricePerCarat) * 100) / diamond.rap - 100;
    diamond.calcAmount = parseFloat(diamond.fnAmt);
    return diamond;
  }

  if (field == 'finalquote') {
    const rate = (1 + diamond.finalquote / 100) * diamond.rap;
    diamond.calcPricePerCarat =
      parseFloat(diamond.rap) + (parseFloat(diamond.finalquote) * parseFloat(diamond.rap)) / 100;
    diamond.calcDiscount = parseFloat(diamond.finalquote);
    diamond.calcAmount = diamond.calcPricePerCarat * diamond.crt;
    return diamond;
  }

  if (field === 'quote') pricePerCarat = parseDecimal((diamond.finalquote * diamond.rap) / 100 + diamond.rap);
  if (field === 'ctPr') pricePerCarat = parseDecimal(diamond.newPricePerCarat);
  if (qt && typeof qt === 'string' && qt.includes('charge') && diamond.fmCharge) {
    extraPer = 0;
    const selectedFM = qt.slice(6, qt.length);
    const newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;
    const FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
    pricePerCarat = parseDecimal(FMcharge / diamond.crt);
  }
  if (bidPer) pricePerCarat = parseDecimal(pricePerCarat - (pricePerCarat * bidPer) / 100);
  diamond.calcPricePerCarat = parseDecimal(pricePerCarat - (pricePerCarat * extraPer) / 100);
  diamond.calcDiscount =
    diamond.calcPricePerCarat && diamond.rap
      ? parseDecimal((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1)
      : 0;

  diamond.calcAmount = parseDecimal(diamond.calcPricePerCarat * diamond.crt);
  return diamond;
}

const FinalCalculations = (props) => {
  const term = localStorage.getItem('Terms');
  // 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏

  const [termCal, setTermCal] = useState(term === 'true' ? '' : term);
  // 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏

  const [columns, setColumns] = useState([]);
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [settings, setSettings] = useState([]);
  const [email, sendEmail] = useState('');

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      const grp = groupBy(settings, 'shp');
      const shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      const carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  let newdata = props.checked;
  const fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)).split('/')[1]
    : '';
  newdata = newdata.map((n) => ({
    ...n,
    fmCharge: n.isFm === 'ELIG' ? getFMCharge(n) : 0,
    //fmCharge: 0,
    isBid:
      getPath() === LISTINGPAGES.BID ||
      getPath() === LISTINGPAGES.MY_BID ||
      (getPath() === 'compare' && (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MY_BID)),
  }));
  newdata = newdata.map((x) => newDiamondPrice(x, 'charge' + fm));
  const isCmVisible = newdata.filter((el) => el.isCm && el.isCm === 'ELIG').length;

  useEffect(() => {
    getProjectSetting(
      props.checked[0].isFm === 'ELIG' ? PROJECT_SETTINGS_TYPE.FM_CHARGES : PROJECT_SETTINGS_TYPE.CM_CHARGES,
      setSettings,
    );
  }, []);

  useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      props.checked[0].isFm === 'ELIG' && !(getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MY_BID)
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value).toFixed(2)}</>,
          }
        : null,
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, []);

  const newPricedRows = checked;
  const summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);
  const totalPieces = summation.total_pieces;
  const totalCarat = parseFloat(summation.total_carat).toFixed(2);
  // const rapPrice = summation.final_rapaport;
  // const avgDisc = parseFloat(summation.final_discount).toFixed(2);
  const avgPr = summation.final_price;
  const finalValue = parseFloat(summation.final_net_value).toFixed(2);
  const promotionalDis = props.promotionDis?.data;

  const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));

  const VALUES = isMobile()
    ? {
        'Total Pieces': totalPieces,
        'Total Carats:': totalCarat,
        // 'Rap Prices': rapPrice,
        // 'Avg. Discount(%)': avgDisc,
        'Total Pr/Ct': avgPr,
        'Net Value': netValue,
      }
    : [
        { title: 'Total Pieces', value: totalPieces },
        { title: 'Total Carats', value: totalCarat },
        // { title: 'Rap Prices', value: rapPrice },
        // { title: 'Avg. Discount(%)', value: avgDisc },
        { title: 'Total Pr/Ct', value: avgPr },
        { title: 'Net Value', value: netValue },
      ];

  props.promotionDis.data !== 0 && VALUES.push({ title: 'promotional Dis(%)', value: props.promotionDis?.data });
  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

  // checkCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheck()
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };

  const submit = () => {
    // !!!checkCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheckCheck()
    if (!checkCheck()) return;
    // arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg, arg,
    handleConfirmStone(
      checked.map((x) => x.id),
      '',
      moment().toISOString(),
      user && user.account ? user.account.companyName : '',
      (flag) => {
        props.onClose();
        props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
    );
  };

  return isMobile() ? (
    <FinalCalcMobile
      data={newdata}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ submit, checkCheck, onClose: props.onClose }}
      noConfirmButton={props.noConfirmButton}
    />
  ) : (
    <div>
      <h2 className="popupInnerTitle">
        <IntlMessages id="app.FinalCalculation" />
      </h2>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll">
          <Table
            data={newdata}
            columns={columns}
            areAllChecked={true}
            nodots
            noGrp
            currentType={currentType}
            canSort={false}
          />
        </div>
        <div className="mt-10 finalCalDetail">
          {props.checked[0].isFm === 'ELIG' &&
          !(getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MY_BID) ? (
            <SelectOption
              className="finalFMSelect"
              selectValue={FMTERMS}
              label="FM Terms*"
              value={fm}
              onChange={(e) => setFm(e)}
            />
          ) : isCmVisible ? (
            <CheckBox label="CM Charge" checked={cm} onChange={() => setCm(!cm)} />
          ) : null}
          <div className="DiamondDetailPopup mt-10">
            {VALUES.map((x, key) => (
              <div key={key} className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>
                  {x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)'
                    ? x.value > 0
                      ? `+${x.value}`
                      : x.value
                    : x.title === 'promotional Dis(%)'
                    ? parseFloat(finalValue) > props.promotionDis?.maxAmt
                      ? `${x.value} ($${Math.abs(
                          Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100),
                        )})`
                      : `${x.value} ($${Math.abs(promotionalDis)})`
                    : x.value}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="sideBarPopupButton">
          {/* {!props.noConfirmButton && (
                        <a className='commonButton' onClick={submit}>
                            <IntlMessages id='app.confirmStone' />
                        </a>
                    )} */}
          <a className="commonButton" onClick={props.onClose}>
            <IntlMessages id="app.CancelStone" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before excel export',
                });
              } else {
                handleDownloadExcel(
                  {},
                  // 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏
                  checked.map((x) => x.id),
                  () => {},
                  // 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏
                );
              }
            }}
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            // disabled={!termCal}
            onClick={() => {
              if (!checked.length) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
              } else if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before email stock',
                });
              } else {
                sendEmail('stock');
              }
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            // disabled={!termCal}
            className="commonButton"
            onClick={() => {
              if (!checked.length) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
              } else if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before email pic',
                });
              } else {
                sendEmail('img');
              }
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
        </div>
      </div>
      {email !== '' && (
        <SendEmailPopup
          sendEmail={email}
          onCancel={() => sendEmail('')}
          // 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏 👏
          ids={checked.map((x) => x.id)}
          img={email === 'img'}
          isStock={email === 'stock'}
        />
      )}
    </div>
  );
};

export default FinalCalculations;
