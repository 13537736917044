import React from 'react';
import { useSelector } from 'react-redux';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import Table from 'components/DiamondList/TableBack';
import { Heading } from 'components/common/Heading';

import { BidService } from 'services/BidService';
import { RowSelectService } from 'services/RowSelectService';
import { TableValuesService } from 'services/TableValueService';

import { IntlMessage } from 'util/IntlMessages';
import { callApi } from 'util/call-api';
import { useIsMobile, useSelectedRows } from 'util/hooks';
import { notify } from 'util/notify';
import { castToNumber, isEmpty } from 'util/utils';

import { CREATE_BID } from 'constants/apiConstant';

import { BidDrawerCalc, BidDrawerHeaderCalc } from './BidDrawerCalc';
import BidStoneMobile from './BidStoneMobile';
import { BID_STONE_COLUMNS } from './_columns';
import { BID_STONE_TYPE } from './_constants';
import { getUpdatedBidList } from './_functions';

import { getColumn } from '../../../DiamondList/DiamondListFunctions';

const tableValues = new TableValuesService({ table: BID_STONE_TYPE });

const prepareStonListForBid = (list) => {
  return list.map((stone) => {
    stone.hasBidDiscount = !isEmpty(castToNumber(stone.bidDiscount ?? stone.back));
    stone.hasBidPricePerCarat = !isEmpty(castToNumber(stone.bidPricePerCarat ?? stone.ctPr));
    stone.hasBidPrAmount = !isEmpty(castToNumber(stone.bidAmount ?? stone.amt));

    stone.bidDiscount = castToNumber(stone.bidDiscount ?? stone.back, 0);
    stone.bidPricePerCarat = castToNumber(stone.bidPricePerCarat ?? stone.ctPr, 0);
    stone.bidAmount = castToNumber(stone.bidAmount ?? stone.amt, 0);

    stone.calcPricePerCarat = stone.bidPricePerCarat;
    stone.calcDiscount =
      stone.calcPricePerCarat && stone.rap ? (1 - stone.calcPricePerCarat / stone.rap) * 100 * -1 : 0;
    stone.calcAmount = stone.calcPricePerCarat * stone.crt;

    stone.bidDiscount = (() => {
      const backIsNegative = stone.ctPr < stone.rap;
      return (backIsNegative ? -1 : 1) * Math.abs(stone.bidDiscount);
    })();

    return stone;
  });
};

export default function BidStone(props) {
  const { onClose } = props;

  const [isMobile] = useIsMobile();
  const [selectedRows] = useSelectedRows(props.currentType);

  const bidConfig = useSelector((store) => store.bidConfig.data ?? {});
  const isAuction = useSelector((store) => store.bidConfig.data?.type === BidService.BID_TYPES.AUCTION);

  const list = React.useMemo(() => prepareStonListForBid(selectedRows), [selectedRows]);

  const columns = React.useMemo(() => {
    const _columns = getColumn();
    const col = [..._columns];

    const back = [
      {
        Header: 'Disc %',
        accessor: 'back',
        id: 'back',
      },
    ];
    const lbNmIndex = col.findIndex((col) => col.id === 'lbNm');
    col.splice(lbNmIndex + 1, 0, ...back);

    const getColumnIndex = (name) => col.findIndex((col) => col?.id === name);
    const removeAndReturn = (index) => (index > -1 ? col.splice(index, 1)?.[0] : undefined);
    const appendIfExists = (index, ...args) => (index > -1 ? col.splice(index + 1, 0, ...args) : undefined);

    const COLUMN_BACK = removeAndReturn(getColumnIndex('back'));
    const COLUMN_CTPR = removeAndReturn(getColumnIndex('ctPr'));
    const COLUMN_AMT = removeAndReturn(getColumnIndex('amt'));
    appendIfExists(getColumnIndex('lbNm'), COLUMN_BACK, COLUMN_CTPR, COLUMN_AMT);

    const { BID_BACK, BID_CTPR, BID_AMT } = BID_STONE_COLUMNS;
    appendIfExists(getColumnIndex('amt'), BID_BACK, BID_CTPR, BID_AMT);

    if (isAuction) {
      const { MAX_BID_BACK, MAX_BID_CTPR, MAX_BID_AMT } = BID_STONE_COLUMNS;
      appendIfExists(getColumnIndex('bidAmount'), MAX_BID_BACK, MAX_BID_CTPR, MAX_BID_AMT);
    }
    return _columns, col;
  }, [isAuction]);

  const handleSubmit = React.useCallback(async () => {
    const selectedRows = getUpdatedBidList();

    if (isEmpty(selectedRows)) {
      notify.error({ message: 'Please select stone(s) to bid.' });
      return;
    }

    const hasStonesWithSameValues = selectedRows.some(
      (stone) =>
        castToNumber(stone.bidDiscount, 0) === castToNumber(stone.back, 0) ||
        castToNumber(stone.bidPricePerCarat, 0) === castToNumber(stone.ctPr, 0),
    );

    if (hasStonesWithSameValues) {
      notify.error({ message: 'Bid discount and discount can not be same for bid.' });
      return;
    }

    const [err, res] = await callApi({
      ...CREATE_BID,
      payload: {
        bidConfigurationId: bidConfig.id,
        subTypeCode: bidConfig.sub_types?.subTypeCode,
        result_type: parseFloat(bidConfig.sub_types?.result),
        diamonds: selectedRows.map((stone) => ({
          diamond: stone.id,
          bidPricePerCarat: stone.bidPricePerCarat,
          bidAmount: stone.bidAmount,
          bidDiscount: stone.isFcCol ? 0 : stone.bidDiscount,
          trackPricePerCarat: stone.ctPr,
          trackAmount: stone.amt,
          trackDiscount: stone.back,
          vnd: stone.vnd,
          vStnId: stone.vStnId,
        })),
      },
    });

    err
      ? notify.error({ message: err && err.data ? err.data.message : res.message })
      : notify.success({ message: res.message });

    void onClose?.(BID_STONE_TYPE);
  }, [bidConfig.id, bidConfig.sub_types?.result, bidConfig.sub_types?.subTypeCode, onClose]);

  const handleClose = React.useCallback(() => {
    void onClose?.();
  }, [onClose]);

  React.useEffect(() => {
    return () => {
      tableValues.resetValues();
    };
  }, []);

  React.useEffect(() => {
    RowSelectService.selectRows(BID_STONE_TYPE, list, true);
  }, [list]);

  if (isMobile) {
    return <BidStoneMobile list={list} onSubmit={handleSubmit} onClose={handleClose} />;
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading className="popupInnerTitle mr-20">
          <IntlMessage id="app.bidStone" />
        </Heading>
        <BidDrawerHeaderCalc />
      </div>
      <div className="searchPopupCommonTable">
        <GridHeightContainer className="searchResultTable tabInnerTableScroll">
          <Table
            currentType={BID_STONE_TYPE}
            columns={columns}
            data={list}
            bidStatus={bidConfig.sub_types?.subTypeCode}
            FilterOption={false}
            canSort={false}
            noResetOnInit
            noSticky
            nodots
            noGrp
          />
        </GridHeightContainer>
        <div className="mt-10 finalCalDetail">
          <BidDrawerCalc />
        </div>
      </div>
      <div className="sideBarPopupButton">
        <a id="bid-stone" className="commonButton" onClick={handleSubmit}>
          <IntlMessage id="app.bidStone" />
        </a>
        <a className="commonButton" onClick={handleClose}>
          <IntlMessage id="app.CancelBid" />
        </a>
      </div>
    </React.Fragment>
  );
}
