import React from 'react';

import IntlMessages from '../util/IntlMessages';

export const DetailView = {
  section1: {
    name: <IntlMessages id="app.basicDetail" />,
    data: [
      {
        part: 1,
        label: <IntlMessages id="app.StnID" />,
        key: 'vStnId',
      },
      {
        part: 1,
        label: <IntlMessages id="app.carat" />,
        key: 'crt',
      },
      // {
      //   part: 1,
      //   label: <IntlMessages id="app.Rapaport" />,
      //   key: "rap",
      // },
      {
        part: 1,
        label: <IntlMessages id="app.Shade" />,
        key: 'shdNm',
      },
      // {
      //   part: 1,
      //   label: <IntlMessages id="app.ReportNo" />,
      //   key: "rptNo",
      // },
      // {
      //   part: 1,
      //   label: <IntlMessages id="app.Lab" />,
      //   key: "lbNm",
      // },
      {
        part: 2,
        label: <IntlMessages id="app.Shape" />,
        key: 'shpNm',
      },
      {
        part: 2,
        label: <IntlMessages id="app.Color" />,
        key: (isFcCol) => (isFcCol ? 'fcColNm' : 'colNm'),
      },
      // {
      //   part: 2,
      //   label: <IntlMessages id="app.Disc%" />,
      //   key: "back",
      // },
      // {
      //   part: 2,
      //   label: <IntlMessages id="app.rapindlr" />,
      //   key: "rap",
      // },
      {
        part: 3,
        label: <IntlMessages id="app.lab" />,
        key: 'lbNm',
      },
      {
        part: 3,
        label: <IntlMessages id="app.Clarity" />,
        key: 'clrNm',
      },
      // {
      //   part: 3,
      //   label: <IntlMessages id="app.compare.pricepercarat" />,
      //   key: "ctPr",
      // },
      {
        part: 4,
        label: <IntlMessages id="app.certNo" />,
        key: 'rptNo',
      },
      // {
      //   part: 4,
      //   label: <IntlMessages id="app.Amount" />,
      //   key: "amt",
      // },
    ],
  },
};

export const PriceDetail = {
  section2: {
    name: <IntlMessages id="app.priceDetail" />,
    data: [
      // {
      //   part: 1,
      //   label: <IntlMessages id="app.RapPrice" />,
      //   key: 'rap',
      // },
      // {
      //   part: 2,
      //   label: <IntlMessages id="app.Disc%" />,
      //   key: 'back',
      // },
      {
        part: 3,
        label: <IntlMessages id="app.compare.pricepercarat" />,
        key: 'ctPr',
      },
      {
        part: 4,
        label: <IntlMessages id="app.StnAmt" />,
        key: 'amt',
      },
    ],
  },
};

export const MakeDetail = {
  section3: {
    name: <IntlMessages id="app.makeDetails" />,
    data: [
      {
        part: 1,
        label: <IntlMessages id="app.cut" />,
        key: 'cutNm',
      },
      {
        part: 2,
        label: <IntlMessages id="app.polish" />,
        key: 'polNm',
      },
      {
        part: 3,
        label: <IntlMessages id="app.symm" />,
        key: 'symNm',
      },
      {
        part: 4,
        label: <IntlMessages id="app.flor" />,
        key: 'fluNm',
      },
      // {
      //   label: <IntlMessages id="app.crHt" />,
      //   key: "cHgt",
      // },
      // {
      //   label: <IntlMessages id="app.length" />,
      //   key: "length",
      // },
      // {
      //   label: <IntlMessages id="app.pavHt" />,
      //   key: "pHgt",
      // },
      // {
      //   label: <IntlMessages id="app.width" />,
      //   key: "width",
      // },
      // {
      //   label: <IntlMessages id="app.Girdle" />,
      //   key: "girdleStr",
      // },
      // {
      //   label: <IntlMessages id="app.depth" />,
      //   key: "height",
      // },
      // {
      //   label: <IntlMessages id="app.culet" />,
      //   key: "cultNm",
      // },
      // {
      //   label: (
      //     <IntlMessages id="app.diamonddetails.Additionalinfo.laserinscription" />
      //   ),
      //   key: "lsrInc",
      // },
      // {
      //   label: <IntlMessages id="app.crAn" />,
      //   key: "cAng",
      // },
    ],
  },
};

export const ParamDetail = {
  section4: {
    name: <IntlMessages id="app.paraDetail" />,
    data: [
      {
        part: 1,
        label: <IntlMessages id="app.measurement" />,
        key: 'msrmnt',
      },
      {
        part: 1,
        label: <IntlMessages id="app.crAn" />,
        key: 'cAng',
      },
      {
        part: 1,
        label: <IntlMessages id="app.diamonddetails.Additionalinfo.Girdle" />,
        key: 'grdlPer',
      },
      {
        part: 2,
        label: <IntlMessages id="app.compare.tableper" />,
        key: 'tblPer',
      },
      {
        part: 2,
        label: <IntlMessages id="app.crHt" />,
        key: 'cHgt',
      },
      {
        part: 2,
        label: <IntlMessages id="app.diamonddetails.Additionalinfo.StarLength" />,
        key: 'strL',
      },
      {
        part: 3,
        label: <IntlMessages id="app.total-depth" />,
        key: 'depPer',
      },
      {
        part: 3,
        label: <IntlMessages id="app.pavAn" />,
        key: 'pAng',
      },
      {
        part: 3,
        label: <IntlMessages id="app.diamonddetails.Additionalinfo.LowerHalf" />,
        key: 'lwrHal',
      },
      {
        part: 4,
        label: <IntlMessages id="app.ratio" />,
        key: 'ratio',
      },
      {
        part: 4,
        label: <IntlMessages id="app.pavDepth" />,
        key: 'pHgt',
      },
      // {
      //   label: <IntlMessages id="app.CenterNatts" />,
      //   key: "blkTblNm",
      // },
      // {
      //   label: <IntlMessages id="app.SideNatts" />,
      //   key: "blkSdNm",
      // },
      // {
      //   label: <IntlMessages id="app.CenterWhite" />,
      //   key: "wTblNm",
      // },
      // {
      //   label: <IntlMessages id="app.SideWhite" />,
      //   key: "wSdNm",
      // },
      // {
      //   label: <IntlMessages id="app.CrownOpen" />,
      //   key: "opCrwnNm",
      // },
      // {
      //   label: <IntlMessages id="app.PavilionOpen" />,
      //   key: "opPavNm",
      // },
      // {
      //   label: <IntlMessages id="app.Brilliancy" />,
      //   key: "brlncyNm",
      // },
      // {
      //   label: <IntlMessages id="app.Type2Cert" />,
      //   key: "type2Nm",
      // },
      // {
      //   label: <IntlMessages id="app.CountryOfOrigin" />,
      //   key: "locNm",
      // },
      // {
      //   label: <IntlMessages id="app.RoughMine" />,
      //   key: "",
      // },
    ],
  },
};
export const InclusionDetailOne = {
  section4: {
    name: <IntlMessages id="app.diamonddetails.Inclusion" />,
    data: [
      {
        part: 1,
        label: <IntlMessages id="app.diamonddetails.Inclusion.whitetable" />,
        key: 'wTblNm',
      },
      {
        part: 1,
        label: <IntlMessages id="app.diamonddetails.Inclusion.blacktable" />,
        key: 'blkTblNm',
      },
      {
        part: 1,
        label: <IntlMessages id="app.TableOpen" />,
        key: 'opTblNm',
      },
      {
        part: 2,
        label: <IntlMessages id="app.Side" />,
        key: 'wSdNm',
      },
      {
        part: 2,
        label: <IntlMessages id="app.SideBlack" />,
        key: 'blkSdNm',
      },
      {
        part: 2,
        label: <IntlMessages id="app.SideOpen" />,
        key: 'opIncNm',
      },
      {
        part: 3,
        label: <IntlMessages id="app.eye-clean" />,
        key: 'eClnNm',
      },
      {
        part: 3,
        label: <IntlMessages id="app.diamonddetails.Inclusion.luster" />,
        key: 'lstrNm',
      },
      {
        part: 3,
        label: <IntlMessages id="app.diamonddetails.Inclusion.heartandaerrow" />,
        key: 'hANm',
      },
      // {
      //   label: <IntlMessages id="app.keyToS" />,
      //   key: "kToSStr",
      // },
      // {
      //   hide: true,
      // },
      // {
      //   label: <IntlMessages id="app.ReportComments" />,
      //   key: "lbCmt",
      // },
    ],
  },
};

export const InclusionDetailTwo = {
  section5: {
    name: <IntlMessages id="app.other-details" />,
    data: [
      {
        label: <IntlMessages id="app.keyToS" />,
        key: 'kToSStr',
      },
      // {
      //   hide: true,
      // },
      {
        label: <IntlMessages id="app.ReportComments" />,
        key: 'lbCmt',
      },
    ],
  },
};
