import { LoaderActions } from 'appRedux/reducers/loader';
import { store } from 'appRedux/store';

export class LoaderService {
  static startLoading() {
    return store.dispatch(LoaderActions.start());
  }

  static stopLoading() {
    return store.dispatch(LoaderActions.stop());
  }

  static adjustCount(count) {
    return store.dispatch(LoaderActions.adjust({ count }));
  }

  static resetLoading() {
    return store.dispatch(LoaderActions.reset());
  }
}

const addGlobals = () => {
  window.__DN = { ...window.__DN, LoaderService };
};
addGlobals();

void module?.hot?.accept?.(['./LoaderService.js'], () => addGlobals());
