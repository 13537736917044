import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

import DNA from 'components/DNA';

import { isEmpty } from 'util/utils';

export const DNARoute = React.memo((props) => {
  const isLoggedIn = useSelector((store) => !isEmpty(store?.auth?.token));
  const location = useLocation();
  const pathname = location.pathname;

  const newPathname = React.useMemo(() => {
    if (isLoggedIn) return pathname.replace('dna', 'diamond-detail');
    return pathname;
  }, [isLoggedIn, pathname]);

  if (newPathname === pathname) return <DNA {...props} />;
  return <Route render={(routeProps) => <Redirect to={{ ...routeProps.location, pathname: newPathname }} />} />;
});
DNARoute.displayName = 'DNARoute';
