import { DateTime } from 'luxon';

import { Storage } from 'services/Storage';
import { TimeService } from 'services/TimeService';

import { callApi, withQueryParams } from 'util/call-api';
import { isEmpty } from 'util/utils';

import { GET_SERVER_TIME, BID_CONFIG } from 'constants/apiConstant';

import { BID_ACTIONS } from 'appRedux/reducers/bidConfig';
import { store } from 'appRedux/store';

export class BidService {
  static get state() {
    return store.getState().bidConfig;
  }

  static get BID_TYPES() {
    return {
      TENDER: 1,
      AUCTION: 3,
    };
  }

  static get GET_BID_CONFIG_PARAMS() {
    return {
      TENDER: {
        type: BidService.BID_TYPES.TENDER,
        t: Math.random(),
      },
      AUCTION: {
        type: BidService.BID_TYPES.AUCTION,
        t: Math.random(),
      },
    };
  }

  static get bidConfig() {
    return BidService.state.data ?? {};
  }

  static get isAuction() {
    return BidService.bidConfig.type === BidService.BID_TYPES.AUCTION;
  }

  static get isTender() {
    return BidService.bidConfig.type === BidService.BID_TYPES.TENDER;
  }

  static get subTypes() {
    return BidService.bidConfig.sub_types ?? {};
  }

  static get diffUpward() {
    return BidService.subTypes.upwardInterval ?? 0;
  }

  static get diffDownward() {
    return BidService.subTypes.downwardInterval ?? 0;
  }

  static get stepUp() {
    return Math.abs(BidService.subTypes.diffInterval ?? 1);
  }

  static get stepDown() {
    return -Math.abs(BidService.subTypes.diffInterval ?? 1);
  }

  static get calculateOnDiscount() {
    return ['all', 'back'].includes(BidService.subTypes.calColumn);
  }

  static get calculateOnPricePerCarat() {
    return ['all', 'ctPr'].includes(BidService.subTypes.calColumn);
  }

  static get bidEndTime() {
    return DateTime.fromISO(BidService.subTypes?.currentBidEndDate);
  }

  static get bidEndDuration() {
    return BidService.bidEndTime.diff(TimeService.currentTime, ['days', 'hours', 'minutes', 'seconds']);
  }

  static startLoading() {
    return store.dispatch(BID_ACTIONS.startLoading());
  }

  static stopLoading() {
    return store.dispatch(BID_ACTIONS.stopLoading());
  }

  static async getCurrentTime() {
    const [err, res] = await callApi(withQueryParams(GET_SERVER_TIME, { t: Math.random() }));
    return !err ? res?.data : undefined;
  }

  static async fetchBid(params) {
    const [err, res, raw] = await callApi({ ...withQueryParams(BID_CONFIG, params), showLoader: true });
    const payload = {
      raw,
      data: err ? {} : res?.data,
      error: err ? res?.data : {},
    };
    store.dispatch(BID_ACTIONS.setBidConfig(payload));
    return payload?.data;
  }

  static async fetchTender(params = BidService.GET_BID_CONFIG_PARAMS.TENDER) {
    const bidConfig = await BidService.fetchBid(params);
    return bidConfig ?? {};
  }

  static async fetchAuction(params = BidService.GET_BID_CONFIG_PARAMS.AUCTION) {
    const bidConfig = await BidService.fetchBid(params);
    return bidConfig ?? {};
  }

  static async fetchRunningBid() {
    if (!TimeService.currentTime?.isValid) return;
    if (BidService.state.loading) return;
    BidService.startLoading();
    Storage.delete('bid-doc');
    let output = undefined;
    if (isEmpty(output)) output = await BidService.fetchTender();
    if (isEmpty(output)) output = await BidService.fetchAuction();
    BidService.stopLoading();
  }
}

window.__DN = { ...window.__DN, BidService };
