import { Action } from 'services/Action';

import { hasKey, isArray, isString, logWarn } from 'util/utils';

export const STORAGE_PREFIX = 'daf';

/**
 * Storage class
 * Transforms and stores data in Storage.driver
 * @note: Keep all methods static (so they can be accessed directly)
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export class Storage {
  static driver = localStorage;

  static updateKeyEvent = new Action('@storage/key-updated');

  static get storagePrefix() {
    return `${STORAGE_PREFIX}-`;

    // const keyPrefix = 'rra';
    // const driverPrefix =
    //   Storage.driver === localStorage ? 'local' : Storage.driver === sessionStorage ? 'session' : '@other';
    // return `@${keyPrefix}/${driverPrefix}/`;
  }

  static getStorageKey(key) {
    return key.startsWith(Storage.storagePrefix) ? key : `${Storage.storagePrefix}${key}`;
  }

  static getSenitizedKey(key) {
    return key.startsWith(Storage.storagePrefix) ? key.substr(Storage.storagePrefix?.length) : key;
  }

  static parseValue(value, toBinary = true) {
    return toBinary ? JSON.parse(atob(value)) : JSON.parse(value);
  }

  static stringifyValue(value, toAscii = true) {
    return toAscii ? btoa(JSON.stringify(value)) : JSON.stringify(value);
  }

  /**
   * Store
   * @param {String} id
   * @param {*} value
   */
  static set(id, value, encode = true) {
    const name = Storage.getSenitizedKey(id);
    const key = Storage.getStorageKey(name);
    try {
      Storage.driver.setItem(key, Storage.stringifyValue(value, encode));
      Storage.updateKeyEvent.emit({ [name]: value });
      return true;
    } catch (error) {
      logWarn(error);
    }
  }

  /**
   * Retrieve
   * @param {String} name
   */
  static get(name, decode = true) {
    const key = Storage.getStorageKey(name);

    try {
      return Storage.parseValue(Storage.driver.getItem(key), decode);
    } catch (error) {
      if (error) logWarn(error);
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} list String or Array of string
   */
  static delete(list) {
    list = isArray(list) ? list : isString(list) ? [list] : [];
    list.forEach((id) => {
      const name = Storage.getSenitizedKey(id);
      const key = Storage.getStorageKey(id);
      try {
        Storage.driver.removeItem(key);
        Storage.driver.removeItem(name);
        Storage.updateKeyEvent.emit({ [name]: undefined });
      } catch (error) {
        logWarn(error);
      }
    });
  }

  static deleteAll(except = ['up', 'r-up']) {
    const storageKeys = Object.keys(Storage.driver).map(Storage.getSenitizedKey);
    except.forEach((name) => storageKeys.splice(storageKeys.indexOf(name), 1));
    Storage.delete(storageKeys);
  }

  static listen(id, callback) {
    const name = Storage.getSenitizedKey(id);
    const unlisten = Storage.updateKeyEvent.listen((event) => {
      if (!hasKey(event?.detail, name)) return;
      callback({ [name]: event?.detail?.[name] });
    });
    return unlisten;
  }
}

window.__DN = { ...window.__DN, Storage };
