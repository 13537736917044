import React from 'react';

import { Select } from 'antd';
import './selectOption.less';

const { Option } = Select;

const SelectOption = (props) => {
  const selectValue = props?.selectValue;
  let groupClass = 'formSelect from-group';
  if (props.hasError) {
    groupClass = groupClass + ' erroroMess';
  }
  return (
    <div className={groupClass}>
      <label>{props.label}</label>
      {props.register ? (
        <Select
          getPopupContainer={(trigger) => {
            return trigger;
          }}
          dropdownClassName="selectDopdown"
          className={props.className ? props.className : null}
          mode={props.mode ? props.mode : null}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder ? props.placeholder : null}
          onChange={props.onChange ? props.onChange : null}
          value={props.value}
          disabled={props.disabled}
          showSearch={props.showSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSearch={(e) => {
            props.getFil(e);
          }}
        >
          {props.citySearch && props.citySearch.length < 2 && selectValue.length === 0 && (
            <Option value="word" disabled>
              Please enter city name to search
            </Option>
          )}
          {selectValue.map((d, index) => (
            <Option key={index} value={d.id}>
              {d.name}
            </Option>
          ))}
        </Select>
      ) : (
        <>
          {props?.mul ? (
            <Select
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              dropdownClassName="selectDopdown"
              className={props.className ? props.className : null}
              mode={props.mode ? props.mode : null}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder ? props.placeholder : null}
              onChange={props.onChange ? props.onChange : null}
              value={props.value}
              disabled={props.disabled}
              showSearch={props.showSearch}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
            >
              {selectValue?.map((d, index) => (
                <Option key={index} value={d.id[0]}>
                  {d.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              dropdownClassName="selectDopdown"
              className={props.className ? props.className : null}
              mode={props.mode ? props.mode : null}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder ? props.placeholder : null}
              onChange={props.onChange ? props.onChange : null}
              value={props.value}
              disabled={props.disabled}
              showSearch={props.showSearch}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {selectValue.map((d, index) => (
                <Option key={index} value={d.id}>
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </>
      )}
      <span style={{ color: 'red' }}>{props.hasError ? props.hasError : ''}</span>
    </div>
  );
};
export default SelectOption;
