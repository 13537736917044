import React, { memo } from 'react';

import { last, split } from 'lodash';
import moment from 'moment';

import NoDataShow from 'components/common/NoDataShow';

import { getPath, LISTINGPAGES } from './DiamondListFunctions';

import { ListingCards } from '../common/DiamondListing/MobileComfirmStone';
import Heading from '../common/Heading';
import MobileGroupCard from '../common/MobileDiamondList/MobileGroupCard';

const emptyData = (loading, length) => {
  const module = last(split(window.location.pathname, '/'));
  const data = module.replace('-', ' ');
  if (loading) return <></>;
  //<NoDataShow message={<Spin />} />;
  else if (length === 0)
    return (
      <NoDataShow
        message={
          getPath() === LISTINGPAGES.WATCH_LIST ||
          getPath() === LISTINGPAGES.REMINDER ||
          getPath() === LISTINGPAGES.QUOTE
            ? `Your ${data} is empty.`
            : getPath() === LISTINGPAGES.NOTE
            ? 'Your note list is empty.'
            : getPath() === LISTINGPAGES.OFFICE
            ? 'No view request found.'
            : getPath() === LISTINGPAGES.CONFIRM
            ? 'No confirm diamonds found.'
            : getPath() === LISTINGPAGES.HOLD
            ? 'No hold diamonds found.'
            : getPath() === LISTINGPAGES.MATCH_PAIR
            ? 'No match pair found for requested search criteria.'
            : getPath() === LISTINGPAGES.ENQUIRY
            ? 'Your enquiry list is empty.'
            : getPath() === LISTINGPAGES.CART
            ? 'Your cart list is empty.'
            : getPath() === LISTINGPAGES.MATCH_PAIR
            ? 'No match pair found for requested search criteria.'
            : getPath() === LISTINGPAGES.SPECIAL_STONE
            ? 'No diamonds added in special stone.'
            : getPath() === LISTINGPAGES.STONE_OF_DAY
            ? 'No diamonds added in feature stone.'
            : getPath() === LISTINGPAGES.UPCOMING
            ? 'No diamonds added in upcoming.'
            : ''
        }
      />
    );
  else return <></>;
};

const getGroupedData = (arr, key) => {
  const data = [];
  let iter = -1;
  arr.map((x) => {
    if (x[key]) iter += 1;
    if (!data[iter]) data[iter] = [];
    data[iter].push(x);
    return true;
  });
  return data;
};

const MobileViewList = (props) => {
  // state = {}

  // showDrawer = () => {
  //     this.setState({
  //         visible: true
  //     })
  // }
  // onClose = () => {
  //     this.setState({
  //         filterOpen: false,
  //         visible: false
  //     })
  // }
  // filter = () => {
  //     this.setState({
  //         filterOpen: true
  //     })
  // }

  const self = props.parent;
  const groupData =
    getPath() === LISTINGPAGES.QUOTE
      ? props.data.map((dt) => [dt])
      : getPath() === LISTINGPAGES.OFFICE
      ? getGroupedData(props.data, 'isOfficeHeader')
      : getPath() === LISTINGPAGES.MATCH_PAIR
      ? getGroupedData(props.data, 'isMatchHeader')
      : getPath() === LISTINGPAGES.CONFIRM
      ? getGroupedData(props.data, 'isConfirmHeader')
      : null;
  // <MobileGroupCard title="ROUND 0.21 H IF 2.96% EX EX EX 1297.3$/Cts">
  const title = (item) =>
    getPath() === LISTINGPAGES.QUOTE
      ? moment(item.updatedAt).format('DD/MM/YYYY')
      : getPath() === LISTINGPAGES.OFFICE
      ? moment(item.date).format('DD/MM/YYYY') +
        ' - [' +
        moment(item.cabinSlot[0].start).format('hh:mm A') +
        ' - ' +
        moment(item.cabinSlot[0].end).format('hh:mm A') +
        ']'
      : getPath() === LISTINGPAGES.MATCH_PAIR
      ? item.groupingTitle
      : getPath() === LISTINGPAGES.CONFIRM
      ? item.memoNo
      : // : getPath() === LISTINGPAGES.UPCOMING
        // ? moment(item.inDt).format('DD/MM/YYYY') + ' 3 PM IST'
        null;

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={self.getTitle()} />
            {self.getListTop ? self.getListTop() : null}
            {/* {!isaccount && (
                                <div className='d-flex align-items-center mobileRightAction'>
                                    <div className='gridListIcon' onClick={self.changeGridView}>
                                        {props.listview ? (
                                            <img src={require('../../assets/svg/DiamondList/grid.svg')} alt='griIcon' />
                                        ) : (
                                            <img src={require('../../assets/svg/DiamondList/list.svg')} alt='griIcon' />
                                        )}
                                    </div>
                                    {getPath() === LISTINGPAGES.LIST && (
                                        <div className='filterOption' onClick={this.filter}>
                                            <span className='filterStatus'>3</span>
                                            <img
                                                src={require('../../assets/svg/DiamondList/filter.svg')}
                                                alt='filter'
                                            />
                                        </div>
                                    )}
                                </div>
                            )} */}
          </div>
          {self.getSelection ? self.getSelection() : null}
        </div>
      </div>

      <div className="searchInnerResult">
        {props.listView ? (
          <>
            <div className="diamondListMobile">
              {groupData ? (
                groupData.map((lItem) => {
                  return getPath() === LISTINGPAGES.UPCOMING ? (
                    <ListingCards
                      quotePage={getPath() === LISTINGPAGES.UPCOMING}
                      data={props.data}
                      currentType={props.currentType}
                    />
                  ) : (
                    <MobileGroupCard title={title(lItem[0])}>
                      <ListingCards
                        quotePage={getPath() === LISTINGPAGES.QUOTE}
                        data={lItem}
                        currentType={props.currentType}
                      />
                    </MobileGroupCard>
                  );
                })
              ) : (
                <ListingCards data={props.data} currentType={props.currentType} />
              )}
              {emptyData(props.loading, props.data.length)}
            </div>
          </>
        ) : (
          <div className="searchResultGridView">{self.getGridView()}</div>
        )}
        {self.getAction()}
      </div>
      {/* <Drawer

                onClose={this.onClose}
                visible={this.state.filterOpen}
                wrapClassName='mobileFilterSideBar'
            >
                <MobileFilterSidebar />
            </Drawer> */}
    </div>
  );
};

export default MobileViewList;
