import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DiamondActions } from 'appRedux/reducers/diamond';
import { isArray } from 'util/utils';

const useRowSelect = (currentType) => {
  const dispatch = useDispatch();
  const selectedRowIds = useSelector((state) => state?.diamondData?.selectedRowIds?.[currentType]);

  const toggleRowSelection = React.useCallback(
    (row, select = true) => {
      if (!row?.id) return;

      select
        ? dispatch(DiamondActions.selectRows({ payload: row, currentType }))
        : dispatch(DiamondActions.unSelectRows({ payload: row, currentType }));
    },
    [currentType, dispatch],
  );

  const toggleAllRowSelection = React.useCallback(
    (rows = [], replace = false) => {
      const areAllChecked = rows.every((row) => selectedRowIds?.includes(row?.selectionKey ?? row?.id));
      areAllChecked
        ? dispatch(DiamondActions.resetSelectedRows({ currentType }))
        : dispatch(DiamondActions.selectRows({ payload: rows, currentType, replace }));
    },
    [currentType, dispatch, selectedRowIds],
  );

  const isHeaderChecked = React.useCallback(
    (rows) => {
      if (!isArray(rows)) return;
      const allRowIds = rows.map((row) => row?.selectionKey ?? row?.id);
      return Boolean(allRowIds.every((id) => selectedRowIds?.includes(id)));
    },
    [selectedRowIds],
  );

  const isRowChecked = React.useCallback((rowId) => selectedRowIds?.includes(rowId), [selectedRowIds]);

  const clearAllRows = React.useCallback(() => dispatch(DiamondActions.resetSelectedRows()), [dispatch]);

  return {
    isRowChecked,
    isHeaderChecked,
    toggleRowSelection,
    toggleAllRowSelection,
    clearAllRows,
  };
};

export default useRowSelect;
