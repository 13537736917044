import { formatFloat, isArray, isEmpty, isNumber, isObject, logInfo, parseDecimal, reduceTotal } from './utils';

// NOTE: Local const for precision, don't assign less than 5
// NOTE: Do not decrease precision or cast numbers to string
const PRECISION = 6;

const calcStoneOptions = { field: 'amt', addTo: 'back' };

export const percentDiff = (originalNumber, newNumber, fractionLength = PRECISION) => {
  return parseDecimal(100 * ((newNumber - originalNumber) / originalNumber), fractionLength);
};

/**
 *
 * @param {Number} value pass back, ctPr or amt
 * @param {calcStoneOptions} options
 * @returns
 */
export const calcStoneValues = (value, options) => {
  const { field, addTo, ...values } = { ...calcStoneOptions, ...options };
  const { avgBack, avgCtPr, avgRap, sumCrt, sumAmt, down, up } = values;

  const TO_CTPR = addTo === 'ctPr';
  const TO_BACK = addTo === 'back'; // eslint-disable-line no-unused-vars

  const BACK_MODIFIED = field === 'back';
  const CTPR_MODIFIED = field === 'ctPr';
  const AMT_MODIFIED = field === 'amt';

  const back = parseDecimal(
    (() => {
      if (BACK_MODIFIED) return value ?? avgBack;
      if (CTPR_MODIFIED) return percentDiff(avgRap, value);
      if (AMT_MODIFIED) return percentDiff(avgRap * sumCrt, value);
      return avgBack;
    })(),
  );

  const ctPr = parseDecimal(
    (() => {
      if (BACK_MODIFIED) return avgRap + avgRap * (back / 100);
      if (CTPR_MODIFIED) return value ?? avgCtPr;
      if (AMT_MODIFIED) return (value ?? avgCtPr) / sumCrt;
      return avgCtPr;
    })(),
  );

  const amt = parseDecimal(
    (() => {
      if (BACK_MODIFIED) return ctPr * sumCrt;
      if (CTPR_MODIFIED) return ctPr * sumCrt;
      if (AMT_MODIFIED) return value ?? sumAmt;
      return sumAmt;
    })(),
  );

  const minBack = parseDecimal(TO_CTPR ? percentDiff(avgRap, avgCtPr + avgCtPr * (down / 100)) : avgBack + down);
  const minCtPr = parseDecimal(TO_CTPR ? avgCtPr + avgCtPr * (down / 100) : avgRap + avgRap * (down / 100));
  const minAmt = parseDecimal(minCtPr * sumCrt);

  const maxBack = parseDecimal(TO_CTPR ? percentDiff(avgRap, avgCtPr + avgCtPr * (up / 100)) : avgBack + up);
  const maxCtPr = parseDecimal(TO_CTPR ? avgCtPr + avgCtPr * (up / 100) : avgRap + avgRap * (up / 100));
  const maxAmt = parseDecimal(maxCtPr * sumCrt);

  const output = { back, ctPr, amt, minBack, minCtPr, minAmt, maxBack, maxCtPr, maxAmt };
  logInfo(output);
  return output;
};

export const calcSystemDiscount = (amt) => {
  if (!isNumber(amt)) return 0;

  const MIN_PER = 0.00004;
  const MAX_PER = 0.00002;
  const END_PER = 3.0;

  const MIN_AMT = 50000.0;
  const MAX_AMT = 99999.0;
  const END_AMT = 1000000.0;

  if (amt <= MIN_AMT) return amt * MIN_PER;
  if (amt <= MAX_AMT) return (amt - MIN_AMT) * MAX_PER + MIN_AMT * MIN_PER;
  if (amt > MAX_AMT && amt <= END_AMT) return END_PER;

  return 0;
};

export const calcTotal = (stoneList = [], options = {}) => {
  if (!isArray(stoneList) || isEmpty(stoneList)) return {};
  if (isObject(options.term)) localStorage.setItem('termObj', JSON.stringify(options.term));

  const count = Number(stoneList?.length);

  const sumCrt = parseDecimal(reduceTotal(stoneList, 'crt'), PRECISION);
  const sumRap = parseDecimal(reduceTotal(stoneList, 'rap'), PRECISION);
  const sumAmt = parseDecimal(reduceTotal(stoneList, 'amt'), PRECISION);
  const sumRapAvg = parseDecimal(reduceTotal(stoneList, 'rap_avg'), PRECISION);
  const sumBidAmount = parseDecimal(reduceTotal(stoneList, 'bidAmount'), PRECISION);
  const sumCalcAmount = parseDecimal(reduceTotal((stoneList, 'calcAmount')), PRECISION);
  const sumRapInCrt = parseDecimal(
    reduceTotal(stoneList.map((stone) => stone?.rap * stone?.crt).filter(isNumber)),
    PRECISION,
  );

  const avgRapByCrt = parseDecimal(sumRapInCrt / sumCrt, PRECISION);
  const avgBack = parseDecimal((1 - sumAmt / sumRapInCrt) * -100, PRECISION);
  const sumCtPr = parseDecimal(sumAmt / sumCrt, PRECISION);

  const termDis = parseDecimal(
    isNumber(Number(options.term?.minAmt))
      ? Number(sumAmt) >= Number(options.term?.minAmt)
        ? options.term?.value
        : options.term?.resetDis
      : options.term?.value,
    PRECISION,
  );

  const bidFinalAmt = parseDecimal(sumAmt + sumAmt * (termDis / 100), PRECISION);
  const bidFinalPr = parseDecimal(sumCtPr + sumCtPr * (termDis / 100), PRECISION);
  const bidFinalDisc = parseDecimal(sumRapAvg ? (1 - bidFinalPr / avgRapByCrt) * -100 : 0, PRECISION);

  const offerFinalAmt = parseDecimal(sumCalcAmount, PRECISION);
  const offerFinalPr = parseDecimal(offerFinalAmt / sumCrt, PRECISION);
  const offerFinalDisc = parseDecimal((1 - offerFinalPr / avgRapByCrt) * -100, PRECISION);

  const sysDis = parseDecimal(calcSystemDiscount(sumAmt) * -1, PRECISION);
  const sysAmt = parseDecimal(bidFinalAmt + bidFinalAmt * (sysDis / 100), PRECISION);
  const sysCtPr = parseDecimal(sysAmt / sumCrt, PRECISION);
  const sysBack = parseDecimal((1 - sysCtPr / avgRapByCrt) * -100, PRECISION);

  const termSysDis = parseDecimal(termDis + sysDis, PRECISION);
  const termBack = parseDecimal(avgBack + ((100 - avgBack) * termDis) / 100, PRECISION);
  const finalAvgDisc = parseDecimal((1 - sumBidAmount / sumRapAvg) * -100, PRECISION);

  const entries = [
    ['count', count],

    ['sumCrt', sumCrt],
    ['sumRap', sumRap],
    ['avgBack', avgBack],
    ['sumCtPr', sumCtPr],
    ['sumAmt', sumAmt],
    ['sumRapInCrt', sumRapInCrt],
    ['avgRapByCrt', avgRapByCrt],

    ['termDis', termDis],
    ['termBack', termBack],
    ['termSysDis', termSysDis],

    ['bidFinalDisc', bidFinalDisc],
    ['bidFinalPr', bidFinalPr],
    ['bidFinalAmt', bidFinalAmt],

    ['offerFinalDisc', offerFinalDisc],
    ['offerFinalPr', offerFinalPr],
    ['offerFinalAmt', offerFinalAmt],

    ['sysDis', sysDis],
    ['sysBack', sysBack],
    ['finalBack', sysBack],
    ['finalCtPr', sysCtPr],
    ['finalAmt', sysAmt],

    ['finalAvgDisc', finalAvgDisc],
  ];

  const output = Object.fromEntries(entries.map(([key, value]) => [key, formatFloat(value)]));
  output.values = entries;
  output.finalAvgDisc = Number(output.finalAvgDisc);
  output.count = parseInt(output.count);

  return output;
};
