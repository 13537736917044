import React, { Component } from 'react';

import { Select } from 'antd';

const { Option } = Select;

//const LIMITS = [100, 200, 300]
const LIMITS = [50, 100, 250, 500, 1000];
const dots = '...';
function getPages(totalPage, pg) {
  const pages = [];
  const prevPage = pg - 1 > 0 ? pg - 1 : 1;
  if (prevPage > 1) pages.push(1);
  if (prevPage > 1) pages.push(dots);
  for (let i = prevPage; i <= prevPage + 2 && i <= totalPage; i++) pages.push(i);
  if (prevPage + 3 < totalPage) pages.push(dots);
  if (prevPage + 2 < totalPage) pages.push(totalPage);
  return pages;
}

class Pagination extends Component {
  render() {
    const props = this.props;
    // count - total count
    // page - current page
    // limit - current limit
    // noLimitChange - display limit Selection
    // noItemCount - display items 1-10 of 1000
    // data - actual page data length
    const totalPage = props.count === 0 ? 1 : parseInt((props.count + props.limit - 1) / props.limit);

    return (
      <div className="listingPagePagination">
        <div className="clientPagination">
          {!props.noLimitChange && (
            <Select
              value={props.limit}
              onChange={(e) => {
                props.onPaginationChange(1, e);
              }}
              className="paginationSelect"
            >
              {LIMITS.map((l) => (
                <Option value={l} key={l}>
                  {l}
                </Option>
              ))}
            </Select>
          )}
          <ul>
            <li>
              <img
                onClick={() => {
                  if (props.page !== 1) props.onPaginationChange(props.page - 1, props.limit);
                }}
                src={require('../../assets/svg/DiamondList/left-arrow.svg')}
                alt="arrow"
              />
            </li>
            {getPages(totalPage, props.page).map((x, index) => (
              <li
                key={index}
                className={x === props.page ? 'active' : null}
                onClick={() => {
                  if (x !== dots) props.onPaginationChange(x, props.limit);
                }}
              >
                {x}
              </li>
            ))}
            <li>
              <img
                onClick={() => {
                  if (props.page !== totalPage) props.onPaginationChange(props.page + 1, props.limit);
                }}
                src={require('../../assets/svg/DiamondList/right-arrow.svg')}
                alt="arrow"
              />
            </li>
          </ul>
          {!props.noItemCount && (
            <span className="paginationText">
              {props.count > 0 ? (props.page - 1) * props.limit + 1 : 0} -{' '}
              {props.count > 0 ? (props.page - 1) * props.limit + props.data.length : 0} of {props.count} items
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default Pagination;
