import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get } from 'lodash';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import size from 'lodash/size';

import BidList from 'components/DiamondList/BidList';
import { getGridHeight } from 'components/DiamondList/diamondListUtils';
import { TableConfig } from 'components/DiamondList/table-config';

import { queryStringToObject } from 'util/utils';

import DiamondDetailAction from './DiamondDetailAction';
import EnquiryDetail from './EnquiryDetail';
import DiamondDetailLeft from './LeftSection';
import MobileDiamondDetail from './MobileDiamondDetail';

import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import {
  DetailView,
  ParamDetail,
  MakeDetail,
  PriceDetail,
  InclusionDetailOne,
  InclusionDetailTwo,
} from '../../constants/DiamondDetail';
import { VIEW_DIAMOND, _HIT_COUNTER } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import IntlMessages from '../../util/IntlMessages';
import {
  handleInsertTrack,
  downloadZip,
  LISTINGPAGES,
  isMobile,
  handleBidCartCreate,
} from '../DiamondList/DiamondListFunctions';
import { GuestUserPopup } from '../GuestUserPopup';
import CommonModal from '../common/CommonModal';
import ConfirmStonePopup from '../common/DiamondListing/ConfirmStonePopup';
import HoldPopup from '../common/DiamondListing/HoldPopup';
import OfficeSidebar from '../common/DiamondListing/OfficeSidebar';
import QuotePopup from '../common/DiamondListing/QuotePopup';
import SendEmail from '../common/DiamondListing/SendEmail';
import ShareDropdown from '../common/DiamondListing/ShareDropdown';
import WatchlistPopup from '../common/DiamondListing/WatchlistPopup';

class DiamondDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: true,
      watchlistShow: false,
      enquiryShow: false,
      offerShow: false,
      officeViewShow: false,
      holdShow: false,
      confirmShow: false,
      shareShow: false,
      data: {},
      sendEmail: false,
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
      shared: [],
      bidCart: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this?.props?.commonData?.strip !== prevProps.commonData.strip) this.forceUpdate();
  }

  placeOrderDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        confirmShow: true,
        enquiryShow: false,
        detail: false,
        watchlistShow: false,
        offerShow: false,
        officeViewShow: false,
        holdShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  EnquiryDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        enquiryShow: true,
        detail: false,
        watchlistShow: false,
        offerShow: false,
        officeViewShow: false,
        holdShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  BidCartDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        enquiryShow: false,
        detail: false,
        watchlistShow: false,
        offerShow: false,
        officeViewShow: false,
        holdShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: true,
      });
    }
  };

  WatchlistDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        watchlistShow: true,
        detail: false,
        enquiryShow: false,
        offerShow: false,
        officeViewShow: false,
        holdShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  QuoteDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        offerShow: true,
        detail: false,
        watchlistShow: false,
        enquiryShow: false,
        officeViewShow: false,
        holdShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  OfficeDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        officeViewShow: true,
        detail: false,
        watchlistShow: false,
        enquiryShow: false,
        offerShow: false,
        holdShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  holdDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        holdShow: true,
        detail: false,
        watchlistShow: false,
        enquiryShow: false,
        offerShow: false,
        officeViewShow: false,
        confirmShow: false,
        shareShow: false,
        bidCart: false,
      });
    }
  };

  ShareDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        shareShow: true,
        detail: true,
        watchlistShow: false,
        enquiryShow: false,
        offerShow: false,
        officeViewShow: false,
        holdShow: false,
        confirmShow: false,
        bidCart: false,
      });
    }
  };

  popupClose = () => {
    this.setState({
      detail: true,
      enquiryShow: false,
      watchlistShow: false,
      offerShow: false,
      officeViewShow: false,
      holdShow: false,
      confirmShow: false,
      shareShow: false,
      bidCart: false,
    });
  };

  handleHitCounter = (id) => {
    const objData = {
      ..._HIT_COUNTER,
      request: {
        isHitCounter: true,
        id,
      },
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === 'OK');
    });
  };

  componentDidMount = () => {
    this.setState({
      shared: window.location.search.split('?'),
    });
    if (this.props.diamondPopup) return;

    const id = last(window.location.pathname.split('/'));
    if (id !== LISTINGPAGES.DETAILS && id !== LISTINGPAGES.DETAIL) {
      const self = this;
      const api = `${VIEW_DIAMOND.url}/${id}`;
      const objData = {
        method: VIEW_DIAMOND.method,
        url: api,
        showLoader: true,
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === 'OK') {
          self.setState({ data: data.data });
          self.handleHitCounter(data.data.id);
        }
      });
    }
  };

  verifyCerti = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      window.open(`https://gia.edu/report-check?reportno=${data.rptNo}`);
    }
  };

  downloadZip = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      downloadZip(
        {
          filter: {
            vStnId: [data.vStnId],
          },
          isExcel: true,
          img: true,
          certFile: true,
          videoFile: true,
          hAFile: true,
          arrowFile: true,
          assetFile: true,
          pltFile: true,
          mp4Video: true,
          type2: true,
          zip: true,
          roughVideo: true,
          darkFieldImage: true,
          flsImage: true,
          idealWhiteImage: true,
          roughImage: true,
          planImg: true,
          faceUp: true,
          b2cRealImage: true,
        },
        () => {},
      );
    }
  };

  sendMail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({ sendEmail: true });
    }
  };

  actionButtons = (data) => {
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const params = queryStringToObject();
    return !data ? null : (
      <div className="diamondDetailBottomBox">
        <div className={'diamondDetailBottomAction'}>
          {!isMobile() ? (
            <h2>
              <IntlMessages id="app.stoneDetail" />
            </h2>
          ) : (
            ''
          )}
          <div className="dia_opt">
            {!localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) &&
            window.location.pathname.split('/')[1] === LISTINGPAGES.DETAILS ? (
              <>
                <div className="stone-width botoomStickyBar diamondSearchSticky">
                  <div className="d-flex align-items-center">
                    <a className="bottomStickyButton" href="/signup">
                      <span>Create An Account</span>
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <a
                      className="bottomStickyButton"
                      onClick={() => {
                        window.open(`/signin?redirectUrl=${window.location.pathname}`, '_self');
                      }}
                    >
                      <span>Login</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                {data.wSts === 'C' && (
                  <span className="diamond-detail-error">(Stone has been sold. Please search other stones.)</span>
                )}

                {(get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
                  get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
                  get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)) && (
                  <DiamondDetailAction
                    image={require('../../assets/svg/DiamondDetail/send.svg')}
                    tooltip="Share"
                    placement="bottomLeft"
                    onClick={this.ShareDetail}
                  />
                )}
                {get(USER_PERMIT, 'DOWNLOAD.view', false) && (
                  <DiamondDetailAction
                    image={require('../../assets/svg/DiamondDetail/download-zip.svg')}
                    tooltip={<IntlMessages id="app.downloadZip" />}
                    placement="bottomLeft"
                    onClick={() => {
                      if (this.state.isGuest) {
                        this.setState({ showGuest: true });
                      } else {
                        this.downloadZip(data);
                      }
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  diamondDetail = (data, key) => {
    const grouped = groupBy(data, key);
    let first = grouped[1] || [];
    const second = grouped[2] || [];
    const third = grouped[3] || [];
    const fourth = grouped[4] || [];
    if (
      (!first || !first.length) &&
      (!second || !second.length) &&
      (!third || !third.length) &&
      (!fourth || !fourth.length)
    ) {
      first = Object.values(grouped)[0];
    }
    const maxIndex = Math.max(first.length, second.length, third.length, fourth.length);
    const arr = [];

    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
      third[i] && arr.push(third[i]);
      fourth[i] && arr.push(fourth[i]);
    }
    return arr;
  };

  leftPart = (data) => {
    const gridHeight1 = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailTopTab'], 15);
    return (
      <div className="diamondDetailInnerDetail">
        {data && size(data) ? (
          <DiamondDetailLeft
            item={data}
            shared={this.state.shared}
            rptNo={data.rptNo}
            pktNo={data.vStnId}
            style={
              isMobile() ? {} : !this.props.diamondPopup ? { height: gridHeight1, overflow: 'auto' } : { height: '' }
            }
          />
        ) : null}
      </div>
    );
  };

  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAILS}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };

  render() {
    const data = this.props.diamondPopup ? this.props.data : this.state.data;
    const gridHeight = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailBottomBox'], 15);
    const params = [];
    params.push(
      Object.keys(DetailView).map((k) => (
        <div className="diamondDetailMore" key={DetailView[k]['name']}>
          <h2 className="detailSmallTitle">{DetailView[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(DetailView[k]['data'], 'part').map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
              const iKey = typeof item['key'] === 'function' ? item['key'](data.isFcCol) : item['key'];
              let value = !data[iKey]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(iKey)
                ? parseFloat(data[iKey]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(iKey)
                ? Math.round(data[iKey])
                : data[iKey];

              if (iKey === 'back') {
                value =
                  data.ctPr < data.rap
                    ? `-${parseFloat(Math.abs(value)).toFixed(2)}`
                    : value === 0
                    ? Math.abs(value)
                    : `+${parseFloat(Math.abs(value)).toFixed(2)}`;
              }
              return (
                <ul key={iKey}>
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>{value}</span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );
    params.push(
      Object.keys(PriceDetail).map((k) => (
        <div className="diamondDetailMore" key={PriceDetail[k]['name']}>
          <h2 className="detailSmallTitle">{PriceDetail[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(PriceDetail[k]['data'], 'part')?.map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt']?.includes(item.key)) return false;
              const iKey = typeof item['key'] === 'function' ? item['key'](data.isFcCol) : item['key'];
              let value = !data[iKey]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(iKey)
                ? parseFloat(data[iKey]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(iKey)
                ? Math.round(data[iKey])
                : data[iKey];

              if (iKey === 'back') {
                value =
                  data.ctPr < data.rap
                    ? isNaN(parseFloat(Math.abs(value)).toFixed(2))
                      ? '-'
                      : `-${parseFloat(Math.abs(value)).toFixed(2)}`
                    : value === 0
                    ? Math.abs(value)
                    : isNaN(parseFloat(Math.abs(value)).toFixed(2))
                    ? '-'
                    : `+${parseFloat(Math.abs(value)).toFixed(2)}`;
              }
              if (iKey === 'rap') {
                value = data?.rap && data.wSts !== 'U' ? parseFloat(Math.abs(value)).toFixed(2) : '-';
              }
              return (
                <ul key={iKey} className="fourCol">
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>{value}</span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );
    params.push(
      Object.keys(MakeDetail).map((k) => (
        <div className="diamondDetailMore" key={MakeDetail[k]['name']}>
          <h2 className="detailSmallTitle">{MakeDetail[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(MakeDetail[k]['data'], 'part').map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
              const iKey = typeof item['key'] === 'function' ? item['key'](data.isFcCol) : item['key'];
              let value = !data[iKey]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(iKey)
                ? parseFloat(data[iKey]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(iKey)
                ? Math.round(data[iKey])
                : data[iKey];

              if (iKey === 'back') {
                value =
                  data.ctPr < data.rap
                    ? `-${parseFloat(Math.abs(value)).toFixed(2)}`
                    : value === 0
                    ? Math.abs(value)
                    : `+${parseFloat(Math.abs(value)).toFixed(2)}`;
              }
              return (
                <ul key={iKey} className="fourCol">
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>{value}</span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );
    params.push(
      Object.keys(ParamDetail).map((k) => (
        <div className="diamondDetailMore" key={ParamDetail[k]['name']}>
          <h2 className="detailSmallTitle">{ParamDetail[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(ParamDetail[k]['data'], 'part').map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
              const iKey = typeof item['key'] === 'function' ? item['key'](data.isFcCol) : item['key'];
              let value = !data[iKey]
                ? '-'
                : TableConfig.FLOAT_COLUMNS.includes(iKey)
                ? parseFloat(data[iKey]).toFixed(2)
                : TableConfig.ROUND_COLUMNS.includes(iKey)
                ? Math.round(data[iKey])
                : data[iKey];

              if (iKey === 'back') {
                value =
                  data.ctPr < data.rap
                    ? `-${parseFloat(Math.abs(value)).toFixed(2)}`
                    : value === 0
                    ? Math.abs(value)
                    : `+${parseFloat(Math.abs(value)).toFixed(2)}`;
              }
              return (
                <ul key={iKey}>
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>{value}</span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );

    params.push(
      Object.keys(InclusionDetailOne).map((k) => (
        <div className="diamondDetailMore" key={InclusionDetailOne[k]['name']}>
          <h2 className="detailSmallTitle">{InclusionDetailOne[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(InclusionDetailOne[k]['data'], 'part').map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
              return (
                <ul key={item.key} className="threeCol">
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>
                          {!data[item['key']]
                            ? '-'
                            : TableConfig.FLOAT_COLUMNS.includes(item.key)
                            ? parseFloat(data[item['key']]).toFixed(2)
                            : TableConfig.ROUND_COLUMNS.includes(item.key)
                            ? Math.round(data[item['key']])
                            : data[item['key']]}
                        </span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );

    params.push(
      Object.keys(InclusionDetailTwo).map((k) => (
        <div className="diamondDetailMore" key={InclusionDetailTwo[k]['name']}>
          <h2 className="detailSmallTitle">{InclusionDetailTwo[k]['name']}</h2>
          <div className="diamondDetailMoreHalf">
            {this.diamondDetail(InclusionDetailTwo[k]['data'], 'part').map((item) => {
              if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
              return (
                <ul key={item.key} className="twoCol">
                  <li>
                    {!item.hide && (
                      <>
                        {item.label}
                        <span>
                          {!data[item['key']]
                            ? '-'
                            : TableConfig.FLOAT_COLUMNS.includes(item.key)
                            ? parseFloat(data[item['key']]).toFixed(2)
                            : TableConfig.ROUND_COLUMNS.includes(item.key)
                            ? Math.round(data[item['key']])
                            : data[item['key']]}
                        </span>
                      </>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      )),
    );

    if (isMobile())
      return (
        <MobileDiamondDetail
          params={params}
          {...this.state}
          popupClose={this.popupClose}
          parent={this}
          actionButtons={this.actionButtons(data)}
        />
      );
    return (
      <div className="diamondDetailWrapper">
        <div className="diamondDetailInner">
          {this.props.diamondPopup ? this.actionButtons(data) : null}
          <div className="diamondDetailMainTab">
            {this.props.diamondPopup && (
              <>
                <div className="detailHalfWhite"></div>
                <div className="detailHalfGray"></div>
              </>
            )}
            <div className="diamondDetailLeft">{this.leftPart(data)}</div>
            <div className={`diamondDetailRight ${!this.props.diamondPopup && 'diamondDetailPage'}`}>
              {!this.props.diamondPopup ? this.actionButtons(data) : null}
              {this.state.watchlistShow && (
                <div className="p-10">
                  <WatchlistPopup
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: '' }}
                    popupClass={!this.props.diamondPopup ? null : 'halfBlockPopup'}
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.bidCart && (
                <div className="p-10">
                  <BidList
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                    checked={[data]}
                    onCancel={() => this.popupClose()}
                    onClose={() => this.popupClose()}
                  />
                </div>
              )}
              {this.state.enquiryShow && (
                <div className="p-10">
                  <EnquiryDetail
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                    checked={[data]}
                    onCancel={() => this.popupClose()}
                    onClose={() => this.popupClose()}
                  />
                </div>
              )}
              {this.state.offerShow && (
                <div className="p-10">
                  <QuotePopup
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: '' }}
                    popupClass={!this.props.diamondPopup ? null : 'halfBlockPopup'}
                    quoteDay={false}
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.officeViewShow && (
                <div className="p-10">
                  <OfficeSidebar
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: '' }}
                    popupClass={!this.props.diamondPopup ? null : 'halfBlockPopup'}
                    quoteDay={false}
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.holdShow && (
                <div className="p-10">
                  <HoldPopup
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: '' }}
                    popupClass={!this.props.diamondPopup ? null : 'halfBlockPopup'}
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.confirmShow && (
                <div className="p-10">
                  <ConfirmStonePopup
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: '' }}
                    popupClass={!this.props.diamondPopup ? null : 'halfBlockPopup'}
                    detail
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.shareShow && (
                <CommonModal
                  modalSize="md-size"
                  title="Share Stone"
                  handleCancel={this.popupClose}
                  visible={true}
                  wrapClassName=""
                >
                  <ShareDropdown checked={[data]} onCloseSharePopup={this.popupClose} />
                </CommonModal>
              )}
              {this.state.detail && (
                <div
                  className="mt-0 height-1vh"
                  // style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                  style={!this.props.diamondPopup ? { overflow: 'auto' } : { height: 'auto' }}
                >
                  {params}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.getEmailModal(data)}
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open('/signup', '_self');
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </div>
    );
  }
}
export default connect((props) => {
  return { commonData: props.commonData };
}, {})(withRouter(DiamondDetail));
