import React from 'react';
import { useSelector } from 'react-redux';

import { BidService } from 'services/BidService';

import { isFunction, isNumber, isNumeric, formatFloat, classNames } from 'util/utils';

import minusJpg from 'assets/img/minus.jpg';
import plusJpg from 'assets/img/plus.jpg';

const ConnectedInput = React.memo((props) => {
  const { currentType, column, onChange, onDecrease, onIncrease, record } = props;

  const originalValue = record?.[column];

  const allowChange =
    (column == 'bidDiscount' && BidService.calculateOnDiscount) ||
    (column == 'bidPricePerCarat' && BidService.calculateOnPricePerCarat);

  const values = useSelector((store) => store?.tableValues?.[currentType]?.[record?.vStnId]);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    const _originalValue = Number(originalValue);
    const updatedValue = values?.[column];

    const initialValue = isNumber(updatedValue)
      ? formatFloat(updatedValue)
      : isNumber(_originalValue)
      ? formatFloat(_originalValue)
      : '0.00';

    setValue(initialValue);
  }, [originalValue, column, values]);

  const handleChange = React.useCallback((e) => {
    const number = e?.target?.value;
    if (isNumeric(number)) setValue(number);
  }, []);

  const handleBlur = React.useCallback(() => {
    onChange({ row: record, name: column, value, originalValue: originalValue });
  }, [originalValue, column, onChange, record, value]);

  const handleDecrease = React.useCallback(() => {
    onDecrease({ row: record, name: column, value, originalValue: originalValue });
  }, [originalValue, column, onDecrease, record, value]);

  const handleIncrease = React.useCallback(() => {
    onIncrease({ row: record, name: column, value, originalValue: originalValue });
  }, [originalValue, column, onIncrease, record, value]);

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode !== 13) return;
      e.preventDefault();
      handleBlur();
    },
    [handleBlur],
  );

  return (
    <span className={classNames(['tableInput width-full'])}>
      {allowChange && isFunction(onDecrease) && (
        <img onClick={handleDecrease} src={minusJpg} width="15px" alt="decrease" />
      )}
      <input
        type="text"
        min={record?.minAllowedValues?.[column]}
        max={record?.maxAllowedValues?.[column]}
        name={column}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ textAlign: 'right' }}
      />
      {allowChange && isFunction(onIncrease) && (
        <img onClick={handleIncrease} src={plusJpg} width="15px" alt="increase" />
      )}
    </span>
  );
});
ConnectedInput.displayName = 'CellEditBack';

export default ConnectedInput;
