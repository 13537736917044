import React from 'react';

import { useBoolean } from 'util/hooks';
import noImageSvg from 'assets/svg/noImageFound/DiamondNoImage.svg';

function Image(props) {
  const { alt, children, downloadUrl, clickToOpen, actions, hideOnError, ..._props } = props;
  const [error, setError] = useBoolean(false);

  const handleClick = React.useCallback(() => {
    if (clickToOpen) window.open(downloadUrl ?? _props.src, '_blank');
  }, [downloadUrl, clickToOpen, _props.src]);

  if (hideOnError && error) return null;
  return error ? (
    children ?? <img className="noimagefound" src={noImageSvg} alt="No Image" />
  ) : (
    <React.Fragment>
      <img
        className={props.className}
        onClick={handleClick}
        onLoad={setError.false}
        onError={setError.true}
        alt={alt ?? _props.title}
        {..._props}
      />
    </React.Fragment>
  );
}

export default React.memo(Image);
