import React from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { Checkbox, Drawer } from 'antd';
import { groupBy } from 'lodash';

import { RowSelectService } from 'services/RowSelectService';
import { Storage } from 'services/Storage';

import { usePathname, useAutoRef } from 'util/hooks';
import useFilters from 'util/useFilters';
import useRowSelect from 'util/useRowSelect';
import useSortBy from 'util/useSortBy';
import { formatDecimal, isEmpty, isArray, objectToQueryString, isNumber, catchError } from 'util/utils';

import { LISTINGPAGES, getColumn } from './DiamondListFunctions';
import DiamondListingHead from './ListTableHeaderBack';
import { TableConfig } from './table-config';

import { LAB_LINKS, FILE_URLS, LOCAL_STORAGE_VAR } from '../../constants/Common';
import DiamondDetail from '../DiamondDetail';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import Status from '../common/DiamondListing/Status';
import TableGrouping from '../common/DiamondListing/TableGrouping';

import imageSource from 'assets/svg/camera.svg';
import certiSource from 'assets/svg/certi.svg';
import videoSource from 'assets/svg/video.svg';

export const LIMIT = 250;

const ActionHeader = React.memo((props) => {
  const { areAllChecked, currentType, disabled, displayedRows, noCheckBox } = props;

  const propsRef = useAutoRef(props);
  const { isHeaderChecked } = useRowSelect(currentType);

  React.useEffect(() => {
    if (areAllChecked) RowSelectService.selectRows(currentType, displayedRows);
  }, [areAllChecked, currentType, displayedRows]);

  const isChecked = isHeaderChecked(displayedRows);

  const handleChange = React.useCallback(() => {
    const { displayedRows } = propsRef.current;
    const list = displayedRows;
    if (isEmpty(list)) return;
    isChecked
      ? RowSelectService.unSelectRows(currentType, list, true)
      : RowSelectService.selectRows(currentType, list, true);
  }, [currentType, isChecked, propsRef]);

  return (
    <>
      {!noCheckBox && !disabled && (
        <Checkbox key="selection_header" checked={isChecked} onChange={handleChange} disabled={disabled} />
      )}
    </>
  );
});

const ActionCell = React.memo((props) => {
  const { bidStatus, bidStatusTooltip, currentType, disabled, noCheckBox, nostatus, row = {} } = props;

  const isChecked = useSelector((state) => state.diamondData?.status?.[currentType]?.[row.selectionKey ?? row.id]);

  const propsRef = useAutoRef(props);
  const pathname = usePathname();

  React.useEffect(() => {
    const { row } = propsRef.current;
    if (row?.isDefaultChecked) RowSelectService.selectRows(currentType, row);
  }, [currentType, propsRef]);

  const handleChange = React.useCallback(
    (e) => {
      void e?.preventDefault?.();
      void e?.stopPropagation?.();
      isChecked ? RowSelectService.unSelectRows(currentType, [row]) : RowSelectService.selectRows(currentType, [row]);
    },
    [currentType, isChecked, row],
  );

  return (
    <div className="selectActionIcon">
      {!nostatus && (
        <Status
          status={row.wSts}
          bidStatus={pathname === LISTINGPAGES.BID ? bidStatus : null}
          winloss={pathname === LISTINGPAGES.MY_BID ? row.status : null}
          bidStatusTooltip={pathname === LISTINGPAGES.BID ? bidStatusTooltip : null}
        />
      )}
      {!noCheckBox && !disabled && <Checkbox checked={isChecked} onClick={handleChange} disabled={disabled} />}
    </div>
  );
});
ActionCell.displayName = 'Actions';

const getStyles = (props, item, type) => {
  return [
    props,
    {
      style: {
        textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
        width: (item.width || '100') + 'px',
        fontWeight: type === 'cell' && TableConfig.BOLD_COLUMNS.includes(item.id) ? '600' : '',
        color: type === 'cell' && (item.id === 'rptNo' || item.id === 'dna' || item.link) ? '#008cba' : '',
      },
    },
  ];
};

const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

function makeSticky(table, options = {}) {
  options = { indices: [], ...options };
  options.indices = options.indices.filter((n) => isNumber(n) && n > -1).sort();

  const nStickyCells = Math.max(...options.indices) + 1;
  const rows = table?.querySelectorAll?.('tr');
  const cellSlice = Array.prototype.slice.call(rows[0].children, 0, nStickyCells);

  const widthList = Array.prototype.map.call(cellSlice, (cell, index) => {
    if (!options.indices.includes(index)) return -1;
    return cell?.getBoundingClientRect()?.width;
  });

  const leftOffsetList = widthList.map((_, index) => {
    let output = options.offset ?? -0;
    for (let i = 0; i < index; i++) output = output + widthList[i];
    return output;
  });

  table.style['border-collapse'] = 'separate';
  table.style['border-spacing'] = 0;

  Array.prototype.forEach.call(rows, (row) => {
    const cellList = Array.prototype.slice.call(row.children, 0, nStickyCells);

    Array.prototype.forEach.call(cellList, (cell, index) => {
      if (cell.classList.contains('groupingTableAmount', 'activebg')) return;
      if (!options.indices.includes(index)) return;

      cell['data-orig-z-index'] = cell['data-orig-z-index'] ?? cell.style['z-index'] ?? 0;
      const zIndex = isNumber(Number(cell['data-orig-z-index'])) ? Number(cell['data-orig-z-index']) : 0;

      cell.style['position'] = 'sticky';
      cell.style['left'] = `${leftOffsetList[index]}px`;
      cell.style['z-index'] = cell.tagName === 'TD' ? zIndex + 1 : zIndex + 101;
      cell.style['background'] = cell.tagName === 'TD' ? '#fff' : '';
      if (cell.classList.contains('tableGropupingBox')) cell.style.removeProperty('z-index');
      // cell.classList.add('cell-sticky');

      if (index + 1 === nStickyCells) {
        cell.style.boxShadow = '2px 0 3px #ccc';
        // cell.classList.add('cell-shadow-left');
      }
    });
  });
}

const Table = (props) => {
  const {
    data = [],
    handleSort = () => {},
    //statuses
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    bidStatus = '',
    bidStatusTooltip = '',
    groupKey = 'groupNo',
    noResetOnInit,
  } = props;

  const [visible, setVisible] = React.useState(false);
  const [detail, setDetail] = React.useState(null);
  const [expanded] = React.useState([]);
  const pathname = usePathname();

  const tableRef = React.useRef();

  const columns = React.useMemo(() => {
    const primaryColumns = getColumn(props.page) || [];
    if (pathname !== LISTINGPAGES.MATCH_PAIR) primaryColumns.map((col) => ({ ...col, canSort: !!col.sort }));
    let allColumns = props.columns || primaryColumns;
    if (isEmpty(allColumns)) return [];
    allColumns = allColumns.map((col) => ({
      ...col,
    }));

    return nocheck
      ? allColumns
      : [
          {
            id: 'selection',
            Header: ({ rows }) => {
              const originalRows = rows.map((row) => row.original);
              return (
                <ActionHeader
                  displayedRows={originalRows}
                  nostatus={nostatus}
                  currentType={currentType}
                  areAllChecked={areAllChecked}
                  noCheckBox={noCheckBox}
                />
              );
            },
            width: 50,
            Cell: ({ row }) => (
              <ActionCell
                row={row.original}
                disabled={pathname === LISTINGPAGES.MATCH_PAIR}
                nostatus={nostatus}
                currentType={currentType}
                areAllChecked={areAllChecked}
                bidStatus={bidStatus}
                bidStatusTooltip={bidStatusTooltip}
                noCheckBox={noCheckBox}
              />
            ),
          },
          ...allColumns,
        ];
  }, [
    areAllChecked,
    bidStatus,
    bidStatusTooltip,
    currentType,
    noCheckBox,
    nocheck,
    nostatus,
    pathname,
    props.columns,
    props.page,
  ]);

  const freezeColumns = React.useCallback(() => {
    catchError(() => {
      const indices = columns.map((col, index) =>
        TableConfig.FROZEN_COLUMNS.includes(col.id) || TableConfig.FROZEN_COLUMNS.includes(col.accessor)
          ? index
          : undefined,
      );

      void makeSticky(tableRef.current, { offset: 1, indices });
    });
  }, [columns]);

  React.useLayoutEffect(() => {
    freezeColumns();
  });

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      columns: columns,
      data,
      manualSortBy: true,
      isMultiSortEvent: () => {},
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
  );

  React.useEffect(() => {
    const sortArr = sortBy.map((sort) => ({ [sort.id]: sort.desc ? 'DESC' : 'ASC' }));
    handleSort(sortArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  //common functions
  const clickHandler = React.useCallback(
    (e, row, cell) => {
      if (cell.column.id === 'selection') return;
      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        window.open(link);
      } else if (cell.column.id === 'dna') {
        window.open(`/${LISTINGPAGES.DETAIL}/${row.original.id}`);
      } else if ((cell.column.id === 'lbNm' || cell.column.id === 'rptNo') && row.original.lbNm && row.original.rptNo) {
        window.open(LAB_LINKS[row.original.lbNm.toUpperCase()].replace('***', row.original.rptNo));
      } else if (!TableConfig.NOCHECK_COLUMNS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        const parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [props.noCheckBox, props.nocheck],
  );

  const grouppedRows = React.useMemo(() => {
    for (const row of rows) prepareRow(row);

    return groupBy(
      rows.map((row) => row.original),
      groupKey,
    );
  }, [groupKey, prepareRow, rows]);

  React.useEffect(() => {
    if (!areAllChecked && !noResetOnInit) RowSelectService.resetSelectedRows(currentType);
  }, [areAllChecked, currentType]);

  if (loading) return <div className={loading ? 'loading-indicator' : ''} />;

  return (
    <table ref={tableRef} {...getTableProps({ className: `${currentType}-table` })}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} {...column.getHeaderProps(headerProps)}>
                {column.id === 'selection'
                  ? column.render('Header')
                  : column.id !== 'action' && (
                      <DiamondListingHead
                        toggleSortBy={toggleSortBy}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                        column={column}
                        filters={filters}
                        nodots={nodots}
                        FilterOption={FilterOption}
                      />
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {isArray(rows) &&
          rows.map((row) => {
            prepareRow(row);

            return (
              <React.Fragment key={row.original.selectionKey ?? row.original.id ?? row.original.accessor}>
                {!noGrp && (
                  <>
                    {row.original.isMatchHeader && (
                      <TableGrouping
                        colSpan={columns.length}
                        displayTotal={TableConfig.TOTAL_COLUMNS.filter((el) => columns.find((c) => c.id == el))}
                        row={row.original}
                        col={row}
                        multipleCheckBox
                        isMatchHeader
                      >
                        <ActionHeader
                          displayedRows={grouppedRows?.[row.original[groupKey]]}
                          currentType={currentType}
                        />
                      </TableGrouping>
                    )}
                    {(row.original.isConfirmHeader || row.original.isBidHeader || row.original.isOfferHeader) && (
                      <TableGrouping colSpan={columns.length} row={row.original} />
                    )}
                    {row.original.isOfficeHeader && <TableGrouping colSpan={columns.length} row={row.original} />}
                    {row.original.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && (
                      <TableGrouping colSpan={columns.length} row={row.original} />
                    )}
                  </>
                )}
                <tr
                  className={
                    row.original.LowerBid === 3
                      ? 'low-bid'
                      : row.original.LowerBid === 1
                      ? 'highest-bid'
                      : row.original.LowerBid === 2
                      ? 'equal-bid'
                      : row.original.lbNm !== 'GIA'
                      ? row.original.lbNm
                      : ''
                  }
                  {...row.getRowProps({ 'table-row': currentType + row.original.id })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <MemoizedCell
                        key={cell.column.id + '_cell'}
                        currentType={currentType}
                        clickHandler={clickHandler}
                        setDetail={setDetail}
                        grouppedRows={grouppedRows}
                        expandedList={expanded}
                        data={data}
                        cell={cell}
                        row={row}
                      />
                    );
                  })}
                </tr>
                {!noGrp && row.original.isMatchFooter && (
                  <TableGrouping
                    displayTotal={TableConfig.TOTAL_COLUMNS.filter((el) => columns.find((c) => c.id == el))}
                    columns={columns}
                    row={row.original}
                    col={row}
                    isFooter
                  />
                )}
              </React.Fragment>
            );
          })}
      </tbody>
      <Drawer onClose={() => setVisible(false)} visible={visible} wrapClassName="diamondDetailPopup" destroyOnClose>
        {visible && <DiamondDetail diamondPopup={true} data={visible} onClose={() => setVisible(false)} />}
      </Drawer>
      {detail && (
        <Drawer onClose={() => setDetail(null)} visible={detail} wrapClassName="diamondDetailPopup" destroyOnClose>
          {detail.detail.i && <img src={FILE_URLS.img.replace('***', detail.detail.i.vStnId)} />}
          {detail.detail.v && (
            <iframe src={FILE_URLS.videoFile.replace('***', detail.detail.v.vStnId)} height="100%" width="100%" />
          )}
          {detail.detail.c && (
            <iframe src={FILE_URLS.certFile.replace('***', detail.detail.c.rptNo)} height="100%" width="100%" />
          )}
        </Drawer>
      )}
    </table>
  );
};

function getMap(value, key) {
  return !value
    ? '-'
    : TableConfig.FLOAT_COLUMNS.includes(key)
    ? formatDecimal(value)
    : TableConfig.ROUND_COLUMNS.includes(key)
    ? formatNumber(Math.round(value))
    : value;
}

const MemoizedCell = React.memo((props) => {
  const { cell, currentType, clickHandler, row, setDetail, grouppedRows, expandedList = [] } = props;

  const isGuest = window.___isGuest___ ?? Storage.get(`${LOCAL_STORAGE_VAR}-guest`) ?? false;
  window.___isGuest___ = isGuest;

  const handleClick = React.useCallback(
    (e) => {
      void e?.preventDefault?.();
      void e?.stopPropagation?.();
      clickHandler(e, row, cell);
    },
    [cell, clickHandler, row],
  );

  const rowSpan = React.useMemo(() => {
    if (window.location?.pathname.includes('match-pair')) {
      const { id, groupNo } = { ...row?.original };
      const rows = grouppedRows?.[groupNo];
      if (rows?.length === 2) {
        const expList = rows.filter(
          (row) => row?.id !== rows?.[rows.length - 1]?.id && expandedList && expandedList.includes(row?.id),
        );
        if (isEmpty(expList)) return 1;
        if (rows?.[0]?.id === id) return 2;
        return 0;
      }
    }
    return 1;
  }, [expandedList, grouppedRows, row?.original]);

  if (rowSpan === 0) return null;

  if (cell.column.id === 'cutNm' && cell.value === 'NON' && cell.value !== 'ROUND') {
    return (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        -
      </td>
    );
  }

  if (cell.column.id === 'back') {
    return !row.original.isFcCol && !isGuest && cell.value ? (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        {row.original.ctPr < row.original.rap
          ? `${cell.row.original.wSts !== 'U' ? '-' + parseFloat(Math.abs(cell.value)).toFixed(2) : '-'}`
          : cell.value === 0
          ? Math.abs(cell.value)
          : `${cell.row.original.wSts !== 'U' ? '+' + parseFloat(Math.abs(cell.value)).toFixed(2) : '-'}`}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        -
      </td>
    );
  }

  if (cell.column.id === 'rap') {
    return !row.original.isFcCol && !isGuest ? (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        {(cell.row.original.wSts !== 'U' ? cell.value : '-') ?? '-'}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        -
      </td>
    );
  }

  if (['amt', 'ctpr'].includes(cell.column.id)) {
    return !isGuest ? (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        {cell.row.original.wSts !== 'U' ? formatDecimal(cell.value) ?? '-' : '-'}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        -
      </td>
    );
  }

  if (cell.column.id === 'newDiscount') {
    return (
      <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
        {cell.value < 0
          ? `-${formatDecimal(Math.abs(cell.value))}`
          : cell.value === 0
          ? Math.abs(cell.value)
          : `+${formatDecimal(Math.abs(cell.value))}`}
      </td>
    );
  }

  if (cell.column.id === 'lbNm') {
    return (
      <td
        {...cell.getCellProps(cellProps)}
        className={cell.value === 'GIA' ? 'gia-cer-color' : cell.value === 'IGI' ? 'ige-cer-color' : 'other-cer-color'}
        onClick={handleClick}
      >
        {cell.value}
      </td>
    );
  }

  return (
    <td
      {...cell.getCellProps(cellProps)}
      title={!['colNm', 'vStnId'].includes(cell.column.id) ? cell.value : undefined}
      rowSpan={rowSpan}
      onClick={handleClick}
    >
      {cell.column.id === 'Details' ? (
        <Resource original={row.original} setDetail={setDetail} grouppedRows={grouppedRows} />
      ) : row.original.altCert && TableConfig.ALT_COLUMNS.includes(cell.column.id) ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <span style={{ width: '100%' }}>{cell.render('Cell')}</span>
          <span
            style={{ width: '100%' }}
            className={
              TableConfig.FLOAT_COLUMNS.includes(cell.column.id) || TableConfig.ROUND_COLUMNS.includes(cell.column.id)
                ? ''
                : null
            }
          >
            {getMap(row.original.altCert[cell.column.id], cell.column.id)}
          </span>
        </div>
      ) : (
        cell.render('Cell', { currentType })
      )}
    </td>
  );
});

const mr5 = { marginRight: '5px' };

const Resource = React.memo(({ original }) => {
  const { img } = original;

  const pathname = usePathname();

  const requestParams = React.useMemo(() => {
    return {
      ...([LISTINGPAGES.MY_DEAL_OF_THE_DAY, LISTINGPAGES.DEAL_OF_THE_DAY].includes(pathname)
        ? { isDealOfTheDay: true }
        : {}),
    };
  }, [pathname]);

  const handleCertClick = React.useCallback(() => {
    pathname === LISTINGPAGES.CONFIRM
      ? window.open(
          `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
            ...requestParams,
            isCert: true,
          })}`,
        )
      : window.open(
          `/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({
            ...requestParams,
            isCert: true,
          })}`,
        );
  }, [original.diamondId, original.id, pathname, requestParams]);

  const handleImageClick = React.useCallback(() => {
    pathname === LISTINGPAGES.CONFIRM
      ? window.open(
          `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
            ...requestParams,
            isImg: true,
          })}`,
        )
      : window.open(`/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({ ...requestParams, isImg: true })}`);
  }, [original.diamondId, original.id, pathname, requestParams]);

  const handleVideoClick = React.useCallback(() => {
    pathname === LISTINGPAGES.CONFIRM
      ? window.open(
          `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
            ...requestParams,
            isVideo: true,
          })}`,
        )
      : window.open(
          `/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({
            ...requestParams,
            isVideo: true,
          })}`,
        );
  }, [original.diamondId, original.id, pathname, requestParams]);

  return (
    <React.Fragment>
      <img style={mr5} src={imageSource} width="15px" alt="" onClick={handleImageClick} />
      <img style={mr5} src={videoSource} width="15px" alt="" onClick={handleVideoClick} />
      <img style={mr5} src={certiSource} width="15px" alt="" onClick={handleCertClick} />
    </React.Fragment>
  );
});

export default React.memo(Table);
