import React from 'react';

import { isNumeric, isNumber } from 'util/utils';

const BidCartDiscountMobile = (props) => {
  const { original, handleDiscountBlur, data, bidConfig } = props;

  const originalValue = React.useMemo(() => {
    return Number(parseFloat(original.back).toFixed(2));
  }, [original.back]);

  const [value, setValue] = React.useState(
    isNumber(original.bidDiscount)
      ? parseFloat(original.bidDiscount).toFixed(2)
      : isNumber(originalValue)
      ? parseFloat(originalValue).toFixed(2)
      : '0.00',
  );

  React.useEffect(() => {
    if (original.bidDiscount) {
      setValue(parseFloat(original.bidDiscount).toFixed(2));
    }
  }, [original]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();
    const _value = e.target.value;
    if (isNumeric(_value)) setValue(_value);
  }, []);

  const handleBlur = React.useCallback(
    (e) => {
      e.preventDefault();
      const _value = e.target.value;
      isNumeric(_value, true)
        ? handleDiscountBlur(_value, original, data, bidConfig)
        : handleDiscountBlur(0, original, data, bidConfig);
    },
    [bidConfig, data, handleDiscountBlur, original],
  );

  if (original.isFcCol) return '-';

  const sign = Number(value) > 0 ? '+' : '';

  return (
    <div className="tableInput">
      <span className="add-sign-mobile">{sign}</span>
      <input
        type="number"
        style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default BidCartDiscountMobile;
