import { pruneEmpty } from 'util/utils';

const initialState = {
  loading: 0,
  raw: {},
  data: {},
  error: {},
  _previous: [],
};

export const BID_ACTION_TYPES = {
  SET_BID_CONFIG: '@bid-config/set',
  RESET: '@bid-config/reset',
  START_LOADING: '@bid-config/start-loading',
  STOP_LOADING: '@bid-config/stop-loading',
};

export const BID_ACTIONS = {
  setBidConfig: (payload) => ({ type: BID_ACTION_TYPES.SET_BID_CONFIG, payload }),
  startLoading: () => ({ type: BID_ACTION_TYPES.START_LOADING }),
  stopLoading: () => ({ type: BID_ACTION_TYPES.STOP_LOADING }),
  reset: () => ({ type: BID_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BID_ACTION_TYPES.SET_BID_CONFIG:
      return {
        ...state,
        raw: { ...payload?.raw },
        data: { ...payload?.data },
        error: { ...payload?.error },
        _previous: [...state._previous, ...[pruneEmpty({ raw: state?.raw, data: state?.data, error: state?.error })]],
      };

    case BID_ACTION_TYPES.START_LOADING:
      return { ...state, loading: ++state.loading };

    case BID_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: --state.loading };

    case '@@RESET':
    case BID_ACTION_TYPES.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
