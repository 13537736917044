import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { withRouter } from 'react-router-dom';

import DNADetail from 'components/DNA/DNADetail';
import ImageSlider from 'components/DNA/ImageSlider';
import CommonModal from 'components/common/CommonModal';
import DownloadDropdown from 'components/common/DiamondListing/DownloadDropdown';
import MobileDownloadOption from 'components/common/DiamondListing/MobileDownloadOption';
import MobileShareOption from 'components/common/DiamondListing/MobileShareOption';
import ShareDropdown from 'components/common/DiamondListing/ShareDropdown';

import { callApi, withURLParams } from 'util/call-api';
import { useBoolean, useIsMobile } from 'util/hooks';
import { isEmpty } from 'util/utils';

import { VIEW_DIAMOND } from 'constants/apiConstant';

import UtilService from '../../services/util';

import './dna.less';

const TITLE_FIELD_SEQUENCE = ['vStnId', 'shpNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'fluNm'];

const DNA = (props) => {
  const match = useRouteMatch();
  const token = useSelector((store) => store?.auth?.token);

  const [stone, setStone] = React.useState({});
  const [share, setShare] = useBoolean(false);
  const [download, setDownload] = useBoolean(false);

  const [isMobile] = useIsMobile();

  const getStoneDetails = React.useCallback(async () => {
    const api = `${VIEW_DIAMOND.url}/${match.params.id}`;
    const objData = {
      method: VIEW_DIAMOND.method,
      url: api,
      showLoader: true,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === 'OK') {
        setStone(data.data);
      }
    });
  }, [match.params.id]);

  React.useEffect(() => {
    getStoneDetails();
  }, [getStoneDetails]);

  return (
    <>
      <div className="dnaImageTop mainBox">
        <span className="unique_logo">
          <img src={require('assets/img/sidebar_logo.png')} alt="" />
        </span>
        {stone.length && <span>{TITLE_FIELD_SEQUENCE.map((fields) => stone[fields]).join(' ')}</span>}
        <div className="dnaDetailShareOption">
          <div className="dnaShareOption" onClick={setShare.true}>
            <img src={require('assets/svg/DNA/share-theme.svg')} alt="" />
            <span>Share</span>
          </div>
          <div className="dnaShareOption" onClick={setDownload.true}>
            <img src={require('assets/svg/DNA/download.svg')} alt="" />
            <span>Download</span>
          </div>
        </div>
      </div>
      <div className="dnaContainerBox">
        <div className="d-flex j-space-between">
          <div className="dnaWrapper d-flex">
            <div className="dnaWrapperBox ivc_block">
              <div className="dnaImageBlock">
                <div className="dnaImageSwipe">
                  <ImageSlider dnaData={stone} />
                </div>
              </div>
            </div>
            <div className="dnaWrapperBox stone_detail_cont">
              <div className="dnaImageTop" style={{ display: 'flex' }}>
                <span className="stone-detail">Stone Detail</span>
                {isEmpty(token) && (
                  <div className="loginButton" style={{ display: 'flex' }}>
                    <button
                      className="commonButton"
                      onClick={() => props.history.push('/signin', { fromDNA: true, stoneId: stone?.id })}
                    >
                      Login
                    </button>
                    <button className="commonOutline pad-lr-30" onClick={() => props.history.push('/signup')}>
                      Register
                    </button>
                  </div>
                )}
              </div>
              <div className="dnaLeftRightDetil d-flex flex-wrap">
                <DNADetail dnaData={stone} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        title="Share Stone"
        visible={share}
        handleCancel={setShare.false}
        modalSize="ListingModal"
        destroyOnClose
      >
        {isMobile ? (
          <MobileShareOption checked={[stone]} onClose={setShare.false} />
        ) : (
          <ShareDropdown checked={[stone]} onCloseSharePopup={setShare.false} />
        )}
      </CommonModal>
      <CommonModal
        title="Download Stone"
        visible={download}
        handleCancel={setDownload.false}
        modalSize="ListingModal"
        destroyOnClose
      >
        {isMobile ? (
          <MobileDownloadOption checked={[stone]} onClose={setDownload.false} />
        ) : (
          <DownloadDropdown checked={[stone]} onCloseDownloadPopup={setDownload.false} />
        )}
      </CommonModal>
    </>
  );
};
export default withRouter(DNA);
