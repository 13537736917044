import React from 'react';

import moment from 'moment';

import DiamondTotalCard from './DiamondTotalCard';
import { Appointment } from './OfficeSidebar';

import { getPath, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';

const TableGrouping = (props) => {
  const { isMatchHeader, isFooter, colSpan } = props;
  return (
    <>
      {isMatchHeader && (
        <tr>
          <td colSpan={colSpan} STYLE="text-align: left; padding: 10px 0 0 0">
            <div className="tableGropupingBox">
              <div className="tableGroupTitle">
                <span className="tableGroupTitleInner">
                  {props.Collapse && (
                    <div className="tableCollapse" onClick={props.onClick}>
                      <img src={props.img} alt="" />
                    </div>
                  )}
                  {props.multipleCheckBox && <div className="checkBoxSelect">{props.children}</div>}
                  {props.row && (props.row.groupNo || props.row.groupingTitle) && (
                    <span>
                      {getPath() === LISTINGPAGES.MY_BID
                        ? `Date: ${props.row.groupNo || props.row.groupingTitle}`
                        : `Pair ID: ${props.row.groupingTitle || props.row.groupNo}`}
                    </span>
                  )}
                  {props.row && props.row.memoNo && <span>{props.row.memoNo}</span>}
                  {props.row && props.row.isOfferHeader && props.row.updatedAt && (
                    <span>{moment(props.row.updatedAt).format('DD/MM/YYYY')}</span>
                  )}
                  {props.row && props.row.isUpcomingHeader && (
                    <span>{moment(props.row.inDt).format('DD/MM/YYYY')} 3 PM IST</span>
                  )}
                  {props.row && props.row.isOfficeHeader && (
                    <span>
                      {moment(props.row.date).format('DD/MM/YYYY')} - [
                      {moment(props.row.cabinSlot[0].start).format('hh:mm A')}
                      {' - '}
                      {moment(props.row.cabinSlot[0].end).format('hh:mm A')}]{' - '}
                      {Appointment.virtualType.filter((x) => x.id === props.row.meetingType)[0].name}
                    </span>
                  )}
                  {props.row && props.row.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <span>{props.row.remarks}</span>}
                </span>
                <div className="tableHeaderMain">{/* {props.row ? props.row.totalDiamonds : 0} Diamond */}</div>
              </div>
            </div>
          </td>
        </tr>
      )}
      {isFooter && (
        <tr className="tableGroupingTr">
          {!props.columns && (
            <td className="groupingTableAmount" style={{ color: 'transparent' }}>
              &nbsp;
            </td>
          )}
          {props.columns
            ? props.columns.map((x, index) => {
                const column = props.col;

                return (
                  <td
                    key={column.id ?? column.accessor ?? index}
                    className={'groupingTableAmount' + (x.sticky ? ' bg-white' : '')}
                  >
                    {props.displayTotal.includes(x.id) && <DiamondTotalCard row={props.row} col={x.id} />}
                  </td>
                );
              })
            : null}
        </tr>
      )}
    </>
  );
};
export default TableGrouping;
