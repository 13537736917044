import React from 'react';

import { isNumber } from 'lodash';

import { isNumeric } from '../../../util/utils';

const BidCartPrMobile = (props) => {
  const { original, handleNewBidBlur, data, bidConfig } = props;
  const originalValue = React.useMemo(() => {
    return Number(parseFloat(original.ctPr).toFixed(2));
  }, [original.ctPr]);

  const [value, setValue] = React.useState(
    isNumber(original.bidPricePerCarat)
      ? parseFloat(original.bidPricePerCarat).toFixed(2)
      : isNumber(originalValue)
      ? parseFloat(originalValue).toFixed(2)
      : '0.00',
  );

  React.useEffect(() => {
    if (original.bidPricePerCarat) {
      setValue(parseFloat(original.bidPricePerCarat).toFixed(2));
    }
  }, [original]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();
    const _value = e.target.value;
    if (isNumeric(_value)) setValue(_value);
  }, []);

  const handleBlur = (e) => {
    e.preventDefault();
    const _value = e.target.value;
    isNumeric(_value, true)
      ? handleNewBidBlur(_value, original, data, bidConfig)
      : handleNewBidBlur(0, original, data, bidConfig);
  };

  const sign = Number(value) > 0 ? '+' : '';
  if (original.isFcCol) {
    return '-';
  }
  return (
    <div className="tableInput">
      <span className="add-sign-mobile">{sign}</span>
      <input
        type="number"
        style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default BidCartPrMobile;
