import React, { memo, useEffect, useState } from 'react';

import { Drawer } from 'antd';
import find from 'lodash/find';

import { HeadingCalc } from './ConfirmStonePopup';

import IntlMessages from '../../../util/IntlMessages';
import useRowSelect from '../../../util/useRowSelect';
import MobileViewListCard from '../../DiamondList/MobileViewListCard';
import Heading from '../Heading';

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const ListingCards = memo(
  ({
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = false,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
    quotePopUp = false,
    myBidList = false,
    areAllChecked = false,
    bidCartPopup,
  }) => {
    const { isRowChecked, toggleRowSelection, toggleAllRowSelection } = useRowSelect(currentType);
    return (
      <>
        {data?.map((x, index) => {
          const isChecked = !checked ? isRowChecked(x.id) : find(checked, { id: x.id }) ? true : false;
          return (
            <MobileViewListCard
              key={index}
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidCartPopup={bidCartPopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              quotePopUp={quotePopUp}
              onClick={() => (areAllChecked ? ' ' : toggleRowSelection(x, !isChecked))}
              activeClass={isChecked ? 'active' : ''}
              myBidList={myBidList}
              row={data}
              areAllChecked={areAllChecked}
              value={() => toggleAllRowSelection(data)}
            />
          );
        })}
      </>
    );
  },
);

const MobileConfirmStone = (props) => {
  const { toggleRowSelection } = useRowSelect(props.currentType);
  const [menu, showMenu] = useState(false);

  useEffect(() => {
    props.data.forEach((row) => void toggleRowSelection(row, true));
  }, [props.data, toggleRowSelection]);

  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.confirmStone" />}></Heading>
          </div>
          {HeadingCalc({ display: 'flex', width: '48%' }, props.VALUES)}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards data={props.data} currentType={props.currentType} />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>
        <div className="sideBarPopupButton">
          <a
            className="commonButton"
            onClick={() => {
              if (props.parent.checkCheck()) showMenu(true);
            }}
          >
            <IntlMessages id="app.confirmStone" />
          </a>
        </div>
      </div>
      {menu && (
        <Drawer
          onClose={() => showMenu(false)}
          visible={true}
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              {props.parent.getcompany()}
              {props.parent.getinvoicedate()}
              {props.parent.gettextarea()}
            </div>
            <div className="sideBarPopupButton">
              <a className="commonButton" onClick={props.parent.submit}>
                Comfirm
              </a>
              <a className="commonOutline" onClick={props.parent.cancel}>
                Cancel
              </a>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default memo(MobileConfirmStone);
