import React from 'react';
import { classNames, isEmpty, isFunction } from 'util/utils';

import './input.less';

const InputBlock = React.memo((props) => {
  const { className, hasError: error, type, label, onEnter, ...restProps } = props;

  const handleKeyDown = React.useCallback(
    (e) => {
      if (type === 'number') [37, 38, 39, 40].includes(e.keyCode) && e.preventDefault();
      if (e.keyCode === 13 && isFunction(onEnter)) onEnter();
    },
    [type, onEnter],
  );

  return (
    <div className={classNames(['from-group inputBlock', error && 'error-message'])}>
      {!isEmpty(label) && <label>{label}</label>}
      <div className="inputWrapper">
        <input {...restProps} type={type} onKeyDown={handleKeyDown} />
      </div>
      <span style={{ color: 'red' }}>{error}</span>
    </div>
  );
});

InputBlock.displayName = 'InputBlock';

export default InputBlock;
