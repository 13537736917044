import { DateTime } from 'luxon';

import { LoaderService } from 'services/LoaderService';

import { callApi } from 'util/call-api';
import {
  catchError,
  isArray,
  isEmpty,
  isNotEmpty,
  isNumber,
  isNumeric,
  isString,
  logInfo,
  logTable,
  parseDecimal,
  reduceTotal,
  sortBy,
  unique,
} from 'util/utils';

import { BASE_URL } from 'constants/Common';
import { EXPORT_EXCEL } from 'constants/apiConstant';

// NOTE: Local const for precision, don't assign less than 5
const PRECISION = 6;

export class StoneService {
  static get QUOTE() {
    return [0.5, 1];
  }

  static get HOURS() {
    return [24, 48, 72];
  }

  static prepareStoneForBargain(_stone, options) {
    const { noModify, hours, isUpdate } = { noModify: true, noUpdate: true, ...options };

    const stone = { ...(_stone?.original ?? _stone) }; // get original value
    stone.original = { ...stone }; // preserve original values for recalculation

    stone.hours =
      isUpdate && isNumber(hours) && StoneService.HOURS.includes(hours + 1) ? hours + 1 : StoneService.HOURS[0];

    // cast to number or undefined
    stone.amt = isNumeric(stone.amt) ? parseDecimal(stone.amt) : undefined;
    stone.back = isNumeric(stone.back) ? parseDecimal(stone.back) : undefined;
    stone.ctPr = isNumeric(stone.ctPr) ? parseDecimal(stone.ctPr) : undefined;
    stone.rap = isNumeric(stone.rap) ? parseDecimal(stone.rap) : undefined;
    stone.crt = isNumeric(stone.crt) ? parseDecimal(stone.crt) : undefined;

    stone.newAmount = isNumeric(stone.newAmount) ? parseDecimal(stone.newAmount) : undefined;
    stone.newDiscount = isNumeric(stone.newDiscount) ? parseDecimal(stone.newDiscount) : undefined;
    stone.newPricePerCarat = isNumeric(stone.newPricePerCarat) ? parseDecimal(stone.newPricePerCarat) : undefined;

    stone.amt = stone.newAmount ?? stone.amt;
    stone.back = stone.newDiscount ?? stone.back;
    stone.ctPr = stone.newPricePerCarat ?? stone.ctPr;

    if (!noModify && !isUpdate) {
      stone.back = isNumber(stone.back) ? parseDecimal(stone.back - 1) : stone.back;
      stone.ctPr = parseDecimal((stone.back * stone.rap) / 100 + stone.rap);
      stone.amt = parseDecimal(stone.ctPr * stone.crt);
    }

    stone.calcAmount = stone.amt;
    stone.calcDiscount = stone.back;
    stone.calcPricePerCarat = stone.ctPr;

    stone.newAmount = stone.calcAmount;
    stone.newDiscount = stone.calcDiscount;
    stone.newPricePerCarat = stone.calcPricePerCarat;

    stone.finalquote = stone.back;

    stone.bargainTrack =
      isArray(stone.bargainTrack) && !isEmpty(stone.bargainTrack)
        ? stone.bargainTrack.map((track) => ({ ...track, timestamp: DateTime.fromISO(track.updatedAt).valueOf() }))
        : [];

    stone.bargainTrack = sortBy(stone.bargainTrack, 'timestamp', true);

    stone.defaultTrack = {
      userType: stone.coUserType,
      trackAmount: stone.amt,
      trackDiscount: stone.back,
      trackPricePerCarat: stone.ctPr,
    };

    stone.currentValidTrack = (() => {
      if ([1, 2, 8].includes(stone.coUserType)) return stone.defaultTrack;

      const latestAdminTrack = stone.bargainTrack.find((track) => [1, 2, 8].includes(track?.userType));
      if (!isEmpty(latestAdminTrack)) return latestAdminTrack;

      return undefined;
    })();

    stone.hasCounterOffer = [1, 2, 8].includes(stone.currentValidTrack?.userType);

    if (stone.offerStatus === 1 && !stone.confirmedStone) logInfo(stone, stone.currentValidTrack, stone.defaultTrack);

    return stone;
  }

  static calculateTotal(stoneList) {
    if (!isArray(stoneList) || isEmpty(stoneList)) return {};

    const count = stoneList?.length;

    const sumCrt = parseDecimal(reduceTotal(stoneList, 'crt'), PRECISION);
    const sumRap = parseDecimal(reduceTotal(stoneList, 'rap'), PRECISION);
    const sumAmt = parseDecimal(reduceTotal(stoneList, 'amt'), PRECISION);
    const sumRapInCrt = reduceTotal(stoneList.map((stone) => stone?.rap * stone?.crt));
    const avgRapByCrt = parseDecimal(sumRapInCrt / sumCrt, PRECISION);

    const avgBack = parseDecimal(Math.abs((sumAmt / sumRapInCrt) * 100 - 100), PRECISION);
    const sumCtPr = parseDecimal(sumAmt / sumCrt, PRECISION);

    const entries = [
      ['count', count],

      ['sumCrt', sumCrt],
      ['sumRap', sumRap],
      ['sumRapInCrt', sumRapInCrt],
      ['avgRapByCrt', avgRapByCrt],

      ['avgBack', avgBack],
      ['sumCtPr', sumCtPr],
      ['sumAmt', sumAmt],
    ];
    logTable(entries);
    return Object.fromEntries(entries);
  }

  static async exportAsExcel(options) {
    LoaderService.startLoading();
    await catchError(async () => {
      const idList = (() => {
        const output = options?.list?.map?.((stone) => stone?.id ?? stone)?.filter?.(isNotEmpty) ?? [];
        return unique(output);
      })();

      const fileName = isString(options?.sheetName) && !isEmpty(options?.sheetName) ? options?.sheetName : undefined;
      const filter =
        options?.filter?.viewType && isEmpty(idList) ? { isFm: { nin: ['CERT', 'ELIG'] } } : options?.filter ?? {};
      const summary = options?.summary ?? false;
      const viewType = options?.filter?.viewType && isEmpty(idList) ? 2 : undefined;

      const [err, res] = await callApi({
        ...EXPORT_EXCEL,
        request: { allSummary: summary, filter: filter, id: idList, sheetName: fileName, viewType },
      });

      if (!err && isString(res.data.data)) window.open(`${BASE_URL}/data${res.data.data}`, 'Download');
    });
    LoaderService.stopLoading();
  }
}

window.__DN = { ...window.__DN, StoneService };
