import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import { DNARoute } from 'routes/DNARoute';
import { StoneDetailRoute } from 'routes/StoneDetailRoute';

import { ConnectedProgress } from 'components/ConnectedProgress';

import { BidService } from 'services/BidService';
import { TimeService } from 'services/TimeService';

import { history } from 'util/history';
import { loadable } from 'util/loadable';
import { isEmpty } from 'util/utils';

import AppLocale from '../lngProvider';

const BiddingProcedure = loadable(() => import('components/BiddingProcedure'));
const BookAppointment = loadable(() => import('components/BookAppointment'));
const Career = loadable(() => import('components/Career'));
const CareerDetail = loadable(() => import('components/Career/CareerDetail'));
const ContactUs = loadable(() => import('components/ContactUs'));
const EmailVerify = loadable(() => import('components/EmailVerify'));
const Feedback = loadable(() => import('components/Feedback'));
const ForgotPassword = loadable(() => import('components/Auth/ForgotPassword'));
const Routes = loadable(() => import('./Routes'));
const FTC = loadable(() => import('components/FTC'));
const GuestLogin = loadable(() => import('components/GuestLogin'));
const News = loadable(() => import('components/News'));
const PageNotFound = loadable(() => import('components/PageNotFound'));
const ResetPassword = loadable(() => import('components/Auth/ResetPassword'));
const SignIn = loadable(() => import('components/SignIn'));
const SignUp = loadable(() => import('components/SignUp'));
const TermsOfUse = loadable(() => import('components/TermsOfUse'));
const ThankYouAfterSignup = loadable(() => import('components/ThankYou'));
const VerifyDevice = loadable(() => import('components/VerifyDevice'));
const VerifyOtp = loadable(() => import('components/VerifyOtp'));
const ViewImage = loadable(() => import('components/DNA/ViewImage'));
const XRAY = loadable(() => import('components/XRAY'));

const SignOutRoute = React.memo(({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth?.token);
  return (
    <Route {...rest} render={(props) => (isEmpty(token) ? <Component {...props} /> : <Redirect to={'/dashboard'} />)} />
  );
});
SignOutRoute.displayName = 'SignOutRoute';

function Root() {
  const isLoggedIn = useSelector((store) => !isEmpty(store?.auth?.token));
  const locale = useSelector((store) => AppLocale[store?.commonData?.locale?.locale ?? 'en']);

  const getConfigFronServer = React.useCallback(async () => {
    if (!isLoggedIn) return;
    await TimeService.getServerTime();
    await BidService.fetchRunningBid();
  }, [isLoggedIn]);

  React.useEffect(() => {
    getConfigFronServer();
  }, [getConfigFronServer]);

  return (
    <IntlProvider locale={locale.locale} messages={locale.messages}>
      <Router history={history}>
        <ConnectedProgress />
        <Switch>
          {/* use when site in under maintanance  */}
          {/* <Maintanance /> */}

          <SignOutRoute path="/" component={SignIn} exact />
          <SignOutRoute path="/signin" component={SignIn} />
          <SignOutRoute path="/signup" component={SignUp} />
          <SignOutRoute path="/device/signup" component={SignUp} />
          <SignOutRoute path="/guest-login" component={GuestLogin} />
          <SignOutRoute path="/forgot-password" component={ForgotPassword} />
          <SignOutRoute path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/verify-otp" component={VerifyOtp} />

          <Route path="/DiamFTC" component={FTC} />
          <Route path="/bidding-procedure" component={BiddingProcedure} exact />
          <Route path="/book-appointment" component={BookAppointment} exact />
          <Route path="/career" component={Career} />
          <Route path="/career-detail" component={CareerDetail} />
          <Route path="/contact" component={ContactUs} exact />
          <Route path="/dna/:id" component={DNARoute} exact />
          <Route path="/feedback" component={Feedback} exact />
          <Route path="/news" component={News} exact />
          <Route path="/not-found" component={PageNotFound} exact />
          <Route path="/terms-condition" component={TermsOfUse} exact />
          <Route path="/thank-you" component={ThankYouAfterSignup} />
          <Route path="/verify-device" component={VerifyDevice} />
          <Route path="/verify-email" component={EmailVerify} />
          <Route path="/viewImage" component={ViewImage} />
          <Route path="/xrays" component={XRAY} />

          {!isLoggedIn && (
            <Route exact path="/:name(diamond-detail|diamond-details)/:id" component={StoneDetailRoute} />
          )}

          <Route path="*" component={Routes} />
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default Root;
