import React from 'react';

import NoDataShow from 'components/common/NoDataShow';

import IntlMessage from 'util/IntlMessages';
import { last, split } from 'util/lodash';
import { getPathName, isNumber } from 'util/utils';

import { TABLE_PAGE_LIMIT } from 'constants/Common';

import { LISTINGPAGES } from './DiamondListFunctions';

export function getGridHeight(allClasses = [], initial = 0) {
  const allContainers = {};
  allClasses.forEach((classname) => {
    allContainers[classname] = document.querySelector(classname?.[0] === '.' ? classname : `.${classname}`);
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).forEach((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  const module = last(split(window.location.pathname, '/'));
  const data = module.replace('-', ' ');

  if (loading || (isNumber(length) && length > 0)) return null;

  return (
    <NoDataShow
      message={
        getPathName() === LISTINGPAGES.WATCH_LIST ||
        getPathName() === LISTINGPAGES.REMINDER ||
        getPathName() === LISTINGPAGES.QUOTE ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.NOTE ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.OFFICE ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.CONFIRM ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.HOLD ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.MATCHPAIR ? (
          <IntlMessage id="app.Nodatafound" />
        ) : getPathName() === LISTINGPAGES.ENQUIRY ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.CART ? (
          <IntlMessage id="app.noData" />
        ) : getPathName() === LISTINGPAGES.SPECIAL ? (
          <IntlMessage id="app.Nodiamondadded" />
        ) : getPathName() === LISTINGPAGES.EXCLUSIVE ? (
          <IntlMessage id="app.Nodiamondexclusive" />
        ) : getPathName() === LISTINGPAGES.STONE_OF_DAY ? (
          <IntlMessage id="app.Nodiamondfeature" />
        ) : getPathName() === LISTINGPAGES.UPCOMING ? (
          <IntlMessage id="app.NOdiamondupcoming" />
        ) : (
          ''
        )
      }
    />
  );
}

export const getState = (add) => ({
  page: 1,
  limit: 100,
  listview: true,
  data: [],
  sort: [],
  sum: {},
  columns: [],
  count: 0,
  loading: false,
  inTrackDiamonds: [],
  defaultChecked: [],
  inBlockDiamonds: [],
  ...add,
});
