import React from 'react';

import { Popover, Skeleton } from 'antd';
import { memoize } from 'lodash';
import { DateTime } from 'luxon';

import { getContactDetails } from 'containers/APIFunctions';

import { useCounter, usePathname } from 'util/hooks';
import { isArray, isEmpty, isString, lowerCase } from 'util/utils';

import { PAGES } from 'constants/Common';

import infoSvg from 'assets/svg/info.svg';

const getContactDetailsBySyncId = memoize(
  (syncId) => getContactDetails({ type: 15, filter: { syncId } }),
  (syncId) => `${syncId}`,
);

const getFormattedUrl = (input) => {
  if (!isString(input)) return '';
  if (input.startsWith('https://') || input.startsWith('http://') || input.startsWith('//')) {
    return input.toLowerCase();
  }
  return `//${input.toLowerCase()}`;
};

export default function VStnIdCell(props) {
  const { data } = props;

  const pathname = usePathname();

  const [userDetail, setUserDetail] = React.useState({});
  const [loading, startLoading, stopLoading] = useCounter();
  const toolTipRef = React.useRef();

  const newStone = React.useMemo(() => {
    const diff = DateTime.fromISO(data.mkDt).diffNow();
    return diff.isValid && (diff <= 15 * 24 * 60 * 60 * 1000 || ['B', 'U'].includes(data.wSts));
  }, [data.mkDt, data.wSts]);

  const showVendorInfo = ![PAGES.BID, PAGES.MY_BID].includes(pathname);

  const fetchUserList = React.useCallback(async (syncId) => {
    const [, res] = await getContactDetailsBySyncId(syncId);
    if (res?.code == 'OK' && isArray(res?.data?.list)) {
      const userData = res?.data?.list[0];
      setUserDetail(userData);
    }
  }, []);

  const loadDetails = React.useCallback(() => {
    if (loading || !isEmpty(userDetail)) return;

    startLoading();
    requestIdleCallback(() => fetchUserList(data.vndId).then(stopLoading), { timeout: 1500 });
  }, [data.vndId, fetchUserList, loading, startLoading, stopLoading, userDetail]);

  React.useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  React.useEffect(() => {
    const id = requestIdleCallback(() => getContactDetailsBySyncId(data.vndId), { timeout: 3000 });
    return () => void cancelIdleCallback(id);
  }, [data.vndId]);

  if (!showVendorInfo) return data.vStnId ?? '-';

  return (
    <div key={data.vStnId} className="infoPopoverWrapper" onClick={(e) => e.stopPropagation()}>
      <Popover
        key={data.vndId}
        ref={toolTipRef}
        trigger="hover"
        placement="leftTop"
        overlayClassName="infoPopover"
        content={
          <React.Fragment key={data.vStnId}>
            <PopoverContent key={data.vndId} vStnId={data.vStnId} loading={loading} userDetail={userDetail} />
          </React.Fragment>
        }
      >
        <img src={infoSvg} alt="info" className="headerInfoIcon" />
      </Popover>
      {data.vStnId}
      {newStone && (
        <span key="__new__stone" className="newdiamond">
          New
        </span>
      )}
    </div>
  );
}

function PopoverContent({ loading, vStnId, userDetail }) {
  const skeleton = loading ? <Skeleton active loading title={null} paragraph={{ rows: 1 }} /> : undefined;

  return (
    <dl className="vStnIdUserInfo">
      <dt>Packet No.:</dt>
      <dd>{vStnId}</dd>
      <dt>Company Name: </dt>
      <dd>{skeleton ?? userDetail?.companyName?.toUpperCase() ?? '-'}</dd>
      <dt>Contact Person: </dt>
      <dd>{skeleton ?? userDetail?.contactPersonDetail?.contactName ?? '-'}</dd>
      <dt>Email: </dt>
      <dd>{skeleton ?? userDetail?.contactPersonDetail?.contactEmail ?? '-'}</dd>
      <dt>Mobile: </dt>
      <dd>{skeleton ?? userDetail?.contactPersonDetail?.contactMobile ?? '-'}</dd>
      <dt>Website: </dt>
      <dd>
        {skeleton ??
          (!isEmpty(userDetail?.contactPersonDetail?.Website) ? (
            <a href={getFormattedUrl(userDetail?.contactPersonDetail?.Website)} target="_blank" rel="noreferrer">
              {lowerCase(userDetail?.contactPersonDetail?.Website)}
            </a>
          ) : (
            '-'
          ))}
      </dd>
    </dl>
  );
}
