import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { history } from 'util/history';

import reducers from '../reducers';

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 50 })
  : compose;

export const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middlewares)));

void module?.hot?.accept?.('../reducers', () => store.replaceReducer(reducers));
