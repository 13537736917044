import React, { memo } from 'react';

import Image from 'components/DiamondDetail/Image';
import VStnIdCell from 'components/DiamondList/VStnIdCell';

import { formatDecimal } from 'util/utils';

import { LAB_LINKS, FILE_URLS } from '../../../constants/Common';
import useRowSelect from '../../../util/useRowSelect';
import CheckBox from '../../common/CheckBox';
import { formatNumber } from '../../common/DiamondListing/SelectStone';
import { STATUS, STATUS_COLOR } from '../../common/DiamondListing/Status';
import { getParams, LISTINGPAGES } from '../DiamondListFunctions';

import noImageSvg from 'assets/svg/noImageFound/DiamondNoImage.svg';

const DiamondSearchGrid = (props) => {
  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);

  return (
    <div className="diamondGridView">
      {!props.loading &&
        props.data.length > 0 &&
        props.data.map((stone) => {
          const isChecked = isRowChecked(stone.id);
          return (
            <div key={`grid_${stone.id}`} className="daimondGridViewItem">
              <div className="DiamondGridViewImg">
                <div className="diamondGrdiCheckbox">
                  <CheckBox checked={isChecked} onChange={() => toggleRowSelection(stone, !isChecked)} />
                </div>
                <Image src={stone?.files?.img} alt={`${stone.vStnId} image`}>
                  <img src={noImageSvg} style={{ width: '170px' }} alt={`${stone.vStnId} alt image`} />
                </Image>
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>{stone.shpNm}</h2>
                  <a href={`/${LISTINGPAGES.DETAIL}/${stone.id}`} target="_blank" rel="noopener noreferrer">
                    <VStnIdCell data={stone} />
                  </a>
                  <p>{getParams(stone).join(' ')}</p>
                </div>
                <div className="gridRightContent">
                  <a
                    onClick={() => {
                      if (stone.lbNm && stone.rptNo) {
                        window.open(LAB_LINKS[stone.lbNm.toUpperCase()].replace('***', stone.rptNo));
                      }
                    }}
                  >
                    <span
                      className={
                        stone.lbNm === 'GIA'
                          ? 'gia-cer-color'
                          : stone.lbNm === 'IGI'
                          ? 'ige-cer-color'
                          : 'other-cer-color'
                      }
                    >
                      {stone.lbNm}
                    </span>
                  </a>
                  {stone?.amt ? <span>${formatDecimal(stone.amt)}</span> : <span>-</span>}
                  <span className="statusShow">
                    {STATUS[stone.wSts]}
                    <span className={'statusIcon ' + STATUS_COLOR[stone.wSts]}></span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(DiamondSearchGrid);
