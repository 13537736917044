import React from 'react';
import { DIAMOND_BID } from '../../../constants/Common';
import { Tooltip } from 'antd';
import moment from 'moment';

export const STATUS = {
  A: 'A',
  // M: "Hold",
  H: 'M',
  U: 'U',
  B: 'B',
  BH: 'BH',
  C: 'Confirm',
  E: 'A',
  all: 'All',
};

export const STATUS_COLOR = {
  A: 'green',
  // M: "red",
  H: 'red',
  W: 'green',
  E: 'green',
  L: 'blue',
  U: 'pink',
  C: 'lighBlue',
  B: 'tenOrange',
  BH: 'red',
};

const BID_STATUS = {
  1: 'Blind Bid',
  2: 'Look & Bid',
};

const Status = (props) => {
  let status = !props.winloss
    ? props.status
    : props.winloss === DIAMOND_BID.STATUS.WIN
    ? 'W'
    : props.winloss === DIAMOND_BID.STATUS.LOSS
    ? 'L'
    : props.status;

  // function getBidStartTime(bidType) {
  //   if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(11, "hours");
  //   else return moment().startOf("day").add(15, "hours");
  // }

  // function getBidEndTime(bidType) {
  //   if (bidType === DIAMOND_BID.TYPE.OPEN)
  //     return moment().startOf("day").add(14, "hours").add(59, "minutes");
  //   else return moment().startOf("day").add(10, "hours").add(59, "minutes");
  // }

  // function getBidType() {
  //   let bidType = 0;
  //   if (
  //     moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) &&
  //     moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
  //   ) {
  //     bidType = DIAMOND_BID.TYPE.OPEN;
  //   } else if (
  //     moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) ||
  //     moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
  //   ) {
  //     bidType = DIAMOND_BID.TYPE.BLIND;
  //   }
  //   return BID_STATUS[bidType];
  // }
  return (
    <div className="showSatusBlock">
      {props.status !== 'all' && <div className={'SelectcolorWiseList ' + STATUS_COLOR[status]}></div>}
      {/* <span>
        {props.winloss === DIAMOND_BID.STATUS.PENDING ? (
          'PENDING'
        ) : props.winloss === DIAMOND_BID.STATUS.WIN ? (
          'W'
        ) : props.winloss === DIAMOND_BID.STATUS.LOSS ? (
          'L'
        ) : props.status === 'B' ? (
          <Tooltip title={props.bidStatusTooltip} placement="bottomLeft">
            {props.bidStatus ? props.bidStatus : STATUS[props.status]}
          </Tooltip>
        ) : (
          STATUS[props.status]
        )}
      </span> */}
    </div>
  );
};

export default Status;
