import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import './leftOption.less';
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const LeftDatePicker = (props) => {
  return (
    <div className="leftSelectItem">
      <div className="leftSelectTitle">
        <h2>{props.title}</h2>
        {props.image && <img src={props.image} alt="icon" className="reloadImg" />}
      </div>
      {props.Singledate ? (
        <DatePicker
          dropdownClassName="datePickerDropdown"
          className="SelectDateRange"
          disabledDate={props.disabledDate}
          onChange={props.onDateChange}
          format={dateFormat}
          value={props.date ? moment(props.date) : undefined}
        />
      ) : (
        <RangePicker
          dropdownClassName="datePickerDropdown"
          className="SelectDateRange"
          defaultValue={[moment('01/08/2020', dateFormat), moment('01/08/2020', dateFormat)]}
          format={dateFormat}
        />
      )}
    </div>
  );
};
export default LeftDatePicker;
