import React from 'react';
import { Link } from 'react-router-dom';

import IntlMessages from '../../util/IntlMessages';
import { getPath, LISTINGPAGES } from '../DiamondList/DiamondListFunctions';

const NoDataShow = (props) => {
  return (
    <div className="empty-data" style={props.style && props.style}>
      <h3>
        {props.message ? (
          getPath() === LISTINGPAGES.CART ? (
            <div>
              {props.message}
              <div className="cart-empty-data">
                <Link to="/diamond-search">Add stones in cart</Link>
              </div>
            </div>
          ) : getPath() === LISTINGPAGES.MY_BID && props.noData ? (
            <div>
              {props.message}
              <div className="cart-empty-data">
                <Link to={props.subTypeCode ? '/search-bid-diamond' : '/new-arrival-bid-it'}>Go back to Bid</Link>
              </div>
            </div>
          ) : props.redirectURL ? (
            <div>
              {props.message}
              <div className="cart-empty-data">
                <Link to="/diamond-search">Go back to Search Diamonds</Link>
              </div>
            </div>
          ) : (
            props.message
          )
        ) : (
          <IntlMessages id="app.noData" />
        )}
      </h3>
    </div>
  );
};
export default NoDataShow;
